import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import { useContext, useMemo } from "react";

import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { BrandEditModal } from "modals/BrandEditModal";
import { Brand } from "types/models";
import { BLUECAR_ROLES_IDS, isAdmin, isSeller } from "utils/models/user";

const baseColumns: Column<Brand>[] = [{ id: "name", label: "Nombre" }];

const bluecarColumns: Array<Column<Brand>> = [
  {
    id: "active",
    label: "Activo",
    renderer: (brand) => (Boolean(brand.active) ? "Sí" : "No"),
  },
  {
    id: "show_home",
    label: "Mostrar en home",
    renderer: (brand) => (Boolean(brand.show_home) ? "Sí" : "No"),
  },
  {
    id: "image_url",
    label: "Imagen",
    renderer: (discount) =>
      discount.image_url ? (
        <a href={discount.image_url} target="_blank">
          Ver imagen
        </a>
      ) : (
        "-"
      ),
  },
];

const allFilters = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activo",
    roles: BLUECAR_ROLES_IDS,
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
  {
    id: "show_home",
    label: "Mostrar en home",
    roles: BLUECAR_ROLES_IDS,
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeBrands = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Marcas",
    roles: BLUECAR_ROLES_IDS,
  });
  const { user } = useAuthentication();
  const { setBrandEditModalProps } = useContext(ModalContext);

  const actionColumn: Column<Brand> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (brand) => (
        <Tooltip title="Editar marca">
          <Button
            onClick={() =>
              setBrandEditModalProps({
                open: true,
                imageUrl: brand.image_url,
                showHome: brand.show_home,
                brandId: brand.id,
              })
            }
          >
            <EditIcon titleAccess="Editar" />
          </Button>
        </Tooltip>
      ),
      align: "center",
    }),
    [setBrandEditModalProps]
  );

  const columns = useMemo(() => {
    if (isAdmin(user)) {
      return [...baseColumns, ...bluecarColumns, actionColumn];
    }
    if (isSeller(user)) {
      return [...baseColumns, ...bluecarColumns];
    }
    return baseColumns;
  }, [actionColumn, user]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters.filter((filter) =>
      filter.roles ? filter.roles.includes(user?.role) : true
    );
  }, [user]);

  return (
    <BackofficeLayout>
      <Table
        columns={columns}
        filters={filters}
        queryKey="brands"
        path="brands"
      />
      {!!user && isAdmin(user) && <BrandEditModal />}
    </BackofficeLayout>
  );
};
