import { useMediaQuery, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useFormik } from "formik";
import { useCallback, useContext } from "react";
import { object, string } from "yup";

import { Form } from "components/Form";
import {
  FormSelect,
  SingleUpdatedValues,
  SingleOption,
} from "components/FormSelect";
import { ProductsContext } from "contexts/ProductsProvider";

type Props = {
  style?: SxProps<Theme>;
  label?: string;
};

const SORTING_OPTIONS: SingleOption[] = [
  { id: "price-asc", label: "Menor precio" },
  { id: "price-desc", label: "Mayor precio" },
  { id: "name-asc", label: "Descripción A-Z" },
  { id: "name-desc", label: "Descripción Z-A" },
  { id: "id-asc", label: "ID A-Z" },
  { id: "id-desc", label: "ID Z-A" },
  { id: "stock-asc", label: "Menor stock" },
  { id: "stock-desc", label: "Mayor stock" },
];

export const SortSelector = ({ style, label = "Ordernar por" }: Props) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { sorting, setSorting } = useContext(ProductsContext);

  const formik = useFormik({
    initialValues: {
      "products-sorting": SORTING_OPTIONS.find(
        (sortingOption) => sortingOption.id === sorting?.id
      ) as SingleOption,
    },
    validationSchema: object({
      "products-sorting": object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione ordenamiento"),
    }),
    onSubmit: () => {},
  });

  const handleChange = useCallback(
    (updatedValue: SingleUpdatedValues) =>
      setSorting({
        id: updatedValue.value?.id || "",
        label: updatedValue.value?.label || "",
      }),
    [setSorting]
  );

  return (
    <Form formik={formik} noMargin>
      <FormSelect
        name="products-sorting"
        label={label}
        fullWidth={xsDevice}
        onChange={handleChange}
        options={SORTING_OPTIONS}
        style={{ marginY: -1, ...style }}
        multiple={false}
        size="small"
      />
    </Form>
  );
};
