import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Paragraph } from "components/Paragraph";
import { ProductExternalId } from "components/ProductExternalId";
import { ProductBoxShopping } from "components/screens/products/ProductBoxShopping";
import { Title } from "components/Title";
import { productsPath } from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { Product } from "types/models";

type Props = {
  product: Product;
};

export const ShoppingCartItem = ({ product }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const { setIsShoppingCartModalOpen } = useContext(ModalContext);

  const handleClick = useCallback(() => {
    setIsShoppingCartModalOpen(false);
    navigate(`${productsPath}/${product.id}`);
  }, [navigate, product.id, setIsShoppingCartModalOpen]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingX: 2,
        paddingY: 1,
        margin: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="img"
        height={xsDevice ? 32 : 64}
        width={xsDevice ? 32 : 64}
        sx={{ objectFit: "contain" }}
        src={product.image_url || "https://via.placeholder.com/128x128"}
        alt={product.name}
      />
      <Box
        marginLeft={3}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
      >
        <Paragraph ellipsis uppercase>
          {product.brand_name}
        </Paragraph>
        <Paragraph ellipsis uppercase>
          {product.group_name}
          {product.group_name && product.line_name ? " - " : ""}
          {product.line_name}
        </Paragraph>
        <Title onClick={handleClick} font="sm" ellipsis uppercase tooltip>
          {product.name}
        </Title>
        <ProductExternalId externalId={product.external_id} />
        {product.oem_codes?.length ? (
          <Paragraph ellipsis uppercase disableSelect>
            Códigos OEM: {product.oem_codes.join(" ")}
          </Paragraph>
        ) : null}
        {xsDevice ? <ProductBoxShopping product={product} /> : null}
      </Box>
      {xsDevice ? null : <ProductBoxShopping product={product} />}
    </Paper>
  );
};
