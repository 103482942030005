import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import {
  Box,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { object, number } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Price } from "components/Price";
import { StockIndicator } from "components/StockIndicator";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { Product } from "types/models";

type Props = {
  isLoading: boolean;
  productAmount: number;
  onAmountChange: ({ amount }: { amount: number }) => void;
  product: Pick<
    Product,
    | "id"
    | "price"
    | "min_mult"
    | "company_discount"
    | "promotion_discount"
    | "promotion_minimum_amount"
    | "stock"
  >;
  showTitle?: boolean;
  hidePrice?: boolean;
  hideHandler?: boolean;
  showIcon?: boolean;
};

const MIN = 0;

export const ProductAmountHandler = ({
  isLoading,
  productAmount,
  onAmountChange,
  product,
  showTitle,
  hidePrice,
  hideHandler,
  showIcon,
}: Props) => {
  const { errorToast } = useToast();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { setIsShoppingCartModalOpen } = useContext(ModalContext);
  const minMultiplier = useMemo(
    () => product.min_mult || 1,
    [product.min_mult]
  );

  const inputName = `${product.id}-amount`;
  const formik = useFormik({
    initialValues: {
      [inputName]: productAmount || product.min_mult,
    },
    validationSchema: object().shape({
      [inputName]: number().min(MIN, ""),
    }),
    onSubmit: () => {},
  });

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const amount = Number(event.target.value);
      if (amount < MIN || amount % minMultiplier !== 0) {
        errorToast(
          `La cantidad ingresada es inválida. El producto se vende de a ${minMultiplier} unidades.`
        );
        formik.setFieldValue(inputName, minMultiplier);
        onAmountChange({ amount: minMultiplier });
      } else {
        onAmountChange({ amount });
      }
    },
    [errorToast, formik, inputName, minMultiplier, onAmountChange]
  );

  const handleAddToCart = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      formik.setFieldValue(inputName, minMultiplier);
      onAmountChange({ amount: minMultiplier });
    },
    [formik, inputName, minMultiplier, onAmountChange]
  );

  const handleAddition = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const amount = formik.values[inputName] + minMultiplier;
      formik.setFieldValue(inputName, amount);
      onAmountChange({ amount });
    },
    [formik, inputName, minMultiplier, onAmountChange]
  );

  const handleSubstraction = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const amount = Math.max(MIN, formik.values[inputName] - minMultiplier);
      formik.setFieldValue(inputName, amount);
      onAmountChange({ amount });
    },
    [formik, inputName, minMultiplier, onAmountChange]
  );

  if (!product.price) return null;

  if (!productAmount)
    return (
      <Box
        position="relative"
        display="flex"
        alignItems="space-between"
        justifyContent={
          showTitle ? "start" : product.price ? "space-between" : "end"
        }
        sx={{
          height: { sm: "100%" },
          flexDirection: { xs: "row", sm: "column" },
        }}
      >
        {!hideHandler && (
          <StockIndicator
            stock={product.stock}
            style={{
              position: "absolute",
              left: xsDevice ? "0" : "-16px",
              top: xsDevice ? "-1px" : hidePrice ? "14px" : "8px",
            }}
          />
        )}
        {hidePrice ? null : (
          <Price
            price={product.price}
            amount={productAmount}
            companyDiscount={product.company_discount}
            promotionDiscount={product.promotion_discount}
            promotionMinimumAmount={product.promotion_minimum_amount}
            showTitle={showTitle}
          />
        )}
        {hideHandler ? null : (
          <Button
            onClick={handleAddToCart}
            disabled={isLoading}
            sx={{ marginTop: showTitle ? 2 : 0 }}
            startIcon={showIcon ? <ShoppingCartOutlined /> : null}
          >
            Agregar
          </Button>
        )}
      </Box>
    );

  return (
    <Box
      position="relative"
      display="flex"
      flexWrap="wrap"
      alignItems="space-between"
      justifyContent={
        showTitle ? "start" : product.price ? "space-between" : "end"
      }
      sx={{
        height: { sm: "100%" },
        flexDirection: { xs: "row", sm: "column" },
      }}
    >
      {!hideHandler && (
        <StockIndicator
          stock={product.stock}
          selected={productAmount}
          style={{
            position: "absolute",
            left: xsDevice ? "unset" : hidePrice ? "-8px" : "16px",
            top: hidePrice ? "calc(50% - 4px)" : xsDevice ? "2px" : "8px",
          }}
        />
      )}
      {hidePrice ? null : (
        <Price
          price={product.price}
          amount={productAmount}
          companyDiscount={product.company_discount}
          promotionDiscount={product.promotion_discount}
          promotionMinimumAmount={product.promotion_minimum_amount}
          showTitle={showTitle}
        />
      )}
      {hideHandler ? null : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          marginLeft="auto"
          marginTop={showTitle ? 2 : 0}
        >
          <IconButton onClick={handleSubstraction} size="large">
            {productAmount === 1 ? (
              <DeleteIcon fontSize="medium" />
            ) : (
              <RemoveIcon fontSize="medium" />
            )}
          </IconButton>
          <Form formik={formik} noMargin>
            <FormTextField
              label=""
              name={inputName}
              min={MIN}
              size="small"
              type="number"
              style={{ width: 72, margin: 0 }}
              onChange={handleOnInputChange}
              hideAdornment
            />
          </Form>
          <IconButton onClick={handleAddition} size="large">
            <AddIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
      {showTitle && productAmount > 0 ? (
        <Button
          onClick={() => setIsShoppingCartModalOpen(true)}
          sx={{ margin: { xs: "auto", md: 0 } }}
        >
          Ver mi pedido
        </Button>
      ) : null}
    </Box>
  );
};
