import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";

import { Filter, FilterPaginated } from "components/FilterPaginated";
import { Header } from "components/Header";
import { Paragraph } from "components/Paragraph";
import { PromotionBox } from "components/screens/promotions/PromotionBox";
import { PromotionBoxListSkeleton } from "components/screens/promotions/PromotionBoxListSkeleton";
import { PromotionsTable } from "components/screens/promotions/PromotionsTable";
import { useAllBrands } from "hooks/useAllBrands";
import { useLayout } from "hooks/useLayout";
import { Promotion } from "types/models";
import { Paginated } from "types/paginated";

const allFilters: Filter[] = [
  { id: "product_name", label: "Producto" },
  {
    id: "brand_id",
    label: "Marca",
    options: [],
  },
];

export const Promotions = () => {
  const { PublicLayout } = useLayout({ title: "Promociones" });
  const [tableView, setTableView] = useState(true);
  const { data: brands } = useAllBrands({ active: true });

  const filters = useMemo(() => {
    return allFilters.map((filter) => {
      if (filter.id === "brand_id") {
        return {
          ...filter,
          options:
            brands?.map((brand) => ({
              id: brand.id,
              label: brand.name,
            })) || [],
        };
      }
      return filter;
    });
  }, [brands]);

  const paginatedProps = useMemo(
    () => ({
      queryKey: "current_promotions",
      path: "promotions?current=true",
      filters,
      filtersRightNode: (
        <Button onClick={() => setTableView(!tableView)}>
          {tableView ? "Ver como catálogo" : "Ver como lista"}
        </Button>
      ),
    }),
    [filters, tableView]
  );

  return (
    <PublicLayout>
      <Header titleText="Promociones" />
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
        {tableView ? (
          <PromotionsTable {...paginatedProps} />
        ) : (
          <FilterPaginated
            {...paginatedProps}
            LoadingComponent={PromotionBoxListSkeleton}
            renderer={(data: Paginated<Promotion>) => (
              <>
                {data?.results.length === 0 && (
                  <Paragraph style={{ marginTop: 3 }}>
                    No hay promociones en este momento.
                  </Paragraph>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {data.results.map((promotion) => (
                    <PromotionBox
                      key={promotion.id}
                      promotion={promotion}
                      hideAnimation
                    />
                  ))}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </PublicLayout>
  );
};
