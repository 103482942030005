import { CssBaseline, useMediaQuery } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import { ChildrenProps } from "./types/props";

const theme = createTheme({
  palette: {
    secondary: grey,
  },
  typography: {
    fontFamily: "Avenir, Arial",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }: ChildrenProps) => {
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <SnackbarProvider
          dense
          variant="info"
          anchorOrigin={{
            horizontal: "left",
            vertical: xsDevice ? "bottom" : "top",
          }}
        >
          {children}
        </SnackbarProvider>
      </CssBaseline>
    </MuiThemeProvider>
  );
};
