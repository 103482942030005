import { Box, Skeleton } from "@mui/material";

export const ProductSkeleton = () => {
  return (
    <Box
      display="flex"
      position="relative"
      sx={{ flexDirection: { xs: "column", md: "row" } }}
    >
      <Box flexGrow={1} display="flex" justifyContent="center" marginBottom={3}>
        <Skeleton
          variant="rounded"
          sx={{
            width: { xs: 256, md: 320, lg: 512 },
            height: { xs: 256, md: 320, lg: 464 },
          }}
        />
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        sx={{ alignItems: { xs: "center", md: "start" } }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            width: 64,
            height: 12,
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 256,
            height: 32,
            marginTop: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 64,
            height: 12,
            marginTop: { xs: 2, md: 5 },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 256,
            height: 32,
            marginTop: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 64,
            height: 12,
            marginTop: { xs: 2, md: 5 },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 256,
            height: 32,
            marginTop: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 64,
            height: 12,
            marginTop: { xs: 2, md: 5 },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: "100%",
            height: 32,
            marginTop: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 64,
            height: 12,
            marginTop: { xs: 2, md: 5 },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: 256,
            height: 32,
            marginTop: 1,
          }}
        />
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        right={{ xs: "unset", md: 0 }}
        top={{ xs: "unset", md: 0 }}
        position={{ xs: "relative", md: "absolute" }}
        minWidth={{ xs: "100%", md: "auto" }}
        marginTop={{ xs: 2, md: 0 }}
      >
        <Skeleton variant="rounded" sx={{ width: 128, height: 80 }} />
        <Skeleton
          variant="rounded"
          sx={{ width: 128, height: 40, marginTop: 2 }}
        />
      </Box>
    </Box>
  );
};
