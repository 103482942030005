import { Box, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Modal } from "components/Modal";
import { QuoteItem } from "components/modals/QuoteModal/QuoteItem";
import { EmptyShoppingCart } from "components/modals/ShoppingCartModal/EmptyShoppingCart";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { finishOrderPath } from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { Quote } from "types/models";
import { API } from "utils/api";
import { getPrice } from "utils/models/product";
import { isPending } from "utils/models/quote";
import { toFormattedPrice } from "utils/numbers";

export const QuoteModal = () => {
  const { quoteModalProps, setQuoteModalProps } = useContext(ModalContext);
  const navigate = useNavigate();

  const {
    data: quote,
    isLoading,
    isStale,
  } = useQuery<Quote>({
    queryKey: ["quotes", quoteModalProps.quoteId],
    queryFn: () =>
      API.get(`quotes/${quoteModalProps.quoteId}`).then(({ data }) => data),
    enabled: !!quoteModalProps.quoteId,
    staleTime: Infinity,
  });
  const companyBaseDiscount = quote?.company_base_discount;

  const handleClose = useCallback(
    () => setQuoteModalProps({ open: false, quoteId: undefined }),
    [setQuoteModalProps]
  );

  const finishQuote = useCallback(() => {
    navigate(`${finishOrderPath}?quoteId=${quote?.id}`);
    handleClose();
  }, [handleClose, navigate, quote?.id]);

  const totalPrice = useMemo(
    () =>
      quote?.quote_items.reduce(
        (total, item) =>
          total +
          item.amount *
            (getPrice({
              amount: item.amount,
              price: item.product_price,
              companyBaseDiscount,
              companyDiscount: item.company_discount,
              promotionDiscount: item.promotion_discount,
              promotionMinimumAmount: item.promotion_minimum_amount,
            }) || 0),
        0
      ) || 0,
    [companyBaseDiscount, quote?.quote_items]
  );

  if (!quoteModalProps.quoteId) return null;

  return (
    <Modal
      open={quoteModalProps.open}
      onClose={handleClose}
      ariaTitle="Modal de Cotización"
      style={{ width: "100%", maxWidth: "800px" }}
    >
      <Title>Cotización</Title>
      <Box display="flex" flexDirection="column" maxHeight="80%" marginY={1}>
        {isLoading && !quote ? (
          <Paragraph>Cargando...</Paragraph>
        ) : quote?.quote_items.length ? (
          quote?.quote_items?.map((item) => (
            <Box
              key={item.product_id}
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="end"
            >
              <QuoteItem isDisabled={isStale} quote={quote} quoteItem={item} />
            </Box>
          ))
        ) : (
          <EmptyShoppingCart handleClose={handleClose} />
        )}
      </Box>
      {quote?.quote_items.length ? (
        <Title
          font="sm"
          style={{ marginBottom: { xs: 1, md: 2 }, textAlign: "end" }}
        >
          Subtotal: $ {toFormattedPrice({ number: totalPrice })}
        </Title>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Button
          disabled={isLoading || isStale}
          onClick={handleClose}
          sx={{
            flexGrow: 1,
            marginY: { xs: 1, md: 0 },
            marginRight: isPending(quote) ? { md: 5 } : 0,
          }}
          variant="outlined"
        >
          Cerrar
        </Button>
        {isPending(quote) && (
          <Button
            disabled={isLoading || isStale}
            onClick={finishQuote}
            sx={{ flexGrow: 1, marginLeft: { md: 5 } }}
            variant="contained"
          >
            Ir a finalizar cotización
          </Button>
        )}
      </Box>
    </Modal>
  );
};
