import { useMemo } from "react";

import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { Sync } from "components/Sync";
import { Column, Table } from "components/Table";
import { useAllBrands } from "hooks/useAllBrands";
import { useAllGroups } from "hooks/useAllGroups";
import { useAllLines } from "hooks/useAllLines";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { Product } from "types/models";
import { BLUECAR_ROLES_IDS, isBluecarRole } from "utils/models/user";

const baseColumns: Column<Product>[] = [
  { id: "name", label: "Nombre" },
  { id: "brand_name", label: "Marca" },
  { id: "line_name", label: "Linea" },
  { id: "group_name", label: "Grupo" },
  {
    id: "oem_codes",
    label: "Códigos OEM",
    renderer: (product) => (
      <Paragraph disableSelect>{product.oem_codes?.join(", ")}</Paragraph>
    ),
  },
];

const activeColumn: Column<Product> = {
  id: "active",
  label: "Activo",
  renderer: (product) => (Boolean(product.active) ? "Sí" : "No"),
};

const allFilters = [
  {
    id: "search",
    label: "Nombre / Descripción / Código OEM",
    style: { width: { md: 320 } },
  },
  { id: "brand_id", label: "Marca", options: [] },
  { id: "line_id", label: "Linea", options: [] },
  { id: "group_id", label: "Grupo", options: [] },
  {
    id: "active",
    label: "Activo",
    roles: BLUECAR_ROLES_IDS,
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeProducts = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Productos",
    roles: BLUECAR_ROLES_IDS,
  });
  const { user } = useAuthentication();
  const { data: lines } = useAllLines();
  const { data: brands } = useAllBrands({});
  const { data: groups } = useAllGroups();

  const columns = useMemo(() => {
    if (user && isBluecarRole(user)) {
      return [...baseColumns, activeColumn];
    }
    return baseColumns;
  }, [user]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters
      .filter((filter) =>
        filter.roles ? filter.roles.includes(user?.role) : true
      )
      .map((filter) => {
        if (filter.id === "line_id") {
          return {
            ...filter,
            options:
              lines?.map((line) => ({
                id: line.id,
                label: line.name,
              })) || [],
          };
        } else if (filter.id === "brand_id") {
          return {
            ...filter,
            options:
              brands?.map((brand) => ({
                id: brand.id,
                label: brand.name,
              })) || [],
          };
        } else if (filter.id === "group_id") {
          return {
            ...filter,
            options:
              groups?.map((group) => ({
                id: group.id,
                label: group.name,
              })) || [],
          };
        }
        return filter;
      });
  }, [lines, brands, groups, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          filtersRightNode={<Sync kind="products" />}
          queryKey="products"
          path="products"
        />
      )}
    </BackofficeLayout>
  );
};
