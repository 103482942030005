import { useMemo } from "react";

import { Column, Table } from "components/Table";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { Group } from "types/models";
import { BLUECAR_ROLES_IDS, isBluecarRole } from "utils/models/user";

const baseColumns: Column<Group>[] = [{ id: "name", label: "Nombre" }];

const activeColumn: Column<Group> = {
  id: "active",
  label: "Activo",
  renderer: (group) => (Boolean(group.active) ? "Sí" : "No"),
};

const allFilters = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activo",
    roles: BLUECAR_ROLES_IDS,
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeGroups = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Grupos",
    roles: BLUECAR_ROLES_IDS,
  });
  const { user } = useAuthentication();

  const columns = useMemo(() => {
    if (user && isBluecarRole(user)) {
      return [...baseColumns, activeColumn];
    }
    return baseColumns;
  }, [user]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters.filter((filter) =>
      filter.roles ? filter.roles.includes(user?.role) : true
    );
  }, [user]);

  return (
    <BackofficeLayout>
      <Table
        columns={columns}
        filters={filters}
        queryKey="groups"
        path="groups"
      />
    </BackofficeLayout>
  );
};
