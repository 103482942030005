import SearchIcon from "@mui/icons-material/Search";
import {
  InputBase,
  styled,
  colors,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { forwardRef, useCallback } from "react";

const Search = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: colors.grey[100],
  color: colors.grey[900],
  marginLeft: 0,
  width: "40%",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

type Props = {
  style?: SxProps<Theme>;
  onSearch: () => void;
  onDismiss?: () => void;
};

export const PublicSearchBar = forwardRef((props: Props, ref) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const handleKeyDown = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event?.key === "Enter") {
        props.onSearch();
      }
    },
    [props]
  );

  return (
    <Search sx={props.style}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        inputRef={ref}
        placeholder="¿Qué producto estás buscando hoy?"
        inputProps={{ "aria-label": "search" }}
        onBlur={props.onDismiss}
        onKeyDown={handleKeyDown}
        sx={{ fontSize: xsDevice ? "0.75rem" : "1rem" }}
      />
      <Button onClick={props.onSearch} sx={{ paddingY: 1, paddingX: 2 }}>
        Buscar
      </Button>
    </Search>
  );
});
