import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Button, Tooltip, Box } from "@mui/material";
import { green, yellow } from "@mui/material/colors";
import { useMutation } from "@tanstack/react-query";
import { useMemo, useContext, useCallback } from "react";

import { Filter } from "components/FilterPaginated";
import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAllBrands } from "hooks/useAllBrands";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { PromotionCreationModal } from "modals/PromotionCreationModal";
import { Promotion } from "types/models";
import { API } from "utils/api";
import { parseDate } from "utils/date";
import {
  invalidateQueries,
  isCurrent,
  isFuture,
} from "utils/models/promotions";
import { BLUECAR_ROLES_IDS, isAdmin } from "utils/models/user";

const baseColumns: Column<Promotion>[] = [
  {
    id: "product_id",
    label: "Producto/Marca",
    renderer: (promotion) =>
      promotion.product_name ? (
        <Paragraph>Producto: {promotion.product_name}</Paragraph>
      ) : (
        <Paragraph>Marca: {promotion.brand_name}</Paragraph>
      ),
  },
  {
    id: "id",
    label: "Estado",
    renderer: (promotion) => {
      const startDate = parseDate(promotion.start_date);
      const endDate = parseDate(promotion.end_date);
      const today = new Date();
      if (endDate < today) return "Finalizada";
      if (promotion.paused)
        return <Paragraph style={{ color: yellow[900] }}>Pausada</Paragraph>;
      if (startDate > today)
        return (
          <Paragraph style={{ color: yellow[900] }}>
            Por comenzar: {promotion.start_date}
          </Paragraph>
        );
      return <Paragraph style={{ color: green[600] }}>Activa</Paragraph>;
    },
  },
  {
    id: "discount",
    label: "Descuento",
    renderer: (promotion) => `${promotion.discount}%`,
  },
  { id: "minimum_amount", label: "Cantidad mínima" },
  {
    id: "image_url",
    label: "Banner",
    renderer: (promotion) =>
      promotion.image_url ? (
        <a href={promotion.image_url} target="_blank">
          Ver banner
        </a>
      ) : (
        "Sin banner"
      ),
  },
  { id: "start_date", label: "Desde" },
  { id: "end_date", label: "Hasta" },
];

const allFilters: Filter[] = [
  { id: "product_name", label: "Producto" },
  {
    id: "brand_id",
    label: "Marca",
    options: [],
  },
  {
    id: "date",
    label: "Fecha",
    type: "date",
  },
];

type UpdateParams = {
  id: string;
  params: {
    paused?: boolean;
  };
};

export const BackofficePromotions = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Promociones",
    roles: BLUECAR_ROLES_IDS,
  });
  const { setConfirmationModalProps, setDuplicatePromotionModalProps } =
    useContext(ModalContext);
  const { infoToast, errorToast } = useToast();
  const { user } = useAuthentication();
  const { data: brands } = useAllBrands({});

  const { mutateAsync: deletePromotion } = useMutation({
    mutationFn: (promotionId: string) =>
      API.delete(`/promotions/${promotionId}`),
    onSuccess: () => {
      infoToast("Promoción eliminada con éxito.");
      invalidateQueries();
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido eliminar la promoción, intenta nuevamente"),
  });

  const { mutateAsync: updatePromotion } = useMutation({
    mutationFn: ({ id, params }: UpdateParams) =>
      API.put(`/promotions/${id}`, params),
    onSuccess: (_, { params }: UpdateParams) => {
      infoToast(
        `Promoción ${params.paused ? "pausada" : "activada"} con éxito.`
      );
      invalidateQueries();
      setConfirmationModalProps({ open: false });
    },
    onError: ({ response }: any, { params }: UpdateParams) =>
      errorToast(
        `No se ha podido ${
          params.paused ? "pausar" : "activar"
        } la promoción, intenta nuevamente: ${response.data}`
      ),
  });

  const handleDuplicate = useCallback(
    (promotion: Promotion) => {
      setDuplicatePromotionModalProps({
        open: true,
        brand: {
          id: promotion.brand_id ?? "",
          label: promotion.brand_name ?? "",
        },
        product: {
          id: promotion.product_id ?? "",
          label: promotion.product_name ?? "",
        },
        discount: promotion.discount.toString(),
        minimum: promotion.minimum_amount.toString(),
        image: promotion.image_url,
      });
    },
    [setDuplicatePromotionModalProps]
  );

  const actionColumn: Column<Promotion> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (promotion) => (
        <Box display="flex" justifyContent="center" gap="5px">
          <Tooltip title="Duplicar promoción">
            <Button onClick={() => handleDuplicate(promotion)}>
              <ContentCopyIcon titleAccess="Duplicar" />
            </Button>
          </Tooltip>
          {(isCurrent(promotion) || isFuture(promotion)) && (
            <>
              <Tooltip
                title={
                  promotion.paused ? "Activar promoción" : "Pausar promoción"
                }
              >
                <Button
                  onClick={() =>
                    setConfirmationModalProps({
                      open: true,
                      title: promotion.paused
                        ? "Activar promoción"
                        : "Pausar promoción",
                      text: `¿Quieres ${
                        promotion.paused ? "activar" : "pausar"
                      } la promoción sobre ${
                        promotion.product_name || promotion.brand_name
                      }, con fechas ${promotion.start_date} - ${
                        promotion.end_date
                      }?`,
                      buttonText: promotion.paused ? "Activar" : "Pausar",
                      buttonColor: "warning",
                      onSubmit: () =>
                        updatePromotion({
                          id: promotion.id,
                          params: {
                            paused: !promotion.paused,
                          },
                        }),
                    })
                  }
                >
                  {promotion.paused ? (
                    <PlayCircleOutlineIcon titleAccess="Activar" />
                  ) : (
                    <PauseCircleOutlineIcon titleAccess="Pausar" />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Eliminar promoción">
                <Button
                  onClick={() =>
                    setConfirmationModalProps({
                      open: true,
                      title: "Eliminar promoción",
                      text: `¿Quieres eliminar la promoción sobre ${
                        promotion.product_name || promotion.brand_name
                      }, con fechas ${promotion.start_date} - ${
                        promotion.end_date
                      }?`,
                      buttonText: "Eliminar",
                      buttonColor: "error",
                      onSubmit: () => deletePromotion(promotion.id),
                    })
                  }
                >
                  <DeleteIcon titleAccess="Eliminar" />
                </Button>
              </Tooltip>
            </>
          )}
        </Box>
      ),
      align: "center",
    }),
    [
      handleDuplicate,
      setConfirmationModalProps,
      updatePromotion,
      deletePromotion,
    ]
  );

  const columns = useMemo(
    () =>
      user && isAdmin(user) ? [...baseColumns, actionColumn] : baseColumns,
    [user, actionColumn]
  );

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters.map((filter) => {
      if (filter.id === "brand_id") {
        return {
          ...filter,
          options:
            brands?.map((brand) => ({
              id: brand.id,
              label: brand.name,
            })) || [],
        };
      }
      return filter;
    });
  }, [brands, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="promotions"
          path="promotions"
        />
      )}
      {!!user && isAdmin(user) && <PromotionCreationModal />}
    </BackofficeLayout>
  );
};
