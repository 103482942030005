import { useQuery, useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";

import { queryClient } from "App";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useToast } from "hooks/useToast";
import { ShoppingCartItem } from "types/models";
import { API } from "utils/api";

type Props = { skip?: boolean };

export const useShoppingCart = (props?: Props) => {
  const { errorToast } = useToast();
  const { user } = useAuthentication();

  const { company } = useContext(ModalContext);
  const {
    data: items,
    isLoading,
    isStale,
    error,
  } = useQuery<ShoppingCartItem[]>({
    staleTime: 1000 * 60 * 5,
    queryKey: ["shopping_cart_items", company?.id],
    queryFn: () =>
      API.get(`shopping_cart_items?company_id=${company?.id}`).then(
        ({ data }) => data
      ),
    enabled: !!user && !props?.skip,
    keepPreviousData: true,
    retry: false,
    retryOnMount: false,
  });

  const updateProductMutation = useMutation({
    mutationFn: async ({
      productId,
      amount,
    }: {
      productId: string;
      amount: number;
    }) => {
      try {
        await API.put("/shopping_cart_items", {
          amount,
          product_id: productId,
        });
        queryClient.invalidateQueries(["shopping_cart_items"]);
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const clearShoppingCartMutation = useMutation({
    mutationFn: async () => {
      try {
        await Promise.all(
          items?.map(({ product }) =>
            API.put("/shopping_cart_items", {
              amount: 0,
              product_id: product.id,
            })
          ) ?? []
        );
        queryClient.invalidateQueries(["shopping_cart_items"]);
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const itemsMap = useMemo(
    () =>
      items?.reduce((acc, item) => {
        acc[item.product.id] = item;
        return acc;
      }, {} as Record<string, ShoppingCartItem>),
    [items]
  );

  const getProductAmount = useCallback(
    ({ productId }: { productId: string }) =>
      itemsMap?.[productId]?.amount ?? 0,
    [itemsMap]
  );

  const updateProduct = useCallback(
    ({ productId, amount }: { productId: string; amount: number }) =>
      updateProductMutation.mutateAsync({ productId, amount }),
    [updateProductMutation]
  );

  const invalidateItems = useCallback(() => {
    queryClient.invalidateQueries(["shopping_cart_items"]);
  }, []);

  return {
    items: error ? undefined : items,
    isLoading:
      isLoading ||
      isStale ||
      updateProductMutation.isLoading ||
      clearShoppingCartMutation.isLoading,
    error,
    getProductAmount,
    updateProduct,
    clearShoppingCart: clearShoppingCartMutation.mutateAsync,
    invalidateItems,
  };
};
