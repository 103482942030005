import { queryClient } from "App";
import { Promotion } from "types/models";
import { parseDate } from "utils/date";

export const invalidateQueries = () => {
  queryClient.invalidateQueries(["promotions"]);
  queryClient.invalidateQueries(["current_promotions"]);
  queryClient.invalidateQueries(["current_promotions_home"]);
};

export const isCurrent = (promotion: Promotion) => {
  const today = new Date();
  const startDate = parseDate(promotion.start_date);
  const endDate = parseDate(promotion.end_date);

  return startDate <= today && today <= endDate;
};

export const isFuture = (promotion: Promotion) => {
  const today = new Date();
  const startDate = parseDate(promotion.start_date);

  return startDate > today;
};
