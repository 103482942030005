import { useCallback, useMemo } from "react";

import { ProductAmountHandler } from "components/ProductAmountHandler";
import { useAuthentication } from "hooks/useAuthentication";
import { useShoppingCart } from "hooks/useShoppingCart";
import { Product } from "types/models";

type Props = {
  product: Product;
  showTitle?: boolean;
  hidePrice?: boolean;
  hideHandler?: boolean;
  showIcon?: boolean;
};

export const ProductBoxShopping = ({
  product,
  showTitle,
  hidePrice,
  hideHandler,
  showIcon,
}: Props) => {
  const { user } = useAuthentication();
  const { isLoading, getProductAmount, updateProduct } = useShoppingCart();

  const productAmount = useMemo(
    () => getProductAmount({ productId: product.id }),
    [getProductAmount, product.id]
  );

  const handleAmountChange = useCallback(
    ({ amount }: { amount: number }) =>
      updateProduct({ productId: product.id, amount }),
    [product.id, updateProduct]
  );

  if (!user) return null;

  return (
    <ProductAmountHandler
      product={product}
      productAmount={productAmount}
      onAmountChange={handleAmountChange}
      isLoading={isLoading}
      showTitle={showTitle}
      hidePrice={hidePrice}
      hideHandler={hideHandler}
      showIcon={showIcon}
    />
  );
};
