import { Box, Button, Grow } from "@mui/material";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";

import logo from "assets/logo.png";
import { Link } from "components/Link";
import { Paper } from "components/Paper";
import { Paragraph } from "components/Paragraph";
import { productsPath } from "constants/routes";
import { useIsOnScreen } from "hooks/useIsOnScreen";
import { Promotion } from "types/models";

type Props = {
  promotion: Promotion;
  hideAnimation?: boolean;
};

export const PromotionBox = ({ hideAnimation, promotion }: Props) => {
  const [appearedOnScreen, setAppearedOnScreen] = useState<boolean>(false);
  const ref = useRef<HTMLElement>(null);
  useIsOnScreen({
    ref,
    onScreen: () => setAppearedOnScreen(true),
  });

  const alt = useMemo(
    () =>
      `${promotion.discount}% OFF en ${
        promotion.brand_name || promotion.product_name
      } hasta el ${promotion.end_date}`,
    [promotion]
  );

  const Wrapper = useCallback(
    ({ children }: { children: ReactElement }) =>
      hideAnimation ? (
        <>{children}</>
      ) : (
        <Grow
          in={appearedOnScreen}
          style={{ transformOrigin: "0 0 0" }}
          {...{ timeout: 2000 }}
          ref={ref}
        >
          {children}
        </Grow>
      ),
    [hideAnimation, appearedOnScreen]
  );

  return (
    <Wrapper>
      <Paper
        baseElevation={1}
        style={{
          margin: 1,
          minWidth: { xs: "146px", md: "320px" },
          maxWidth: { xs: "146px", md: "320px" },
        }}
      >
        <Link
          noMargin
          to={
            promotion.brand_id
              ? `${productsPath}?brand_id=${promotion.brand_id}`
              : `${productsPath}/${promotion.product_id}`
          }
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "100%",
            minHeight: "100%",
          }}
        >
          <Box alignItems="center" display="flex" flexGrow={1}>
            <Box
              component="img"
              src={!!promotion.image_url ? promotion.image_url : logo}
              alt={alt}
              sx={{
                borderTopRightRadius: 3,
                borderTopLeftRadius: 3,
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
          <Box paddingY={1} textAlign="center">
            <Paragraph style={{ fontWeight: "bold" }}>
              {promotion.brand_name || promotion.product_name}
            </Paragraph>
            <Paragraph>Hasta el {promotion.end_date}</Paragraph>
          </Box>
          <Button>Ver {promotion.brand_id ? "Catálogo" : "Producto"}</Button>
        </Link>
      </Paper>
    </Wrapper>
  );
};
