import { useMediaQuery, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { object, string } from "yup";

import { Form } from "components/Form";
import {
  FormSelect,
  SingleUpdatedValues,
  SingleOption,
} from "components/FormSelect";

type Props = {
  label?: string;
};

const NAME_ASC = { id: "name-asc", label: "Descripción A-Z" };
const SORTING_OPTIONS: SingleOption[] = [
  NAME_ASC,
  { id: "name-desc", label: "Descripción Z-A" },
  { id: "discount-asc", label: "Menor descuento" },
  { id: "discount-desc", label: "Mayor descuento" },
];

export const SortSelector = ({ label = "Ordernar por" }: Props) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  const [sorting, setSorting] = useState<SingleOption | undefined>(NAME_ASC);

  const formik = useFormik({
    initialValues: {
      "companies-sorting": SORTING_OPTIONS.find(
        (sortingOption) => sortingOption.id === sorting?.id
      ) as SingleOption,
    },
    validationSchema: object({
      "companies-sorting": object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione ordenamiento"),
    }),
    onSubmit: () => {},
  });

  const handleChange = useCallback(
    (updatedValue: SingleUpdatedValues) =>
      setSorting({
        id: updatedValue.value?.id || "",
        label: updatedValue.value?.label || "",
      }),
    [setSorting]
  );

  const SortSelectorComponent = useMemo(
    () => (
      <Form formik={formik} noMargin>
        <FormSelect
          name="companies-sorting"
          label={label}
          fullWidth={xsDevice}
          onChange={handleChange}
          options={SORTING_OPTIONS}
          style={{ marginY: -1 }}
          multiple={false}
          size="small"
        />
      </Form>
    ),
    [formik, handleChange, label, xsDevice]
  );

  return { SortSelector: SortSelectorComponent, sorting };
};
