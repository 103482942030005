import { Box, Button } from "@mui/material";
import { useContext, useMemo } from "react";

import { FilterPaginated } from "components/FilterPaginated";
import { Paragraph } from "components/Paragraph";
import { CompanySelector } from "components/screens/products/CompanySelector";
import { FiltersSkeleton } from "components/screens/products/FiltersSkeleton";
import { ProductBox } from "components/screens/products/ProductBox";
import { ProductBoxListSkeleton } from "components/screens/products/ProductBoxListSkeleton";
import { ProductsTable } from "components/screens/products/ProductsTable";
import { SortSelector } from "components/screens/products/SortSelector";
import { FilterPaginatedContext } from "contexts/FilterPaginatedProvider";
import { ModalContext } from "contexts/ModalProvider";
import { ProductsContext } from "contexts/ProductsProvider";
import { useAllBrands } from "hooks/useAllBrands";
import { useAllGroups } from "hooks/useAllGroups";
import { useAllLines } from "hooks/useAllLines";
import { useLayout } from "hooks/useLayout";
import { Product } from "types/models";
import { Paginated } from "types/paginated";
import { mapToSelect } from "utils/select";

const BRAND_FILTER_ID = "brand_id";
const LINE_FILTER_ID = "line_id";
const GROUP_FILTER_ID = "group_id";

export const Products = () => {
  const { PublicLayout } = useLayout({ title: "Productos" });
  const { tableView, setTableView } = useContext(ProductsContext);
  const { searchParamsObject } = useContext(FilterPaginatedContext);

  const { isShoppingCartModalOpen } = useContext(ModalContext);

  const { data: lines } = useAllLines({
    active: true,
    brand: searchParamsObject[BRAND_FILTER_ID],
    group: searchParamsObject[GROUP_FILTER_ID],
  });
  const { data: brands } = useAllBrands({
    active: true,
    line: searchParamsObject[LINE_FILTER_ID],
    group: searchParamsObject[GROUP_FILTER_ID],
  });
  const { data: groups } = useAllGroups({
    active: true,
    brand: searchParamsObject[BRAND_FILTER_ID],
    line: searchParamsObject[LINE_FILTER_ID],
  });

  const filters = useMemo(
    () => [
      {
        id: "search",
        label: "Nombre / ID / Código OEM",
        style: { width: { md: 512 } },
      },
      {
        id: BRAND_FILTER_ID,
        label: "Marca",
        options: mapToSelect({ elements: brands }),
      },
      {
        id: LINE_FILTER_ID,
        label: "Linea",
        options: mapToSelect({ elements: lines }),
      },
      {
        id: GROUP_FILTER_ID,
        label: "Grupo",
        options: mapToSelect({ elements: groups }),
      },
    ],
    [lines, brands, groups]
  );

  const paginatedProps = useMemo(
    () => ({
      queryKey: "productsActiveTrue",
      path: "products?active=true",
      filters,
      filtersRightNode: (
        <Box
          sx={{
            alignItems: { xs: "flex-end", md: "center" },
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: { xs: 3, md: 1 },
            margin: { xs: 1, md: 0 },
          }}
        >
          <Button onClick={() => setTableView(!tableView)}>
            {tableView ? "Ver como catálogo" : "Ver como lista"}
          </Button>
          <SortSelector />
          {!isShoppingCartModalOpen && <CompanySelector />}
        </Box>
      ),
    }),
    [filters, isShoppingCartModalOpen, setTableView, tableView]
  );

  return (
    <PublicLayout>
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
        {!filters || !lines || !brands || !groups ? (
          <>
            <FiltersSkeleton />
            <ProductBoxListSkeleton />
          </>
        ) : tableView ? (
          <ProductsTable {...paginatedProps} />
        ) : (
          <FilterPaginated
            {...paginatedProps}
            LoadingComponent={ProductBoxListSkeleton}
            renderer={(data: Paginated<Product>) => (
              <>
                {data?.results.length === 0 && (
                  <Paragraph style={{ marginTop: 3 }}>
                    Sin resultados.
                  </Paragraph>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {data?.results.map((product) => (
                    <ProductBox key={product.id} product={product} />
                  ))}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </PublicLayout>
  );
};
