type MapToSelectArgs<T extends object> = {
  elements?: Array<T>;
  elementId?: keyof T;
} & (
  | { elementLabel?: never; elementLabelFn: (element: T) => string }
  | { elementLabel?: keyof T; elementLabelFn?: never }
);

export function mapToSelect<T extends object>({
  elements,
  elementId = "id" as keyof T,
  elementLabel = "name" as keyof T,
  elementLabelFn,
}: MapToSelectArgs<T>) {
  return (
    elements?.map?.((element) => ({
      id: element[elementId] as string,
      label: elementLabelFn
        ? elementLabelFn(element)
        : (element[elementLabel] as string),
    })) || []
  );
}
