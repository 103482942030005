import { Box, alpha, colors } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { CSSProperties } from "react";
import MaterialCarousel from "react-material-ui-carousel";

type Props = {
  animation?: "fade" | "slide";
  indicators?: boolean;
  items?: { title: string; imageUrl: string }[];
  style?: SxProps<Theme>;
  imageStyles?: SxProps<Theme>;
  navButtonsStyles?: CSSProperties;
  onImageClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
};

export const Carousel = ({
  animation = "fade",
  indicators = false,
  items,
  style,
  imageStyles = {},
  navButtonsStyles = {},
  onImageClick,
}: Props) => {
  if (!items?.length) return null;

  const defaultNavButtonStyles: CSSProperties = {
    backgroundColor: alpha(colors.grey[300], 0.3),
  };

  return (
    <MaterialCarousel
      navButtonsProps={{
        style: {
          ...defaultNavButtonStyles,
          ...navButtonsStyles,
        },
      }}
      sx={{
        marginTop: { xs: 0, md: -3 },
        ...style,
      }}
      animation={animation}
      indicators={items.length > 1 && indicators}
      navButtonsAlwaysVisible={items.length > 1}
      navButtonsAlwaysInvisible={items.length <= 1}
      fullHeightHover={false}
      cycleNavigation
    >
      {items.map((item) => (
        <Box
          key={item.title}
          component="img"
          style={{ display: "block", margin: "auto" }}
          src={item.imageUrl}
          alt={item.title}
          width="100%"
          sx={{ height: { xs: "auto", md: 400 }, ...imageStyles }}
          onClick={onImageClick}
        />
      ))}
    </MaterialCarousel>
  );
};
