import { Button } from "@mui/material";
import { useField } from "formik";
import { ChangeEvent, useRef, useState } from "react";

import { useToast } from "hooks/useToast";
import { PresignedUrl } from "types/models";
import { API } from "utils/api";

type Props = {
  presignedPath: string;
  name: string;
  initialFilename?: string;
  text?: string;
  required: boolean;
};

export const FormUploadButton = ({
  presignedPath,
  name,
  initialFilename,
  required,
  text = "Cargar archivo",
}: Props) => {
  const { errorToast } = useToast();
  const [field, , helpers] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filename, setFilename] = useState<null | undefined | string>(
    initialFilename
  );

  const handleFileUpload = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files && target.files.length ? target.files[0] : null;
    if (!file) return;

    helpers.setValue("");
    setFilename(null);
    setIsLoading(true);
    try {
      const { data } = await API.get<PresignedUrl>(
        `${presignedPath}?filename=${file.name}`
      );
      const { url: presignedUrl, fields } = data;

      const { data: fileUploadResponse } = await API.postForm(
        presignedUrl,
        {
          ...fields,
          file,
        },
        { headers: { authorization: null } }
      );

      const location = fileUploadResponse.match(
        /<Location>([^<]*)<\/Location>/
      );
      if (!location[1]) throw new Error("No se ha podido cargar el archivo");

      setFilename(file.name);
      helpers.setValue(location[1]);
    } catch (_) {
      errorToast("No se ha podido cargar el archivo, intenta nuevamente");
    }
    setIsLoading(false);
  };

  const openFileSelector = () => inputRef.current?.click();

  if (!presignedPath) return null;

  return (
    <>
      <input
        ref={inputRef}
        name={`${name}-file-input`}
        type="file"
        style={{ display: "none " }}
        onChange={handleFileUpload}
      />
      <input
        type="text"
        name={name}
        value={field.value}
        style={{ display: "none " }}
        required={required}
      />

      <Button
        onClick={openFileSelector}
        disabled={isLoading}
        variant="outlined"
        sx={{ marginY: 1 }}
      >
        {isLoading
          ? "Cargando..."
          : filename
          ? `${filename} - Haz click para actualizar`
          : text}
      </Button>
    </>
  );
};
