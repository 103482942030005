import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Fab,
  Fade,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";

import useScrollTo from "hooks/useScrollTo";

interface ScrollToTopFabProps {
  anchorSelector: string;
}

const ScrollToTopFab = ({
  anchorSelector,
}: ScrollToTopFabProps): JSX.Element => {
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.down("md"));

  const trigger = useScrollTrigger({ disableHysteresis: true });
  const scrollTo = useScrollTo();

  const handleClick = useCallback(() => {
    scrollTo(anchorSelector);
  }, [scrollTo, anchorSelector]);

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        sx={{
          position: "fixed",
          bottom: { xs: 24, md: 54 },
          right: { xs: 24, md: 54 },
          zIndex: 100,
        }}
      >
        <Fab
          size={smDevice ? "small" : "medium"}
          color="primary"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  );
};

export default ScrollToTopFab;
