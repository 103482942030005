import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const setAuthenticationHeader = ({ token }: { token: string }) => {
  API.defaults.headers.authorization = `Bearer ${token}}`;
};

export const removeAuthenticationHeader = () => {
  delete API.defaults.headers.authorization;
};
