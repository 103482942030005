import { useMediaQuery, useTheme } from "@mui/material";
import { Form as FormikForm, FormikContextType, FormikProvider } from "formik";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  formik: FormikContextType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  noMargin?: boolean;
  flexDirection?: "column" | "row";
};

export const Form = ({
  children,
  formik,
  flexDirection = "column",
  noMargin,
}: Props) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <FormikProvider value={formik}>
      <FormikForm
        style={{
          display: "flex",
          flexDirection: xsDevice ? "column" : flexDirection,
          marginTop: noMargin ? 0 : 8,
          marginBottom: noMargin ? 0 : 8,
        }}
      >
        {children}
      </FormikForm>
    </FormikProvider>
  );
};
