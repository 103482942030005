import { Box } from "@mui/material";

import logo from "assets/logo.png";
import { Header } from "components/Header";
import { Paragraph } from "components/Paragraph";
import { CompanyRow } from "components/screens/company/CompanyRow";
import { useLayout } from "hooks/useLayout";

export const Company = () => {
  const { PublicLayout } = useLayout({ title: "Empresa" });
  const text = (
    <Paragraph style={{ textAlign: "justify" }}>
      <Paragraph font="xl" style={{ marginY: 5 }}>
        BLUECAR S.A. opera en el mercado de autopartes desde el año 2002 y
        cuenta una trayectoria familiar de tres generaciones en el rubro de
        repuestos de transportes, asumiendo la representación de primeras marcas
        nacionales e internacionales.
      </Paragraph>
      <Paragraph font="xl" style={{ marginY: 5 }}>
        Abastecemos al mercado de una amplia gama de repuestos para el sistema
        eléctrico y de inyección de automóviles y vehículos pesados, tales como
        camiones, maquinarias viales, agrícolas, ómnibus de corta y larga
        distancia.
      </Paragraph>
      <Paragraph font="xl" style={{ marginY: 5 }}>
        Contamos con un equipo de profesionales y especialistas técnicos
        dispuestos a satisfacer las necesidades de nuestros clientes. Nuestra
        misión y compromiso es brindar a nuestros clientes productos de calidad,
        con el mejor servicio.
      </Paragraph>
      <Paragraph font="xl" style={{ marginY: 5 }}>
        BLUECAR posee una amplia cobertura nacional y posee sucursales en la
        Ciudad Autónoma de Buenos Aires, en Pinamar y en San Nicolás (Pcia. de
        Buenos Aires) y en la Ciudad de Salta en el Noroeste Argentino.
      </Paragraph>
    </Paragraph>
  );

  return (
    <PublicLayout>
      <Header titleText="Empresa" />
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }}>
        <CompanyRow mdReverse imgAlt="Bluecar" imgSrc={logo}>
          {text}
        </CompanyRow>
        {/* <CompanyRow imgAlt="Bluecar" imgSrc={logo}>
          {text}
        </CompanyRow> */}
      </Box>
    </PublicLayout>
  );
};
