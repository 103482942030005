import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar as MaterialAppBar,
  Box,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useEffect, useCallback, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import logo from "assets/logo.png";
import { CartMenu } from "components/CartMenu";
import { Link } from "components/Link";
import { PublicAppBarLink } from "components/PublicAppBarLink";
import { PublicSearchBar } from "components/PublicSearchBar";
import { SendPriceList } from "components/SendPriceList";
import { Title } from "components/Title";
import { UserMenu } from "components/UserMenu";
import {
  companyPath,
  contactPath,
  finishOrderPath,
  homePath,
  productsPath,
  promotionsPath,
} from "constants/routes";

export const appBarHeight = "70px";

export const PublicAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isHomePath = useMatch(homePath);
  const isProductsPath = useMatch(productsPath);
  const isFinishOrderPath = useMatch(finishOrderPath);

  const [searchBar, setDisplaySearchBar] = useState<boolean>(false);
  const showSearchBar = useCallback(() => setDisplaySearchBar(true), []);
  const dismissSearchBar = useCallback(() => setDisplaySearchBar(false), []);
  const handleSearch = useCallback(() => {
    if (!!searchRef.current?.value)
      navigate(`${productsPath}?search=${searchRef.current?.value}`);
    dismissSearchBar();
  }, [dismissSearchBar, navigate]);

  useEffect(() => {
    if (searchBar) searchRef.current?.focus();
  }, [searchBar]);

  const pages = [
    { name: "Home", path: homePath },
    { name: "Productos", path: productsPath },
    { name: "Promociones", path: promotionsPath },
    { name: "Empresa", path: companyPath },
    { name: "Contacto", path: contactPath, external: true },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const responsiveMenu = (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton size="large" onClick={handleOpenNavMenu}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page) => (
          <Link key={page.name} to={page.path} external={page.external}>
            <MenuItem key={page.name} onClick={handleCloseNavMenu}>
              {page.name}
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Box>
  );

  return (
    <MaterialAppBar sx={{ backgroundColor: "white" }}>
      <Toolbar
        sx={{
          justifyContent: "center",
          height: appBarHeight,
        }}
      >
        {responsiveMenu}
        <Link to={homePath} noMargin>
          <Box component="img" sx={{ height: 54 }} alt="Bluecar" src={logo} />
        </Link>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={appBarHeight}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {pages.map((page) => (
            <PublicAppBarLink
              key={page.name}
              to={page.path}
              external={page.external}
            >
              <Title font="sm">{page.name}</Title>
            </PublicAppBarLink>
          ))}
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            flexGrow: { xs: 1, md: 0 },
          }}
        >
          {!isProductsPath && !isHomePath ? (
            <Tooltip title="Buscar">
              <IconButton onClick={showSearchBar} size="large">
                <SearchIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Box width={48} />
          )}
          <SendPriceList />
          {isFinishOrderPath ? null : <CartMenu />}
          <UserMenu />
        </Box>
      </Toolbar>
      <Collapse in={searchBar}>
        <Toolbar
          variant="dense"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <PublicSearchBar
            ref={searchRef}
            onDismiss={dismissSearchBar}
            onSearch={handleSearch}
          />
        </Toolbar>
      </Collapse>
    </MaterialAppBar>
  );
};
