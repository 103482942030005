import { Box, Button } from "@mui/material";
import { useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Modal } from "components/Modal";
import { EmptyShoppingCart } from "components/modals/ShoppingCartModal/EmptyShoppingCart";
import { LoginShoppingCart } from "components/modals/ShoppingCartModal/LoginShoppingCart";
import { ShoppingCartItem } from "components/modals/ShoppingCartModal/ShoppingCartItem";
import { Paragraph } from "components/Paragraph";
import { CompanySelector } from "components/screens/products/CompanySelector";
import { Title } from "components/Title";
import { finishOrderPath } from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useCompanyBaseDiscount } from "hooks/useCompanyBaseDiscount";
import { useShoppingCart } from "hooks/useShoppingCart";
import { getPrice } from "utils/models/product";
import { isBluecarRole as isBluecarRoleUtils } from "utils/models/user";
import { toFormattedPrice } from "utils/numbers";

export const ShoppingCartModal = () => {
  const navigate = useNavigate();

  const { user } = useAuthentication();
  const {
    company,
    isShoppingCartModalOpen,
    setIsShoppingCartModalOpen,
    setIsAuthenticationModalOpen,
  } = useContext(ModalContext);

  const { items, isLoading, clearShoppingCart } = useShoppingCart();

  const isBluecarRole = useMemo(
    () => !!user && isBluecarRoleUtils(user),
    [user]
  );

  const handleClose = useCallback(
    () => setIsShoppingCartModalOpen(false),
    [setIsShoppingCartModalOpen]
  );

  const handleLogin = useCallback(() => {
    setIsAuthenticationModalOpen(true);
    handleClose();
  }, [setIsAuthenticationModalOpen, handleClose]);

  const handleClearShoppingCart = useCallback(() => {
    clearShoppingCart().then(handleClose);
  }, [clearShoppingCart, handleClose]);

  const handleFinishOrder = useCallback(() => {
    navigate(finishOrderPath);
    handleClose();
  }, [handleClose, navigate]);

  const companyBaseDiscount = useCompanyBaseDiscount();
  const totalPrice = useMemo(
    () =>
      items?.reduce(
        (total, item) =>
          total +
          item.amount *
            (getPrice({
              amount: item.amount,
              price: item.product.price,
              companyBaseDiscount,
              companyDiscount: item.product.company_discount,
              promotionDiscount: item.product.promotion_discount,
              promotionMinimumAmount: item.product.promotion_minimum_amount,
            }) || 0),
        0
      ) || 0,
    [companyBaseDiscount, items]
  );

  return (
    <Modal
      open={isShoppingCartModalOpen}
      onClose={handleClose}
      ariaTitle="Modal de Carrito de compras"
      style={{
        width: "100%",
        maxWidth: !user || !items?.length ? "400px" : "800px",
      }}
    >
      <Title>Carrito de compras</Title>
      {items?.length ? (
        <Box
          sx={{
            marginBottom: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: 36,
          }}
        >
          <Paragraph onClick={handleClearShoppingCart} isDisabled={isLoading}>
            Vaciar carrito
          </Paragraph>
          {isBluecarRole ? (
            <CompanySelector style={{ marginY: { xs: 1, sm: 0 } }} />
          ) : null}
        </Box>
      ) : null}
      <Box display="flex" flexDirection="column" maxHeight="80%" marginY={1}>
        {user ? (
          isLoading && !items ? (
            <Paragraph>Cargando...</Paragraph>
          ) : items?.length ? (
            items?.map((item) => (
              <Box
                key={item.product.id}
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="end"
              >
                <ShoppingCartItem product={item.product} />
              </Box>
            ))
          ) : (
            <EmptyShoppingCart handleClose={handleClose} />
          )
        ) : (
          <LoginShoppingCart
            handleClose={handleClose}
            handleLogin={handleLogin}
          />
        )}
      </Box>
      {items?.length ? (
        <Title
          font="sm"
          style={{ marginBottom: { xs: 1, md: 2 }, textAlign: "end" }}
        >
          Subtotal: $ {toFormattedPrice({ number: totalPrice })}
        </Title>
      ) : null}
      {items?.length ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Button
            disabled={isLoading}
            onClick={handleClose}
            sx={{
              flexGrow: 1,
              marginY: { xs: 1, md: 0 },
              marginRight: { md: 5 },
            }}
            variant="outlined"
          >
            Cerrar
          </Button>
          <Button
            disabled={isLoading || (isBluecarRole && !company)}
            onClick={handleFinishOrder}
            sx={{ flexGrow: 1, marginLeft: { md: 5 } }}
            variant="contained"
          >
            Ver resumen
          </Button>
        </Box>
      ) : null}
    </Modal>
  );
};
