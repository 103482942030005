import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext } from "react";

import { queryClient } from "App";
import { ModalContext } from "contexts/ModalProvider";
import { useMe } from "hooks/useMe";
import {
  API,
  removeAuthenticationHeader,
  setAuthenticationHeader,
} from "utils/api";
import { removeToken, setToken } from "utils/cookies";

export const useAuthentication = () => {
  const { data: user, isLoading, isError } = useMe();
  const { setCompany } = useContext(ModalContext);

  const signInMutation = useMutation({
    mutationFn: async (form: { email: string; password: string }) => {
      const { data } = await API.post<{ token: string }>(
        "/users/sign_in",
        form
      );
      const { token } = data;
      setToken({ token });
      setAuthenticationHeader({ token });
      queryClient.invalidateQueries();
      return token;
    },
  });

  const signOut = useCallback(() => {
    removeToken();
    removeAuthenticationHeader();
    setCompany(undefined);
    queryClient.invalidateQueries();
  }, [setCompany]);

  return {
    signInMutation,
    signOut,
    user: isError ? undefined : user,
    isLoading,
  };
};
