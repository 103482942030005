import { Box, colors, useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Carousel } from "components/Carousel";
import { PublicSearchBar } from "components/PublicSearchBar";
import { HomeBox } from "components/screens/home/HomeBox";
import { HomeBoxSkeleton } from "components/screens/home/HomeBoxSkeleton";
import { HomeTitle } from "components/screens/home/HomeTitle";
import { PromotionBox } from "components/screens/promotions/PromotionBox";
import { PromotionBoxListSkeleton } from "components/screens/promotions/PromotionBoxListSkeleton";
import { productsPath, promotionsPath } from "constants/routes";
import { useAllBrands } from "hooks/useAllBrands";
import { useLayout } from "hooks/useLayout";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { Banner, Promotion } from "types/models";

export const Home = () => {
  const { PublicLayout } = useLayout({ title: "Home" });
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  const handleSearch = () =>
    navigate(`${productsPath}?search=${searchRef.current?.value}`);

  const { data: brandsData, isLoading: isLoadingBrands } = useAllBrands({
    active: true,
    showHome: true,
  });

  const { data: promotionsData, isLoading: isLoadingPromotions } =
    usePaginatedQuery<Promotion>({
      queryKey: ["current_promotions_home"],
      path: "promotions?current=true&per=8",
    });

  const { data: banners, isLoading: isLoadingBanners } =
    usePaginatedQuery<Banner>({
      queryKey: ["banners"],
      path: "banners?active=true&per=100",
    });
  const bannerItems = useMemo(
    () =>
      banners?.results.map((banner) => ({
        title: banner.name,
        imageUrl: banner.image_url,
      })) ?? [],
    [banners]
  );

  return (
    <PublicLayout>
      {isLoadingBanners && (
        <Box
          sx={{ marginTop: { xs: 0, md: -3 }, height: { xs: "auto", md: 400 } }}
        />
      )}
      <Carousel items={bannerItems} />
      <Box paddingY={3} paddingX={{ xs: 2, md: 7 }}>
        {xsDevice && (
          <PublicSearchBar
            ref={searchRef}
            onSearch={handleSearch}
            style={{ marginBottom: 2, borderRadius: 3 }}
          />
        )}
        <HomeTitle
          title="Marcas"
          rightButton={{
            to: productsPath,
            text: "Ver todas",
          }}
        >
          {!xsDevice && (
            <PublicSearchBar
              ref={searchRef}
              onSearch={handleSearch}
              style={{ borderRadius: 3, height: 60, marginX: 2 }}
            />
          )}
        </HomeTitle>
        <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
          {isLoadingBrands &&
            Array.from({ length: 6 }).map((_, i: number) => (
              <HomeBoxSkeleton key={i} />
            ))}
          {brandsData?.map((brand) => (
            <HomeBox
              key={brand.id}
              title={brand.name}
              image={brand.image_url}
              to={`${productsPath}?brand_id=${brand.id}`}
              style={{
                animation: { xs: "none", md: "leftRight 1s infinite" },
                animationDirection: { md: "alternate" },
                animationTimingFunction: { md: "ease-in-out" },
              }}
              hideTitle
            />
          ))}
        </Box>
      </Box>
      {isLoadingPromotions || promotionsData?.results.length ? (
        <Box
          paddingY={3}
          paddingX={{ xs: 2, md: 7 }}
          sx={{ backgroundColor: colors.grey[100] }}
        >
          <HomeTitle
            title="Promociones"
            rightButton={{
              to: promotionsPath,
              text: "Ver todas",
            }}
          />
          <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
            {isLoadingPromotions && <PromotionBoxListSkeleton />}
            {promotionsData?.results.map((promotion) => (
              <PromotionBox key={promotion.id} promotion={promotion} />
            ))}
          </Box>
        </Box>
      ) : null}
    </PublicLayout>
  );
};
