import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { string, number, object } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormSelect, SingleOption } from "components/FormSelect";
import { FormTextField } from "components/FormTextField";
import { useAllBrands } from "hooks/useAllBrands";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useAllProducts } from "hooks/useAllProducts";
import { useCreationModal } from "hooks/useCreationModal";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";
import { mapToSelect } from "utils/select";

export const CompanyDiscountCreationModal = () => {
  const [isProductDiscount, setIsProductDiscount] = useState<boolean>(false);
  const { CreationModal, handleClose: closeModal } = useCreationModal({
    title: "Crear descuento por empresa",
  });
  const { infoToast, errorToast } = useToast();
  const { data: brands } = useAllBrands({ active: true });
  const { data: products } = useAllProducts();
  const { data: companies } = useAllCompanies();

  const mappedBrands = useMemo(
    () => mapToSelect({ elements: brands }),
    [brands]
  );
  const mappedProducts = useMemo(
    () =>
      mapToSelect({
        elements: products,
        elementLabelFn: (product) => `${product.external_id} - ${product.name}`,
      }),
    [products]
  );
  const mappedCompanies = useMemo(
    () => mapToSelect({ elements: companies }),
    [companies]
  );

  const { mutateAsync: createCompanyDiscount, isLoading } = useMutation({
    mutationFn: (form: {
      productId: string;
      brandId: string;
      companyId: string;
      discount: string;
    }) =>
      API.post("/company_discounts", {
        discount: form.discount,
        product_id: isProductDiscount ? form.productId : null,
        brand_id: !isProductDiscount ? form.brandId : null,
        company_id: form.companyId,
      }),
    onSuccess: () => infoToast("Descuento creado con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido crear el descuento: ${errors}`);
    },
  });

  const objectValidationSchema = useMemo(
    () =>
      object({
        id: string().required(),
        label: string().required(),
      }),
    []
  );

  const formik = useFormik({
    initialValues: {
      brand: null as unknown as SingleOption,
      product: null as unknown as SingleOption,
      company: null as unknown as SingleOption,
      discount: "",
    },
    validationSchema: object().shape(
      {
        brand: object({}).when("product", {
          is: (product?: SingleOption) => !isProductDiscount && !product?.id,
          then: objectValidationSchema.required("Seleccione una marca"),
          otherwise: object({}).nullable(),
        }),
        product: object({}).when("brand", {
          is: (brand?: SingleOption) => isProductDiscount && !brand?.id,
          then: objectValidationSchema.required("Seleccione un producto"),
          otherwise: object({}).nullable(),
        }),
        company: objectValidationSchema.required("Seleccione la empresa"),
        discount: number()
          .positive("Ingrese un valor positivo")
          .max(100, "El descuento no puede ser mayor al 100%")
          .required("El descuento es requerido"),
      },
      [["product", "brand"]]
    ),
    onSubmit: (values) =>
      createCompanyDiscount(
        {
          ...values,
          companyId: values.company.id,
          productId: values.product?.id,
          brandId: values.brand?.id,
        },
        {
          onSuccess: () => {
            formik.resetForm();
            closeModal();
            queryClient.invalidateQueries(["company_discounts"]);
          },
        }
      ),
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    closeModal();
  }, [formik, closeModal]);

  const toggleIsProductDiscount = useCallback(
    () => setIsProductDiscount((value) => !value),
    []
  );

  return (
    <CreationModal>
      <Form formik={formik}>
        <FormTextField
          name="discount"
          label="Descuento"
          type="number"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormSelect
          name="company"
          label="Empresa"
          options={mappedCompanies}
          multiple={false}
          isDisabled={isLoading}
          fullWidth
          isRequired
        />
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isProductDiscount} />}
            label="Descuento por producto"
            onChange={toggleIsProductDiscount}
          />
        </FormGroup>
        {isProductDiscount ? (
          <FormSelect
            name="product"
            label="Producto"
            options={mappedProducts}
            multiple={false}
            isDisabled={isLoading}
            fullWidth
            isRequired
          />
        ) : (
          <FormSelect
            name="brand"
            label="Marca"
            options={mappedBrands}
            multiple={false}
            isDisabled={isLoading}
            fullWidth
            isRequired
          />
        )}
        <Box display="flex" marginTop="10px">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Crear descuento
          </Button>
        </Box>
      </Form>
    </CreationModal>
  );
};
