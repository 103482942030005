import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { productsPath } from "constants/routes";
import { useFullscreenImage } from "hooks/useFullscreenImage";
import { Product } from "types/models";

interface Props {
  product: Product;
}

export const ProductsTableItem = ({ product }: Props) => {
  const navigate = useNavigate();

  const { FullscreenImage, imageStyles, openImage } = useFullscreenImage({
    images: [
      {
        title: product.name,
        url: product.image_url || "https://via.placeholder.com/128x128",
      },
    ],
  });

  return (
    <>
      {FullscreenImage}
      <Box
        display="flex"
        gap={1}
        sx={{
          alignItems: { xs: "flex-start", md: "center" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          onClick={openImage}
          component="img"
          height={64}
          width={64}
          src={product.image_url || "https://via.placeholder.com/128x128"}
          alt={product.name}
          sx={{
            ...imageStyles,
            filter: product.stock === 0 ? "opacity(50%)" : "unset",
          }}
        />
        <Box>
          <Paragraph ellipsis uppercase>
            {product.brand_name}
            {product.brand_name && product.group_name ? " - " : ""}
            {product.group_name}
            {product.group_name && product.line_name ? " - " : ""}
            {product.line_name}
          </Paragraph>
          <Title
            onClick={() => navigate(`${productsPath}/${product.id}`)}
            font="sm"
            ellipsis
            tooltip
            uppercase
          >
            {product.name}
          </Title>
        </Box>
      </Box>
    </>
  );
};
