import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useContext, useCallback, useEffect } from "react";
import { object, string } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormSelect, SingleOption } from "components/FormSelect";
import { FormUploadButton } from "components/FormUploadButton";
import { Modal } from "components/Modal";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

const showHomeOptions: SingleOption[] = [
  { id: "true", label: "Sí" },
  { id: "false", label: "No" },
];

export const BrandEditModal = () => {
  const { infoToast, errorToast } = useToast();
  const { brandEditModalProps, setBrandEditModalProps } =
    useContext(ModalContext);

  const { mutateAsync: updateBrand, isLoading } = useMutation({
    mutationFn: (form: { imageUrl?: string; showHome?: boolean }) =>
      API.put(`/brands/${brandEditModalProps.brandId}`, {
        image_url: form.imageUrl,
        show_home: form.showHome,
      }),
    onSuccess: () => infoToast("Marca actualizada con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido actualizar la marcar: ${errors}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      showHome: undefined as unknown as SingleOption,
      imageUrl: undefined as unknown as string,
    },
    validationSchema: object().shape({
      showHome: object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione un valor"),
      imageUrl: string().when("showHome", {
        is: (showHome: SingleOption) => showHome.id === "true",
        then: string().required("Suba una imagen para la marca"),
      }),
    }),
    onSubmit: async ({ showHome, imageUrl }) => {
      try {
        await updateBrand({ imageUrl, showHome: showHome.id === "true" });
        formik.resetForm();
        setBrandEditModalProps({
          open: false,
          brandId: undefined,
          brandName: undefined,
          showHome: undefined,
          imageUrl: undefined,
        });
        queryClient.invalidateQueries(["brands"]);
      } catch (_) {}
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setBrandEditModalProps({
      open: false,
      brandId: undefined,
      brandName: undefined,
      showHome: undefined,
      imageUrl: undefined,
    });
  }, [formik, setBrandEditModalProps]);

  useEffect(() => {
    formik.setValues({
      showHome: brandEditModalProps.showHome
        ? showHomeOptions[0]
        : showHomeOptions[1],
      imageUrl: brandEditModalProps.imageUrl || "",
    });
  }, [brandEditModalProps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={brandEditModalProps.open}
      onClose={handleClose}
      ariaTitle={`Modal para editar ${brandEditModalProps.brandName}`}
    >
      <Title>Editar {brandEditModalProps.brandName}</Title>
      <Form formik={formik}>
        <FormSelect
          name="showHome"
          label="Mostrar en home"
          options={showHomeOptions}
          multiple={false}
          isDisabled={isLoading || formik.isSubmitting}
          fullWidth
          isRequired
        />
        <FormUploadButton
          presignedPath="brands/brand_image_url"
          text="Cargar imagen"
          name="imageUrl"
          initialFilename={brandEditModalProps.imageUrl}
          required={false}
        />
        <Box display="flex" marginTop={1}>
          <Button
            disabled={isLoading || formik.isSubmitting}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Actualizar
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
