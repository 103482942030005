import { InfoOutlined } from "@mui/icons-material";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import {
  Box,
  Button,
  Tooltip,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useContext, useMemo, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { object, string } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormSelect, SingleOption } from "components/FormSelect";
import { Paragraph } from "components/Paragraph";
import { ProductAmountHandler } from "components/ProductAmountHandler";
import { ProductExternalId } from "components/ProductExternalId";
import { Column, ResponsiveTable } from "components/ResponsiveTable";
import { CompanySelector } from "components/screens/products/CompanySelector";
import { Spinner } from "components/Spinner";
import { Title } from "components/Title";
import {
  backofficeOrdersPath,
  backofficePath,
  backofficeQuotesPath,
  productsPath,
} from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useCompanyBaseDiscount } from "hooks/useCompanyBaseDiscount";
import { useLayout } from "hooks/useLayout";
import { useShoppingCart } from "hooks/useShoppingCart";
import { useToast } from "hooks/useToast";
import { QuoteItem, Quote, ShoppingCartItem, Company } from "types/models";
import { API } from "utils/api";
import {
  BLUECAR_ADDRESSES,
  DELIVERY_OPTIONS,
  WITH_DELIVERY_ID,
} from "utils/models/address";
import { taxMultiplierForProduct } from "utils/models/company";
import { getDiscounts, getPrice } from "utils/models/product";
import { APPROVED, CANCELLED, isPending, Status } from "utils/models/quote";
import { isBluecarRole as isBluecarRoleUtils } from "utils/models/user";
import { toFormattedPrice } from "utils/numbers";

type Params =
  | {
      items?: QuoteItem[];
      isQuote: true;
    }
  | {
      items?: ShoppingCartItem[];
      isQuote: false;
    };

type FinishOrderItem = QuoteItem & {
  price: number;
  stock: number;
  min_mult: number;
};

const getItems = (params: Params): FinishOrderItem[] | undefined => {
  if (params.isQuote) {
    return params.items?.map((item) => ({
      ...item,
      min_mult: item.product.min_mult,
      stock: item.product.stock ?? 0,
      price: item.product_price ?? 0,
    }));
  }
  return params.items?.map((item) => ({
    id: item.product.id,
    product: {
      active: item.product.active,
      oem_codes: item.product.oem_codes,
      tax: item.product.tax,
      min_mult: item.product.min_mult,
    },
    amount: item.amount,
    product_id: item.product.id,
    product_name: item.product.name,
    product_image_url: item.product.image_url,
    product_external_id: item.product.external_id,
    product_description: item.product.description,
    line_id: item.product.line_id,
    line_name: item.product.line_name,
    group_id: item.product.group_id,
    group_name: item.product.group_name,
    brand_id: item.product.brand_id,
    brand_name: item.product.brand_name,
    min_mult: item.product.min_mult,
    stock: item.product.stock ?? 0,
    price: item.product.price ?? 0,
    product_price: item.product.price,
    company_discount: item.product.company_discount,
    promotion_discount: item.product.promotion_discount,
    promotion_minimum_amount: item.product.promotion_minimum_amount,
  }));
};

export const FinishOrder = () => {
  const { PublicLayout } = useLayout({ title: "Finalizar pedido" });
  const { infoToast, errorToast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  const [searchParams] = useSearchParams();
  const quoteId = useMemo(() => searchParams.get("quoteId"), [searchParams]);

  const { isLoading: isLoadingUser, user } = useAuthentication();
  const { company, setConfirmationModalProps } = useContext(ModalContext);

  const isBluecarRole = useMemo(
    () => !!user && isBluecarRoleUtils(user),
    [user]
  );

  if (!user && !isLoadingUser) navigate(productsPath);

  const {
    items: shoppingCartItems,
    isLoading: isLoadingShoppingCart,
    updateProduct,
    invalidateItems,
  } = useShoppingCart({ skip: !!quoteId });

  const { data: quote, isLoading: isLoadingQuote } = useQuery<Quote>({
    queryKey: ["quotes", quoteId],
    queryFn: () => API.get(`quotes/${quoteId}`).then(({ data }) => data),
    enabled: !!quoteId,
    staleTime: Infinity,
  });

  const companyBaseDiscountForShoppingCart = useCompanyBaseDiscount();
  const companyBaseDiscount = !!quoteId
    ? quote?.company_base_discount
    : companyBaseDiscountForShoppingCart;

  const items = getItems(
    !!quoteId
      ? { items: quote?.quote_items, isQuote: true }
      : { items: shoppingCartItems, isQuote: false }
  );

  if (quote && !isPending(quote)) {
    navigate(`${backofficePath}/${backofficeQuotesPath}`);
  }

  const companyId = !!quoteId
    ? quote?.company.id
    : user?.company?.id || company?.id;

  const { data: companyInfo, isLoading: isLoadingCompanyInfo } =
    useQuery<Company>({
      queryKey: ["companies", companyId],
      queryFn: () => API.get(`companies/${companyId}`).then(({ data }) => data),
      enabled: !!companyId,
      staleTime: Infinity,
    });

  const { subtotalPrice, totalPrice } = useMemo(() => {
    let subtotal = 0;
    let total = 0;

    items?.forEach((item) => {
      const itemSubtotal =
        item.amount *
        (getPrice({
          amount: item.amount,
          price: item.product_price,
          companyBaseDiscount,
          companyDiscount: item.company_discount,
          promotionDiscount: item.promotion_discount,
          promotionMinimumAmount: item.promotion_minimum_amount,
        }) || 0);
      const { tax } = taxMultiplierForProduct({
        company: companyInfo,
        product: item.product,
      });
      subtotal += itemSubtotal;
      total += itemSubtotal * tax;
    });

    return { subtotalPrice: subtotal, totalPrice: total };
  }, [companyBaseDiscount, companyInfo, items]);

  const {
    mutateAsync: updateQuoteItemAmount,
    isLoading: isLoadingQuoteItemUpdate,
  } = useMutation({
    mutationFn: async ({ id, amount }: { id: string; amount: number }) => {
      try {
        await API.put(`/quotes/${quoteId}/quote_items/${id}`, {
          amount,
        });
        queryClient.invalidateQueries(["quotes"]);
        queryClient.invalidateQueries(["quotes", quoteId]);
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const amountHandler = useCallback(
    (item: QuoteItem) =>
      quoteId
        ? ({ amount }: { amount: number }) =>
            updateQuoteItemAmount({
              amount,
              id: item.id,
            })
        : ({ amount }: { amount: number }) =>
            updateProduct({ amount, productId: item.product_id }),
    [quoteId, updateProduct, updateQuoteItemAmount]
  );

  const columns: Column<FinishOrderItem>[] = useMemo(
    () => [
      {
        id: "product",
        label: "Producto",
        renderer: (item) => (
          <>
            <Paragraph ellipsis uppercase>
              {item.brand_name}
              {item.brand_name && item.group_name ? " - " : ""}
              {item.group_name}
              {item.group_name && item.line_name ? " - " : ""}
              {item.line_name}
            </Paragraph>
            <Title
              onClick={() => navigate(`${productsPath}/${item.product_id}`)}
              font="sm"
              ellipsis
              tooltip
              uppercase
            >
              {item.product_name}
            </Title>
            <ProductExternalId externalId={item.product_external_id} />
          </>
        ),
      },
      {
        id: "amount",
        label: "Cantidad",
        align: "center",
        renderer: (item) => {
          return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ProductAmountHandler
                product={item}
                productAmount={item.amount}
                onAmountChange={amountHandler(item)}
                isLoading={isLoadingQuoteItemUpdate || isLoadingShoppingCart}
                hidePrice
              />
            </Box>
          );
        },
      },
      {
        id: "product",
        label: "Precio unitario",
        align: "center",
        renderer: (item) => (
          <Paragraph font="l">
            ${toFormattedPrice({ number: item.product_price ?? 0 })}
          </Paragraph>
        ),
      },
      {
        id: "product",
        label: "Precio unitario con descuento",
        align: "center",
        renderer: (item) => {
          const discountPrice = getPrice({
            price: item.product_price,
            amount: item.amount,
            companyBaseDiscount,
            companyDiscount: item.company_discount,
            promotionDiscount: item.promotion_discount,
            promotionMinimumAmount: item.promotion_minimum_amount,
          });
          return (
            <Paragraph font="l">
              ${toFormattedPrice({ number: discountPrice ?? 0 })}
            </Paragraph>
          );
        },
      },
      {
        id: "product",
        label: "Descuentos",
        align: "center",
        renderer: (item) => {
          const discounts = [];

          const allDiscounts = getDiscounts({
            amount: item.amount,
            companyBaseDiscount,
            companyDiscount: item.company_discount,
            promotionDiscount: item.promotion_discount,
            promotionMinimumAmount: item.promotion_minimum_amount,
          });

          if (allDiscounts.companyBaseDiscount) {
            discounts.push(
              <Paragraph
                tooltip
                tooltipText={`Descuento base ${
                  company?.label || quote?.company.name || user?.company?.name
                }`}
                font="l"
              >
                {allDiscounts.companyBaseDiscount}%
              </Paragraph>
            );
          }

          if (companyBaseDiscount && allDiscounts.companyDiscount) {
            discounts.push(" + ");
          }

          if (allDiscounts.companyDiscount) {
            discounts.push(
              <Paragraph
                tooltip
                tooltipText="Descuento en marca/producto"
                font="l"
              >
                {allDiscounts.companyDiscount}%
              </Paragraph>
            );
          }

          if (allDiscounts.promotionDiscount) {
            discounts.push(
              <Paragraph
                tooltip
                tooltipText={`Promoción a partir de ${item.promotion_minimum_amount} unidades`}
                font="l"
              >
                {allDiscounts.promotionDiscount}%
              </Paragraph>
            );
          }

          return discounts.length ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="5px"
            >
              {allDiscounts.promotionPossibleDicount ? (
                <Tooltip
                  title={`Si superas las ${allDiscounts.promotionMinimumAmount} unidades, se aplicará un ${allDiscounts.promotionPossibleDicount}%`.concat(
                    allDiscounts.companyBaseDiscount
                      ? `, en lugar del ${allDiscounts.companyBaseDiscount}% ${
                          allDiscounts.companyDiscount
                            ? `+ ${allDiscounts.companyDiscount}%`
                            : ""
                        } aplicado`
                      : ""
                  )}
                  enterTouchDelay={0}
                  arrow
                >
                  <NewReleasesOutlinedIcon sx={{ color: colors.yellow[800] }} />
                </Tooltip>
              ) : null}
              <Tooltip
                title="Posa el mouse sobre el descuento para ver más información"
                arrow
              >
                <InfoOutlined
                  fontSize="small"
                  sx={{ transform: "scale(85%)" }}
                />
              </Tooltip>
              {discounts}
            </Box>
          ) : (
            "-"
          );
        },
      },
      {
        id: "product",
        label: "Subotal con descuento",
        align: "center",
        renderer: (item) => {
          const discountPrice = getPrice({
            price: item.product_price,
            amount: item.amount,
            companyBaseDiscount,
            companyDiscount: item.company_discount,
            promotionDiscount: item.promotion_discount,
            promotionMinimumAmount: item.promotion_minimum_amount,
          });
          const totalWithoutDiscount = item.product_price! * item.amount;
          const totalWithDiscount = discountPrice! * item.amount;
          const hasDiscount = totalWithoutDiscount !== totalWithDiscount;

          return (
            <Box>
              {hasDiscount && (
                <Paragraph font="sm" style={{ textDecoration: "line-through" }}>
                  ${toFormattedPrice({ number: totalWithoutDiscount })}
                </Paragraph>
              )}
              <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
                $
                {toFormattedPrice({
                  number: hasDiscount
                    ? totalWithDiscount
                    : totalWithoutDiscount,
                })}
              </Title>
            </Box>
          );
        },
      },
      {
        id: "product",
        label: "Total con descuento",
        align: "center",
        renderer: (item) => {
          const discountPrice = getPrice({
            price: item.product_price,
            amount: item.amount,
            companyBaseDiscount,
            companyDiscount: item.company_discount,
            promotionDiscount: item.promotion_discount,
            promotionMinimumAmount: item.promotion_minimum_amount,
          });
          const totalWithDiscount = discountPrice! * item.amount;
          const { tax, humanTax } = taxMultiplierForProduct({
            company: companyInfo,
            product: item.product,
          });

          return (
            <Box>
              <Paragraph font="sm">(IVA {humanTax})</Paragraph>
              <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
                ${toFormattedPrice({ number: totalWithDiscount * tax })}
              </Title>
            </Box>
          );
        },
      },
    ],
    [
      amountHandler,
      company?.label,
      companyBaseDiscount,
      companyInfo,
      isLoadingQuoteItemUpdate,
      isLoadingShoppingCart,
      navigate,
      quote?.company.name,
      user?.company?.name,
    ]
  );

  const formik = useFormik({
    initialValues: {
      delivery: null as unknown as SingleOption,
      address: null as unknown as SingleOption,
    },
    validationSchema: object({
      delivery: object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione la forma de envío"),
      address: object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione la dirección"),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.resetForm();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    formik.setFieldValue("address", null);
  }, [formik.values.delivery]); // eslint-disable-line react-hooks/exhaustive-deps

  // Shopping cart actions

  const { mutateAsync: createQuote, isLoading: isCreatingQuote } = useMutation({
    mutationFn: async () => {
      if (!company) return Promise.reject("No se ha seleccionado una empresa");

      await API.post("/quotes", { company_id: company?.id });
      invalidateItems();
      queryClient.invalidateQueries(["quotes"]);
      navigate(`${backofficePath}/${backofficeQuotesPath}`);
      setConfirmationModalProps({ open: false });
    },
    onSuccess: () => infoToast("Cotización enviada con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido crear la cotización: ${errors}`);
    },
  });

  const { mutateAsync: createOrder, isLoading: isCreatingOrder } = useMutation({
    mutationFn: async () => {
      if (isBluecarRole && !company)
        return Promise.reject("No se ha seleccionado una empresa");

      if (!formik.values.address)
        return Promise.reject("No se ha seleccionado una dirección");

      const params = {
        company_id: isBluecarRole ? company?.id : null,
        delivery_info: {
          delivery: formik.values.delivery?.id === WITH_DELIVERY_ID,
          address_id: formik.values.address.id,
        },
      };

      await API.post("/orders", params);
      invalidateItems();
      queryClient.invalidateQueries(["orders"]);
      navigate(`${backofficePath}/${backofficeOrdersPath}`);
      setConfirmationModalProps({ open: false });
    },
    onSuccess: () => infoToast("¡Tu pedido se está generando!"),
    onError: (error: any) => {
      const message = error.response ? error.response.data : error;
      errorToast(`No se ha podido crear el pedido: ${message}`);
    },
  });

  // Quote actions

  const { mutateAsync: updateQuote, isLoading: isUpdatingQuote } = useMutation({
    mutationFn: async ({ status }: { status: Status }) => {
      try {
        if (!quote) return;

        if (!formik.values.address)
          return await Promise.reject("No se ha seleccionado una dirección");

        const isApproved = status === APPROVED;
        const params = {
          status,
          delivery_info: isApproved
            ? {
                delivery: formik.values.delivery?.id === WITH_DELIVERY_ID,
                address_id: formik.values.address.id,
              }
            : {},
        };

        await API.put(`/quotes/${quote.id}`, params);
        queryClient.invalidateQueries(["quotes"]);
        queryClient.invalidateQueries(["quotes", quote.id]);
        queryClient.invalidateQueries(["quotes", "pending_count"]);
        queryClient.invalidateQueries(["orders"]);

        if (isApproved) {
          infoToast("Cotización aprobada.");
          navigate(`${backofficePath}/${backofficeOrdersPath}`);
        } else {
          infoToast("Cotización cancelada.");
          navigate(`${backofficePath}/${backofficeQuotesPath}`);
        }

        setConfirmationModalProps({ open: false });
      } catch (_) {
        errorToast("No se ha podido actualizar la cotización");
      }
    },
  });

  const cancelQuote = useCallback(
    () => updateQuote({ status: CANCELLED }),
    [updateQuote]
  );

  const approveQuote = useCallback(
    () => updateQuote({ status: APPROVED }),
    [updateQuote]
  );

  // Disable and loading states

  const disableButtons = useMemo(
    () =>
      (quoteId && isLoadingCompanyInfo) ||
      (!quoteId && isLoadingShoppingCart) ||
      (quoteId && isLoadingQuote) ||
      isUpdatingQuote ||
      isCreatingQuote ||
      isCreatingOrder ||
      !formik.values.address,
    [
      isLoadingCompanyInfo,
      formik.values.address,
      isCreatingOrder,
      isUpdatingQuote,
      isCreatingQuote,
      isLoadingQuote,
      isLoadingShoppingCart,
      quoteId,
    ]
  );

  if (
    isLoadingUser ||
    (quoteId && isLoadingCompanyInfo) ||
    (quoteId && isLoadingQuote && !items) ||
    (!quoteId && isLoadingShoppingCart && !items)
  )
    return (
      <PublicLayout>
        <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
          <Spinner />
        </Box>
      </PublicLayout>
    );
  if (!user) navigate(productsPath);

  return (
    <PublicLayout>
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
        <Title
          style={{
            marginTop: 2,
            marginBottom: isBluecarRole && !quoteId ? 0 : 4,
          }}
        >
          Finaliza {quoteId ? "la cotización" : "el pedido"}
        </Title>
        {isBluecarRole && !quoteId ? (
          <CompanySelector
            style={{ marginY: 2 }}
            label="Selecciona la empresa"
          />
        ) : null}
        {items?.length ? (
          <>
            <ResponsiveTable data={items} columns={columns} />
            <Title font="sm" style={{ marginTop: 2 }}>
              Subtotal: ${toFormattedPrice({ number: subtotalPrice })}
            </Title>
            <Title font="md" style={{ marginTop: 2 }}>
              Total: ${toFormattedPrice({ number: totalPrice })}
            </Title>
            <Title font="md" style={{ marginTop: 2 }}>
              Información de envío
            </Title>
            {isBluecarRole && !quoteId && (
              <Paragraph>
                En caso de enviar una cotización, la dirección podrá ser
                modificada al confirmar el pedido.
              </Paragraph>
            )}
            <Box display="flex" alignItems="center" minHeight="72px">
              <Form formik={formik} flexDirection="row">
                <FormSelect
                  name="delivery"
                  label="Envío"
                  options={DELIVERY_OPTIONS}
                  multiple={false}
                  isRequired={true}
                  size="small"
                />
                <FormSelect
                  name="address"
                  label="Dirección"
                  options={
                    formik.values.delivery?.id === WITH_DELIVERY_ID
                      ? company?.addresses || companyInfo?.addresses || []
                      : BLUECAR_ADDRESSES
                  }
                  multiple={false}
                  isRequired={true}
                  width="360px"
                  size="small"
                  style={{ marginLeft: xsDevice ? 0 : 2 }}
                />
              </Form>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", md: "row" },
              }}
            >
              {quoteId && (
                <>
                  <Button
                    disabled={disableButtons}
                    variant="outlined"
                    color="error"
                    onClick={() =>
                      setConfirmationModalProps({
                        open: true,
                        title: "Cancelar cotización",
                        text: "¿Estás seguro de que quieres cancelar la cotización?",
                        buttonText: "Cancelar cotización",
                        buttonColor: "error",
                        onSubmit: cancelQuote,
                      })
                    }
                  >
                    Cancelar cotización
                  </Button>
                  <Button
                    sx={{ marginX: { md: 4 }, marginY: { xs: 2, md: 0 } }}
                    variant="outlined"
                    disabled={disableButtons}
                    onClick={() =>
                      navigate(`${backofficePath}/${backofficeQuotesPath}`)
                    }
                  >
                    Volver a cotizaciones
                  </Button>
                  <Button
                    disabled={disableButtons}
                    variant="contained"
                    onClick={() =>
                      setConfirmationModalProps({
                        open: true,
                        title: "Confirmar cotización",
                        text: "¿Estás seguro de que quieres crear aceptar la cotización?",
                        buttonText: "Aceptar cotización",
                        onSubmit: approveQuote,
                      })
                    }
                  >
                    Aprobar cotización
                  </Button>
                </>
              )}

              {!quoteId && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                  }}
                >
                  {isBluecarRole && (
                    <Button
                      variant="outlined"
                      disabled={disableButtons || (isBluecarRole && !company)}
                      onClick={() =>
                        setConfirmationModalProps({
                          open: true,
                          title: "Enviar cotización",
                          text: "¿Estás seguro de que quieres enviar la cotización?",
                          buttonText: "Enviar cotización",
                          onSubmit: createQuote,
                        })
                      }
                    >
                      Enviar cotización
                    </Button>
                  )}
                  <Button
                    disabled={disableButtons || (isBluecarRole && !company)}
                    variant="contained"
                    sx={{
                      marginLeft: { md: isBluecarRole ? 5 : 0 },
                      marginBottom: { xs: 2, md: 0 },
                    }}
                    onClick={() =>
                      setConfirmationModalProps({
                        open: true,
                        title: "Confirmación del pedido",
                        text: "¿Estás seguro de que quieres crear el pedido?",
                        buttonText: "Crear pedido",
                        onSubmit: createOrder,
                      })
                    }
                  >
                    Crear pedido
                  </Button>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <>
            <Paragraph style={{ marginY: 1 }}>
              ¡Tu carrito de compras se encuentra vacío!
            </Paragraph>
            <Button
              onClick={() => navigate(productsPath)}
              variant="contained"
              type="submit"
            >
              Agregar productos
            </Button>
          </>
        )}
      </Box>
    </PublicLayout>
  );
};
