import { Box, Button } from "@mui/material";

import { Paragraph } from "components/Paragraph";

type Props = {
  handleClose: () => void;
  handleLogin: () => void;
};

export const LoginShoppingCart = ({ handleClose, handleLogin }: Props) => {
  return (
    <>
      <Paragraph style={{ marginBottom: 2, marginTop: 1 }}>
        Inicia sesión para armar tu carrito
      </Paragraph>
      <Box display="flex">
        <Button
          onClick={handleClose}
          style={{ flexGrow: 1, marginRight: 5 }}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleLogin}
          style={{ flexGrow: 1, marginLeft: 5 }}
          variant="contained"
          type="submit"
        >
          Iniciar sesión
        </Button>
      </Box>
    </>
  );
};
