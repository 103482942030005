import { Box } from "@mui/material";

import { Footer } from "components/Footer";
import { PublicAppBar, appBarHeight } from "components/PublicAppBar";
import { ChildrenProps } from "types/props";

export const PublicLayout = ({ children }: ChildrenProps) => (
  <>
    <PublicAppBar />
    <Box
      component="main"
      sx={{
        flex: 1,
        paddingTop: appBarHeight,
      }}
    >
      {children}
    </Box>
    <Footer />
  </>
);
