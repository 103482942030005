import { Box, Button } from "@mui/material";
import { ReactNode } from "react";

import { Link } from "components/Link";
import { Title } from "components/Title";

type Props = {
  children?: ReactNode;
  title: string;
  rightButton?: {
    to: string;
    text: string;
  };
};

export const HomeTitle = ({ children, title, rightButton }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingX={2}
      paddingBottom={1}
      borderBottom="2px solid black"
      sx={{
        justifyContent: {
          xs: rightButton ? "space-between" : "center",
          md: "space-between",
        },
      }}
    >
      <Title font="xl">{title}</Title>
      {children}
      {rightButton?.to && rightButton?.text && (
        <Link to={rightButton.to} noMargin>
          <Button variant="outlined">{rightButton.text}</Button>
        </Link>
      )}
    </Box>
  );
};
