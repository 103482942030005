const TOKEN = "token";

export const getToken = () => {
  const cookies = document.cookie.split(";");
  return cookies.reduce((current, cookie) => {
    const [key, value] = cookie.split("=");
    if (key === TOKEN && value) {
      return value;
    }
    return current;
  }, "");
};
export const setToken = ({ token }: { token: string }) =>
  (document.cookie = `${TOKEN}=${token};path=/;`);
export const removeToken = () => {
  const cookies = document.cookie.split(";");
  cookies.forEach(() => {
    document.cookie = `${TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  });
};
