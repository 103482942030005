export const WITH_DELIVERY_ID = "true";

export const DELIVERY_OPTIONS = [
  { id: "false", label: "Retiro por sucursal" },
  { id: WITH_DELIVERY_ID, label: "Con envío" },
];

export const BLUECAR_ADDRESSES = [
  { id: "10000000001", label: "BARRACAS - California 2067, CABA" },
  { id: "10000000002", label: "BOEDO - Av. Boedo 2043, CABA" },
  { id: "10000000003", label: "PINAMAR - Av. Constitución 1451" },
  { id: "10000000004", label: "SAN NICOLAS - Av. Savio 56" },
  { id: "10000000005", label: "SALTA - Av. Jujuy 563" },
  { id: "10000000006", label: "BUEN AYRE - Polo industrial EBAPI - Lote 44" },
];
