import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "App";
import { Paragraph } from "components/Paragraph";
import { ProductAmountDisplay } from "components/ProductAmountDisplay";
import { ProductAmountHandler } from "components/ProductAmountHandler";
import { ProductExternalId } from "components/ProductExternalId";
import { Title } from "components/Title";
import { productsPath } from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { Quote, QuoteItem as QuoteItemModel } from "types/models";
import { API } from "utils/api";
import { isPending } from "utils/models/quote";

type Props = {
  isDisabled?: boolean;
  quote: Quote;
  quoteItem: QuoteItemModel;
};

export const QuoteItem = ({ isDisabled, quote, quoteItem }: Props) => {
  const { errorToast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const { setQuoteModalProps } = useContext(ModalContext);

  const { mutateAsync: updateQuoteItemAmount, isLoading } = useMutation({
    mutationFn: async ({ amount }: { amount: number }) => {
      try {
        await API.put(`/quotes/${quote.id}/quote_items/${quoteItem.id}`, {
          amount,
        });
        queryClient.invalidateQueries(["quotes", quote.id]);
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const handleClick = useCallback(() => {
    setQuoteModalProps({ open: false, quoteId: undefined });
    navigate(`${productsPath}/${quoteItem.product_id}`);
  }, [navigate, quoteItem.product_id, setQuoteModalProps]);

  const productPriceInfo = useMemo(
    () => ({
      id: quoteItem.product_id,
      min_mult: quoteItem.product.min_mult,
      price: quoteItem.product_price,
      company_discount: quoteItem.company_discount,
      promotion_discount: quoteItem.promotion_discount,
      promotion_minimum_amount: quoteItem.promotion_minimum_amount,
    }),
    [
      quoteItem.company_discount,
      quoteItem.product.min_mult,
      quoteItem.product_id,
      quoteItem.product_price,
      quoteItem.promotion_discount,
      quoteItem.promotion_minimum_amount,
    ]
  );

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingX: 2,
        paddingY: 1,
        margin: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="img"
        height={xsDevice ? 32 : 64}
        width={xsDevice ? 32 : 64}
        sx={{ objectFit: "contain" }}
        src={
          quoteItem.product_image_url || "https://via.placeholder.com/128x128"
        }
        alt={quoteItem.product_name}
      />
      <Box
        marginLeft={3}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
      >
        <Paragraph ellipsis uppercase>
          {quoteItem.brand_name}
          {quoteItem.brand_name && quoteItem.group_name ? " - " : ""}
          {quoteItem.group_name}
          {quoteItem.group_name && quoteItem.line_name ? " - " : ""}
          {quoteItem.line_name}
        </Paragraph>
        <Title
          onClick={quoteItem.product.active ? handleClick : undefined}
          font="sm"
          ellipsis
          uppercase
          tooltip
        >
          {quoteItem.product_name}
        </Title>
        <ProductExternalId externalId={quoteItem.product_external_id} />
        {quoteItem.product.oem_codes?.length ? (
          <Paragraph ellipsis uppercase disableSelect>
            Códigos OEM: {quoteItem.product.oem_codes.join(" ")}
          </Paragraph>
        ) : null}
        {xsDevice &&
          (isPending(quote) ? (
            <ProductAmountHandler
              product={productPriceInfo}
              productAmount={quoteItem.amount}
              onAmountChange={updateQuoteItemAmount}
              isLoading={isLoading || !!isDisabled}
            />
          ) : (
            <ProductAmountDisplay
              product={productPriceInfo}
              productAmount={quoteItem.amount}
            />
          ))}
      </Box>
      {!xsDevice &&
        (!isPending(quote) ? (
          <ProductAmountDisplay
            product={productPriceInfo}
            productAmount={quoteItem.amount}
          />
        ) : (
          <ProductAmountHandler
            product={productPriceInfo}
            productAmount={quoteItem.amount}
            onAmountChange={updateQuoteItemAmount}
            isLoading={isLoading || !!isDisabled}
          />
        ))}
    </Paper>
  );
};
