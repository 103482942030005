import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { AppRoutes } from "AppRoutes";
import ScrollToTopFab from "components/ScrollToTopFab";
import { ContactProvider } from "contexts/ContactProvider";
import { FilterPaginatedProvider } from "contexts/FilterPaginatedProvider";
import { ModalProvider } from "contexts/ModalProvider";
import { ProductsProvider } from "contexts/ProductsProvider";
import { ThemeProvider } from "ThemeProvider";
import { setAuthenticationHeader } from "utils/api";
import { getToken } from "utils/cookies";

export const queryClient = new QueryClient();

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setAuthenticationHeader({ token });
      queryClient.invalidateQueries(["users/me"]);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider>
      <ModalProvider>
        <ContactProvider>
          <ProductsProvider>
            <FilterPaginatedProvider>
              <QueryClientProvider client={queryClient}>
                <Box id="top" />
                <ScrollToTopFab anchorSelector="#top" />

                <AppRoutes />
              </QueryClientProvider>
            </FilterPaginatedProvider>
          </ProductsProvider>
        </ContactProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
