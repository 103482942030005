import { Typography, Tooltip } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useMemo } from "react";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  style?: SxProps<Theme>;
  font?: "sm" | "md" | "xl";
  ellipsis?: boolean;
  uppercase?: boolean;
  tooltip?: boolean;
  onClick?: () => void;
  disableSelect?: boolean;
};

export const Title = ({
  children,
  style,
  font = "md",
  ellipsis,
  uppercase,
  tooltip,
  onClick,
  disableSelect = false,
}: Props) => {
  const uppercaseStyle: SxProps<Theme> = useMemo(
    () => (uppercase ? { textTransform: "uppercase" } : {}),
    [uppercase]
  );

  const disableSelectStyle: SxProps<Theme> = useMemo(
    () => ({ "user-select": disableSelect ? "none" : "auto" }),
    [disableSelect]
  );

  const ellipsisStyle: SxProps<Theme> = useMemo(
    () =>
      ellipsis
        ? {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }
        : {},
    [ellipsis]
  );
  const onClickStyle: SxProps<Theme> = useMemo(
    () =>
      onClick
        ? {
            "&:hover": { textDecoration: "underline" },
            cursor: "pointer",
            color: "primary.main",
          }
        : {},
    [onClick]
  );

  const fontSize = font === "sm" ? 18 : font === "md" ? 20 : 22;
  const fontWeight = font === "sm" ? 700 : font === "md" ? 900 : 900;

  return (
    <Tooltip title={tooltip ? children : ""} arrow>
      <Typography
        sx={{
          fontSize,
          fontWeight,
          ...ellipsisStyle,
          ...disableSelectStyle,
          ...uppercaseStyle,
          ...onClickStyle,
          ...style,
        }}
        variant="body1"
        onClick={onClick}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
