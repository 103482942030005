import { Order } from "types/models";

export const CREATING = "creating";
export const COMPLETED = "completed";
export const GENERATED = "generated";
export const ERRORED = "errored";

export type Status =
  | typeof CREATING
  | typeof COMPLETED
  | typeof GENERATED
  | typeof ERRORED;

type StatusOption = {
  id: Status;
  label: string;
};

export const STATUS_OPTIONS: StatusOption[] = [
  { id: CREATING, label: "En proceso de creación" },
  { id: COMPLETED, label: "Completada" },
  { id: GENERATED, label: "Generada" },
  { id: ERRORED, label: "Con error" },
];

export const isCreating = (order?: Order) => order?.status === CREATING;
export const isCompleted = (order?: Order) => order?.status === COMPLETED;
export const isGenerated = (order?: Order) => order?.status === GENERATED;
export const isErrored = (order?: Order) => order?.status === ERRORED;

export const humanizeOrder = (order: Order) => {
  if (isCreating(order)) return "En proceso de creación";
  if (isCompleted(order)) return "Completada";
  if (isGenerated(order)) return "Generada";
  if (isErrored(order)) return "Error";
  return "";
};
