import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Paragraph } from "components/Paragraph";
import { ProductExternalId } from "components/ProductExternalId";
import { Column, ResponsiveTable } from "components/ResponsiveTable";
import {
  withSavedDiscount,
  withoutSavedDiscount,
} from "components/screens/order/columns";
import { Spinner } from "components/Spinner";
import { Title } from "components/Title";
import { productsPath } from "constants/routes";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { OrderItem, Order as OrderModel } from "types/models";
import { API } from "utils/api";
import { isBluecarRole as isBluecarRoleUtils } from "utils/models/user";
import { toFormattedPrice } from "utils/numbers";

export const Order = () => {
  const { PublicLayout } = useLayout({ title: "Detalle de pedido" });
  const { orderId } = useParams();
  const navigate = useNavigate();

  const { isLoading: isLoadingUser, user } = useAuthentication();

  const isBluecarRole = useMemo(
    () => !!user && isBluecarRoleUtils(user),
    [user]
  );

  const { data: order, isLoading: isLoadingOrder } = useQuery<OrderModel>({
    queryKey: ["orders", orderId],
    queryFn: () => API.get(`orders/${orderId}`).then(({ data }) => data),
    enabled: !!orderId,
    staleTime: Infinity,
  });

  if (
    !orderId ||
    (!user && !isLoadingUser) ||
    (!order && !isLoadingOrder) ||
    (order && !isBluecarRole && order.company.id !== user?.company?.id)
  ) {
    navigate(productsPath);
  }

  const items = useMemo(() => order?.order_items ?? [], [order?.order_items]);

  const { subtotalPrice, totalPrice } = useMemo(() => {
    let subtotal = 0;
    let total = 0;

    items?.forEach((orderItem) => {
      const discountPercentage =
        1 - orderItem.product_itris_final_discount / 100;
      const itemSubtotal =
        orderItem.amount *
        orderItem.product_itris_final_unit_price *
        discountPercentage;
      subtotal += itemSubtotal;
      total += itemSubtotal * orderItem.product_tax_multiplier;
    });

    return { subtotalPrice: subtotal, totalPrice: total };
  }, [items]);

  const columns: Column<OrderItem>[] = useMemo(
    () => [
      {
        id: "product_id",
        label: "Producto",
        renderer: (orderItem) => (
          <>
            <Paragraph ellipsis uppercase>
              {orderItem.brand_name}
              {orderItem.brand_name && orderItem.group_name ? " - " : ""}
              {orderItem.group_name}
              {orderItem.group_name && orderItem.line_name ? " - " : ""}
              {orderItem.line_name}
            </Paragraph>
            <Title
              onClick={() =>
                navigate(`${productsPath}/${orderItem.product_id}`)
              }
              font="sm"
              ellipsis
              tooltip
              uppercase
            >
              {orderItem.product_name}
            </Title>
            <ProductExternalId externalId={orderItem.product_external_id} />
          </>
        ),
      },
      {
        id: "amount",
        label: "Cantidad",
        align: "center",
      },
      {
        id: "product_price",
        label: "Precio unitario",
        align: "center",
        renderer: (orderItem) =>
          !orderItem.with_saved_discount ? (
            withoutSavedDiscount.productPrice(orderItem)
          ) : (
            <Paragraph font="l">
              $
              {toFormattedPrice({
                number: orderItem.product_itris_final_unit_price,
              })}
            </Paragraph>
          ),
      },
      {
        id: "product_itris_final_unit_price",
        label: "Precio unitario con descuento",
        align: "center",
        renderer: (orderItem) =>
          !orderItem.with_saved_discount ? (
            withoutSavedDiscount.productPriceWithDiscount(orderItem)
          ) : orderItem.product_itris_final_discount ? (
            <Paragraph font="l">
              $
              {toFormattedPrice({
                number:
                  orderItem.product_itris_final_unit_price *
                  (1 - orderItem.product_itris_final_discount / 100),
              })}
            </Paragraph>
          ) : (
            "-"
          ),
      },
      {
        id: "product_itris_final_discount",
        label: "Descuentos",
        align: "center",
        renderer: (orderItem) =>
          orderItem.with_saved_discount
            ? withSavedDiscount.discounts(orderItem, order)
            : withoutSavedDiscount.discounts(orderItem, order),
      },
      {
        // Using with_saved_discount to have a different id
        // in column but it's not related to its content
        id: "with_saved_discount",
        label: "Subotal con descuento",
        align: "center",
        renderer: (orderItem) => {
          if (!orderItem.with_saved_discount) {
            return withoutSavedDiscount.subtotal(orderItem);
          }

          const crossedPrice = orderItem.product_itris_final_discount ? (
            <Paragraph font="sm" style={{ textDecoration: "line-through" }}>
              $
              {toFormattedPrice({
                number:
                  orderItem.product_itris_final_unit_price * orderItem.amount,
              })}
            </Paragraph>
          ) : null;
          const discountedPrice =
            orderItem.product_itris_final_unit_price *
            (1 - orderItem.product_itris_final_discount / 100);
          return (
            <Box>
              {crossedPrice}
              <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
                $
                {toFormattedPrice({
                  number: discountedPrice * orderItem.amount,
                })}
              </Title>
            </Box>
          );
        },
      },
      {
        id: "product_tax_multiplier",
        label: "Total con descuento",
        align: "center",
        renderer: (orderItem) => {
          if (!orderItem.with_saved_discount) {
            return withoutSavedDiscount.total(orderItem);
          }

          const discountPercentage =
            1 - orderItem.product_itris_final_discount / 100;
          return (
            <Box>
              <Paragraph font="sm">
                (IVA {orderItem.product_human_tax})
              </Paragraph>
              <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
                $
                {toFormattedPrice({
                  number:
                    orderItem.product_itris_final_unit_price *
                    discountPercentage *
                    orderItem.amount *
                    orderItem.product_tax_multiplier,
                })}
              </Title>
            </Box>
          );
        },
      },
    ],
    [navigate, order]
  );

  if (isLoadingUser || isLoadingOrder || !items)
    return (
      <PublicLayout>
        <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
          <Spinner />
        </Box>
      </PublicLayout>
    );
  if (!user) navigate(productsPath);

  return (
    <PublicLayout>
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }} minHeight={800}>
        <Title
          style={{
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          Pedido ID {orderId} - {order?.company.name}
        </Title>
        <ResponsiveTable data={items} columns={columns} />
        <Title font="sm" style={{ marginTop: 2 }}>
          Subtotal: ${toFormattedPrice({ number: subtotalPrice })}
        </Title>
        <Title font="md">
          Total: ${toFormattedPrice({ number: totalPrice })}
        </Title>
        <Title font="sm" style={{ marginTop: 1 }}>
          Envío: {order?.delivery ? "Sí" : "Retiro en sucursal"}
        </Title>
        <Title font="sm">
          {order?.delivery ? "Dirección" : "Sucursal"}: {order?.address}
        </Title>
      </Box>
    </PublicLayout>
  );
};
