import { Box, Button, colors } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

import { Link } from "components/Link";
import { Paper } from "components/Paper";

type Props = {
  title: string;
  image?: string;
  to: string;
  hideTitle?: boolean;
  style?: SxProps<Theme>;
};

const widthHeight = { xs: "126px", md: "180px" };

export const HomeBox = ({ title, image, to, hideTitle, style }: Props) => (
  <Paper
    style={{
      marginX: 1,
      marginY: 2,
      padding: 1,
      "&:hover": {
        color: colors.blue[500],
      },
      ...style,
    }}
  >
    <Link
      noMargin
      to={to}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        minHeight: "100%",
        overflow: "hidden",
        color: "inherit",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: widthHeight,
          width: widthHeight,
          maxWidth: widthHeight,
          minWidth: widthHeight,
        }}
      >
        <Box component="img" src={image} alt={title} sx={{ width: "100%" }} />
      </Box>
      {!hideTitle && (
        <Button
          sx={{
            maxWidth: widthHeight,
            minWidth: widthHeight,
            color: "inherit",
          }}
        >
          {title}
        </Button>
      )}
    </Link>
  </Paper>
);
