import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { ref, string, object } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormSelect, SingleOption } from "components/FormSelect";
import { FormTextField } from "components/FormTextField";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useCreationModal } from "hooks/useCreationModal";
import { useToast } from "hooks/useToast";
import { User } from "types/models";
import { API } from "utils/api";
import { COMPANY_ADMIN, ROLES, SELLER, isAdmin } from "utils/models/user";

interface Props {
  user?: User;
}

export const UserCreationModal = ({ user }: Props) => {
  const { CreationModal, handleClose: closeModal } = useCreationModal({
    title: "Crear usuario",
  });
  const { infoToast, errorToast } = useToast();
  const { data: companies } = useAllCompanies();

  const selectableRoles = useMemo(
    () =>
      ROLES.filter(
        (role) =>
          (role.id === SELLER && isAdmin(user)) || role.id === COMPANY_ADMIN
      ),
    [user]
  );

  const mappedCompanies = useMemo(
    () =>
      companies?.map((company) => ({
        id: company.id,
        label: company.name,
      })) || [],
    [companies]
  );

  const { mutate: createUser, isLoading } = useMutation({
    mutationFn: (form: {
      name: string;
      email: string;
      phone: string;
      password: string;
      passwordConfirmation: string;
      companyId: string | null;
      role: string;
    }) =>
      API.post("/users", {
        ...form,
        password_confirmation: form.passwordConfirmation,
        company_id: form.companyId,
      }),
    onSuccess: () => infoToast("Usuario creado con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido crear el usuario: ${errors}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
      company: null as unknown as SingleOption,
      role: null as unknown as SingleOption,
    },
    validationSchema: object({
      name: string().required("Requerido"),
      email: string().email("Email inválido").required("Requerido"),
      phone: string(),
      password: string()
        .min(8, "Contraseña inválida - Mínimo 8 caracteres")
        .required("Requerido"),
      passwordConfirmation: string()
        .oneOf([ref("password"), null], "Las contraseñas deben coincidir")
        .required("Requerido"),
      role: object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione el rol"),
      company: object().when("role", {
        is: (role?: SingleOption) => role?.id === COMPANY_ADMIN,
        then: object({
          id: string().required(),
          label: string().required(),
        }).required("Seleccione la empresa"),
        otherwise: object().notRequired().nullable(),
      }),
    }),
    onSubmit: (values) =>
      createUser(
        {
          ...values,
          companyId:
            values.role.id === COMPANY_ADMIN ? values.company.id : null,
          role: values.role.id,
        },
        {
          onSuccess: () => {
            formik.resetForm();
            closeModal();
            queryClient.invalidateQueries(["users"]);
            queryClient.invalidateQueries(["companies"]);
          },
        }
      ),
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    closeModal();
  }, [formik, closeModal]);

  return (
    <CreationModal>
      <Form formik={formik}>
        <FormTextField
          name="name"
          label="Nombre Completo"
          type="text"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="email"
          label="Email"
          placeholder="usuario@bluecar.com.ar"
          type="email"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="phone"
          label="Teléfono"
          type="text"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="password"
          label="Contraseña"
          type="password"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="passwordConfirmation"
          label="Repita Contraseña"
          type="password"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormSelect
          name="role"
          label="Rol"
          options={selectableRoles}
          multiple={false}
          isDisabled={isLoading}
          fullWidth
          isRequired
        />
        {formik.values.role?.id === COMPANY_ADMIN ? (
          <FormSelect
            name="company"
            label="Empresa"
            options={mappedCompanies}
            multiple={false}
            isDisabled={isLoading}
            fullWidth
            isRequired
          />
        ) : null}
        <Box display="flex" marginTop="10px">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Crear usuario
          </Button>
        </Box>
      </Form>
    </CreationModal>
  );
};
