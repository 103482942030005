import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { queryClient } from "App";
import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAllBrands } from "hooks/useAllBrands";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { CompanyDiscountCreationModal } from "modals/CompanyDiscountCreationModal";
import { CompanyDiscountEditModal } from "modals/CompanyDiscountEditModal";
import { CompanyDiscount } from "types/models";
import { API } from "utils/api";
import { BLUECAR_ROLES_IDS, isAdmin, isBluecarRole } from "utils/models/user";

const baseColumns: Column<CompanyDiscount>[] = [
  {
    id: "product_id",
    label: "Producto/Marca",
    renderer: (discount) =>
      discount.product_name ? (
        <Paragraph>Producto - {discount.product_name}</Paragraph>
      ) : (
        <Paragraph>Marca - {discount.brand_name}</Paragraph>
      ),
  },
  {
    id: "discount",
    label: "Descuento",
    renderer: (discount) => `${discount.discount}%`,
  },
];

const bluecarColumns: Column<CompanyDiscount>[] = [
  {
    id: "company_name",
    label: "Empresa",
    renderer: (discount) => discount.company_name,
  },
];

const allFilters = [
  { id: "product_name", label: "Producto" },
  {
    id: "brand_id",
    label: "Marca",
    options: [],
  },
  {
    id: "company_id",
    label: "Empresa",
    roles: BLUECAR_ROLES_IDS,
    options: [],
  },
];

export const BackofficeCompanyDiscounts = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Descuentos",
  });
  const { setCompanyDiscountEditModalProps, setConfirmationModalProps } =
    useContext(ModalContext);
  const { infoToast, errorToast } = useToast();
  const { user } = useAuthentication();
  const { data: companies } = useAllCompanies();
  const { data: brands } = useAllBrands({});

  const { mutateAsync: deleteCompanyDiscount } = useMutation({
    mutationFn: (companyDiscountId: string) =>
      API.delete(`/company_discounts/${companyDiscountId}`),
    onSuccess: () => {
      infoToast("Descuento eliminado con éxito.");
      queryClient.invalidateQueries(["company_discounts"]);
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido eliminar el descuento, intenta nuevamente"),
  });

  const actionColumn: Column<CompanyDiscount> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (companyDiscount) => (
        <>
          <Tooltip title="Editar descuento">
            <Button
              onClick={() =>
                setCompanyDiscountEditModalProps({
                  open: true,
                  companyDiscountId: companyDiscount.id,
                  companyName: companyDiscount.company_name,
                  companyDiscount: companyDiscount.discount,
                })
              }
            >
              <EditIcon titleAccess="Editar" />
            </Button>
          </Tooltip>
          <Tooltip title="Eliminar descuento">
            <Button
              onClick={() =>
                setConfirmationModalProps({
                  open: true,
                  title: "Eliminar descuento",
                  text: `¿Quieres eliminar el descuento que ${
                    companyDiscount.company_name
                  } tiene sobre ${
                    companyDiscount.product_name || companyDiscount.brand_name
                  }?`,
                  buttonText: "Eliminar",
                  buttonColor: "error",
                  onSubmit: () => deleteCompanyDiscount(companyDiscount.id),
                })
              }
            >
              <DeleteIcon titleAccess="Eliminar" />
            </Button>
          </Tooltip>
        </>
      ),
      align: "center",
    }),
    [
      setCompanyDiscountEditModalProps,
      setConfirmationModalProps,
      deleteCompanyDiscount,
    ]
  );

  const columns = useMemo(() => {
    if (user) {
      if (isAdmin(user)) {
        return [...baseColumns, ...bluecarColumns, actionColumn];
      } else if (isBluecarRole(user)) {
        return [...baseColumns, ...bluecarColumns];
      }
    }
    return baseColumns;
  }, [user, actionColumn]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters
      .filter((filter) => {
        if (filter.roles) {
          return filter.roles.includes(user?.role);
        }
        return true;
      })
      .map((filter) => {
        if (filter.id === "company_id") {
          return {
            ...filter,
            options:
              companies?.map((company) => ({
                id: company.id,
                label: company.name,
              })) || [],
          };
        } else if (filter.id === "brand_id") {
          return {
            ...filter,
            options:
              brands?.map((brand) => ({
                id: brand.id,
                label: brand.name,
              })) || [],
          };
        }
        return filter;
      });
  }, [companies, brands, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="company_discounts"
          path="company_discounts?active=true"
        />
      )}
      {!!user && isAdmin(user) && <CompanyDiscountCreationModal />}
      <CompanyDiscountEditModal />
    </BackofficeLayout>
  );
};
