import { Box } from "@mui/material";

import { ProductBoxSkeleton } from "components/screens/products/ProductBoxSkeleton";

export const ProductBoxListSkeleton = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {Array.from({ length: 12 }).map((_, i: number) => (
        <ProductBoxSkeleton key={i} />
      ))}
    </Box>
  );
};
