export const yesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

export const datetimeString = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${day}/${month}/${year} ${hours}:${minutes} hs`;
};

export const isDateNewerThanAWeekAgo = (dateString: string) => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  return inputDate > oneWeekAgo;
};

// dateString will be dd/MM/yyyy
export const parseDate = (dateString: string) => {
  const [day, month, year] = dateString.split("/");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};
