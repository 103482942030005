import { Paper, Skeleton } from "@mui/material";

export const HomeBoxSkeleton = () => (
  <Paper
    elevation={1}
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginX: 1,
      marginY: 2,
      padding: 1,
      width: { xs: "146px", md: "200px" },
      height: { xs: "184px", md: "236px" },
    }}
  >
    <Skeleton
      sx={{
        height: { xs: "96px", md: "192px" },
        width: { xs: "64px", md: "128px" },
      }}
    />
  </Paper>
);
