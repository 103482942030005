import { Box } from "@mui/material";

import LoadingLogo from "assets/car-preloader.svg";

export const Spinner = () => (
  <Box
    sx={{
      display: "flex",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      position: "absolute",
      width: `100%`,
      height: `100%`,
      top: 0,
      left: 0,
      zIndex: 99,
    }}
  >
    <Box
      sx={{
        position: "sticky",
        top: "50vh",
        left: "50%",
      }}
    >
      <Box
        src={LoadingLogo}
        alt="Bluecar Loading"
        component="img"
        sx={{
          width: 80,
          height: 80,
          position: "sticky",
          top: "50%",
          animation: "spin 1s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    </Box>
  </Box>
);
