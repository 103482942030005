import { useQuery } from "@tanstack/react-query";

import { Line } from "types/models";
import { API } from "utils/api";

interface Props {
  active?: boolean;
  brand?: string;
  group?: string;
}

export const useAllLines = (props?: Props) => {
  const activeString = props?.active ? "&active=true" : "";
  const brandString = props?.brand ? `&brand_id=${props?.brand}` : "";
  const groupString = props?.group ? `&group_id=${props?.group}` : "";

  const queryFn = (): Promise<Line[]> =>
    API.get(`lines?all=true${activeString}${brandString}${groupString}`).then(
      ({ data }) => data
    );

  return useQuery({
    queryKey: ["lines", "all", activeString, brandString, groupString],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });
};
