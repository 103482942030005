import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useCallback } from "react";
import { string, object, mixed } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { FormUploadButton } from "components/FormUploadButton";
import { useCreationModal } from "hooks/useCreationModal";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const BannerCreationModal = () => {
  const { CreationModal, handleClose: closeModal } = useCreationModal({
    title: "Crear banner",
  });
  const { infoToast, errorToast } = useToast();

  const { mutate: createBanner, isLoading } = useMutation({
    mutationFn: (form: { name: string; image: string }) =>
      API.post("/banners", { name: form.name, image_url: form.image }),
    onSuccess: () => infoToast("Banner creado con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido crear el banner: ${errors}`);
    },
  });

  const formik = useFormik({
    initialValues: { name: "", image: "" },
    validationSchema: object({
      name: string().required("Requerido"),
      image: mixed().required("Requerido"),
    }),
    onSubmit: (values) =>
      createBanner(values, {
        onSuccess: () => {
          formik.resetForm();
          closeModal();
          queryClient.invalidateQueries(["banners"]);
        },
      }),
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    closeModal();
  }, [formik, closeModal]);

  return (
    <CreationModal>
      <Form formik={formik}>
        <FormTextField
          name="name"
          label="Nombre"
          type="text"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormUploadButton
          presignedPath="banners/banner_image_url"
          text="Subir banner"
          name="image"
          required
        />
        <Box display="flex" marginTop="10px">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Crear Banner
          </Button>
        </Box>
      </Form>
    </CreationModal>
  );
};
