import { Company } from "types/models";

const NO_TAX_CATEGORY = "SNC";

export const taxMultiplierForProduct = ({
  company,
  product,
}: {
  company?: Company;
  product: { tax: number };
}) =>
  !company || company.billing_taxes_category == NO_TAX_CATEGORY
    ? { tax: 1, humanTax: "0%" }
    : { tax: product.tax / 100 + 1, humanTax: `${product.tax}%` };
