import { Box } from "@mui/material";

import { PromotionBoxSkeleton } from "components/screens/promotions/PromotionBoxSkeleton";

export const PromotionBoxListSkeleton = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
    >
      {Array.from({ length: 3 }).map((_, i: number) => (
        <PromotionBoxSkeleton key={i} />
      ))}
    </Box>
  );
};
