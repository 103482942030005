import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useContext, useCallback, useEffect } from "react";
import { object, number, string } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Modal } from "components/Modal";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const CompanyEditModal = () => {
  const { infoToast, errorToast } = useToast();
  const { companyEditModalProps, setCompanyEditModalProps } =
    useContext(ModalContext);

  const { mutateAsync: updateCompanyBaseDiscount, isLoading } = useMutation({
    mutationFn: (form: { discount?: number; email?: string }) =>
      API.put(`/companies/${companyEditModalProps.companyId}`, {
        discount: form.discount || 0,
        email: form.email,
      }),
    onSuccess: () => infoToast("Descuento base actualizado con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido actualizar el descuento base: ${errors}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      discount: undefined as unknown as number,
      email: undefined as unknown as string,
    },
    validationSchema: object({
      email: string().email("Email inválido"),
      discount: number()
        .min(0, "Ingrese un valor positivo")
        .max(100, "El descuento no puede ser mayor al 100%"),
    }),
    onSubmit: async (values) => {
      try {
        await updateCompanyBaseDiscount(values);
        formik.resetForm();
        setCompanyEditModalProps({
          open: false,
          companyId: undefined,
          companyName: undefined,
          companyDiscount: undefined,
          companyEmail: undefined,
        });
        queryClient.invalidateQueries(["companies"]);
      } catch (_) {}
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setCompanyEditModalProps({
      open: false,
      companyId: undefined,
      companyName: undefined,
      companyDiscount: undefined,
      companyEmail: undefined,
    });
  }, [formik, setCompanyEditModalProps]);

  useEffect(() => {
    formik.setValues({
      discount: companyEditModalProps.companyDiscount || 0,
      email: companyEditModalProps.companyEmail || "",
    });
  }, [companyEditModalProps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={companyEditModalProps.open}
      onClose={handleClose}
      ariaTitle={`Modal para editar ${companyEditModalProps.companyName}`}
    >
      <Title>Editar {companyEditModalProps.companyName}</Title>
      <Form formik={formik}>
        <FormTextField name="email" label="Email" type="email" fullWidth />
        <FormTextField
          name="discount"
          label="Descuento"
          type="number"
          fullWidth
        />
        <Box display="flex" marginTop={1}>
          <Button
            disabled={isLoading || formik.isSubmitting}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Actualizar
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
