import { Box } from "@mui/material";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  mdReverse?: boolean;
  imgAlt: string;
  imgSrc: string;
};

export const CompanyRow = ({ children, mdReverse, imgAlt, imgSrc }: Props) => (
  <Box
    sx={{
      flexDirection: {
        xs: "column-reverse",
        md: mdReverse ? "row" : "row-reverse",
      },
      paddingLeft: { md: 10 },
      paddingRight: { md: 10 },
      paddingTop: { xs: 2, md: 0 },
      paddingBottom: { xs: 2, md: 0 },
    }}
    display="flex"
    alignItems="center"
  >
    <Box
      component="img"
      alt={imgAlt}
      src={imgSrc}
      sx={{ width: { xs: "100%", md: "50%" } }}
    />
    <Box sx={{ width: { xs: "100%", md: "50%" } }}>{children}</Box>
  </Box>
);
