import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useContext, useCallback, useEffect } from "react";
import { object, number } from "yup";

import { queryClient } from "App";
import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Modal } from "components/Modal";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const CompanyDiscountEditModal = () => {
  const { infoToast, errorToast } = useToast();
  const { companyDiscountEditModalProps, setCompanyDiscountEditModalProps } =
    useContext(ModalContext);

  const { mutateAsync: updateCompanyDiscount, isLoading } = useMutation({
    mutationFn: (form: { discount?: number }) =>
      API.put(
        `/company_discounts/${companyDiscountEditModalProps.companyDiscountId}`,
        {
          discount: form.discount || 0,
        }
      ),
    onSuccess: () => infoToast("Descuento actualizado con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido actualizar el descuento: ${errors}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      discount: undefined as unknown as number,
    },
    validationSchema: object({
      discount: number()
        .min(0, "Ingrese un valor positivo")
        .max(100, "El descuento no puede ser mayor al 100%"),
    }),
    onSubmit: async (values) => {
      try {
        await updateCompanyDiscount(values);
        formik.resetForm();
        setCompanyDiscountEditModalProps({
          open: false,
          companyDiscountId: undefined,
          companyName: undefined,
          companyDiscount: undefined,
        });
        queryClient.invalidateQueries(["company_discounts"]);
      } catch (_) {}
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setCompanyDiscountEditModalProps({
      open: false,
      companyDiscountId: undefined,
      companyName: undefined,
      companyDiscount: undefined,
    });
  }, [formik, setCompanyDiscountEditModalProps]);

  useEffect(() => {
    formik.setValues({
      discount: companyDiscountEditModalProps.companyDiscount || 0,
    });
  }, [companyDiscountEditModalProps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={companyDiscountEditModalProps.open}
      onClose={handleClose}
      ariaTitle={`Modal para editar ${companyDiscountEditModalProps.companyName}`}
    >
      <Title>Editar {companyDiscountEditModalProps.companyName}</Title>
      <Form formik={formik}>
        <FormTextField
          name="discount"
          label="Descuento"
          type="number"
          fullWidth
        />
        <Box display="flex" marginTop={1}>
          <Button
            disabled={isLoading || formik.isSubmitting}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Actualizar
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
