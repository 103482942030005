import {
  FormMultipleAutocomplete,
  Props as FormMultipleAutocompleteProps,
  Option as ImportedMultipleOption,
  UpdatedValues as ImportedMultipleUpdatedValues,
} from "components/FormMultipleAutocomplete";
import {
  FormSingleAutocomplete,
  Props as FormSingleAutocompleteProps,
  Option as ImportedSingleOption,
  UpdatedValues as ImportedSingleUpdatedValues,
} from "components/FormSingleAutocomplete";

export type Props = { startsWithMatch?: boolean } & (
  | ({ multiple: true } & FormMultipleAutocompleteProps)
  | ({ multiple: false } & FormSingleAutocompleteProps)
);

export type MultipleOption = ImportedMultipleOption;
export type MultipleUpdatedValues = ImportedMultipleUpdatedValues;

export type SingleOption = ImportedSingleOption;
export type SingleUpdatedValues = ImportedSingleUpdatedValues;

export const FormSelect = ({ multiple, ...props }: Props) =>
  multiple ? (
    <FormMultipleAutocomplete {...(props as FormMultipleAutocompleteProps)} />
  ) : (
    <FormSingleAutocomplete {...(props as FormSingleAutocompleteProps)} />
  );
