import { Quote } from "types/models";

export const PENDING = "pending";
export const CANCELLED = "cancelled";
export const APPROVED = "approved";

export type Status = typeof PENDING | typeof CANCELLED | typeof APPROVED;

type StatusOption = {
  id: Status;
  label: string;
};

export const STATUS_OPTIONS: StatusOption[] = [
  { id: PENDING, label: "Pendiente" },
  { id: CANCELLED, label: "Cancelada" },
  { id: APPROVED, label: "Aprobada" },
];

export const isPending = (quote?: Quote) => quote?.status === PENDING;
export const isCancelled = (quote?: Quote) => quote?.status === CANCELLED;
export const isApproved = (quote?: Quote) => quote?.status === APPROVED;

export const humanizeQuote = (quote: Quote) => {
  if (isPending(quote)) return "Pendiente";
  if (isCancelled(quote)) return "Cancelada";
  if (isApproved(quote)) return "Aprobada";
  return "";
};
