import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Tooltip, colors } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "App";
import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { ordersPath } from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { Order } from "types/models";
import { API } from "utils/api";
import { datetimeString, isDateNewerThanAWeekAgo } from "utils/date";
import {
  humanizeOrder,
  isGenerated,
  isCreating,
  STATUS_OPTIONS,
  isErrored,
  isCompleted,
} from "utils/models/order";
import {
  COMPANY_ADMIN,
  BLUECAR_ROLES_IDS,
  isBluecarRole as isBluecarRoleUtils,
} from "utils/models/user";
import { toFormattedPrice } from "utils/numbers";
import { mapToSelect } from "utils/select";

const baseColumns: Column<Order>[] = [
  { id: "id", label: "Identificador" },
  {
    id: "created_at",
    label: "Fecha",
    renderer: (order) => datetimeString(order.created_at),
  },
  {
    id: "status",
    label: "Estado",
    renderer: (order) => (
      <Paragraph
        style={{
          fontWeight: isCompleted(order) ? 600 : 400,
          color: isCreating(order)
            ? colors.yellow[800]
            : isErrored(order)
            ? colors.red[700]
            : colors.green[700],
        }}
      >
        {humanizeOrder(order)}
        {order.external_result_message && isErrored(order)
          ? ` - ${order.external_result_message}`
          : ""}
        {order.external_id && (isGenerated(order) || isCompleted(order))
          ? ` - ID ITRIS ${order.external_id}`
          : ""}
      </Paragraph>
    ),
  },
  {
    id: "order_items",
    label: "Total",
    renderer: (order) =>
      "$" +
      toFormattedPrice({
        number: order?.order_items.reduce((total, item) => {
          const discountPercentage =
            1 - item.product_itris_final_discount / 100;
          return (
            total +
            item.amount *
              item.product_itris_final_unit_price *
              item.product_tax_multiplier *
              discountPercentage
          );
        }, 0),
      }),
  },
  {
    id: "order_items",
    label: "Items",
    renderer: (order) =>
      order.order_items
        ?.map((item) => `${item.product_name} x${item.amount}`)
        .join(", "),
  },
  {
    id: "address",
    label: "Dirección",
    minWidth: 200,
    renderer: (order) =>
      order.delivery
        ? order.address
        : `Retiro en sucursal ${order.address ? `(${order.address})` : ""}`,
  },
];

const companyColumn: Column<Order> = {
  id: "company",
  label: "Empresa",
  minWidth: 128,
  renderer: (order) => order.company?.name,
};

const allFilters = [
  { id: "id", label: "Identificador" },
  {
    id: "status",
    label: "Estado",
    options: STATUS_OPTIONS,
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  },
  {
    id: "company_id",
    label: "Empresa",
    roles: BLUECAR_ROLES_IDS,
    options: [],
  },
];

export const BackofficeOrders = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Pedidos",
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  });
  const { setConfirmationModalProps } = useContext(ModalContext);
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const { infoToast, errorToast } = useToast();
  const { data: companies } = useAllCompanies();

  const { mutateAsync: recreateItrisOrder } = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) =>
      API.post(`/orders/${orderId}/itris_recreate`),
    onSuccess: () => {
      infoToast("Orden enviada a Itris nuevamente.");
      queryClient.invalidateQueries(["orders"]);
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido reenviar la orden, intenta nuevamente"),
  });

  const isBluecarRole = useMemo(
    () => !!user && isBluecarRoleUtils(user),
    [user]
  );

  const actionColumn: Column<Order> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (order) => (
        <Box display="flex" justifyContent="center" gap="5px">
          {isErrored(order) &&
            isBluecarRole &&
            isDateNewerThanAWeekAgo(order.created_at) && (
              <Tooltip title="Reenviar a Itris">
                <Button
                  onClick={() =>
                    setConfirmationModalProps({
                      open: true,
                      title: "Reenviar orden a Itris",
                      text: "¿Quieres reintentar la creación de esta orden en Itris?",
                      buttonText: "Reenviar",
                      onSubmit: () => recreateItrisOrder({ orderId: order.id }),
                    })
                  }
                >
                  <RefreshIcon titleAccess="Reenviar" />
                </Button>
              </Tooltip>
            )}
          <Tooltip title="Ver pedido">
            <Button onClick={() => navigate(`${ordersPath}/${order.id}`)}>
              <VisibilityIcon titleAccess="Ver" />
            </Button>
          </Tooltip>
        </Box>
      ),
      align: "center",
    }),
    [isBluecarRole, navigate, recreateItrisOrder, setConfirmationModalProps]
  );

  const columns = useMemo(
    () =>
      isBluecarRole
        ? [...baseColumns, companyColumn, actionColumn]
        : [...baseColumns, actionColumn],
    [isBluecarRole, actionColumn]
  );

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters
      .filter((filter) => {
        if (filter.roles) {
          return filter.roles.includes(user?.role);
        }
        return true;
      })
      .map((filter) => {
        if (filter.id === "company_id") {
          return { ...filter, options: mapToSelect({ elements: companies }) };
        }
        return filter;
      });
  }, [companies, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="orders"
          path="orders"
        />
      )}
    </BackofficeLayout>
  );
};
