import { useQuery } from "@tanstack/react-query";

import { useAuthentication } from "hooks/useAuthentication";
import { Company } from "types/models";
import { API } from "utils/api";
import { isBluecarRole } from "utils/models/user";

export const useAllCompanies = () => {
  const { user } = useAuthentication();

  const queryFn = (): Promise<Company[]> =>
    API.get("companies?all=true").then(({ data }) => data);

  return useQuery({
    queryKey: ["companies", "all", user?.id],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    enabled: !!user && isBluecarRole(user),
  });
};
