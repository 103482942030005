import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useContext, useCallback } from "react";
import { string, object } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Modal } from "components/Modal";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const RecoverPasswordModal = () => {
  const { infoToast, errorToast } = useToast();
  const mutationFn = (form: { email: string }): Promise<void> =>
    API.post("users/generate_recover_password_token", form).then(
      ({ data }) => data
    );
  const { mutateAsync: recoverPassword } = useMutation({ mutationFn });

  const {
    isRecoverPasswordModalOpen,
    setIsRecoverPasswordModalOpen,
    setIsAuthenticationModalOpen,
  } = useContext(ModalContext);

  const handleAuthentication = useCallback(() => {
    setIsRecoverPasswordModalOpen(false);
    setIsAuthenticationModalOpen(true);
  }, [setIsAuthenticationModalOpen, setIsRecoverPasswordModalOpen]);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: object({
      email: string().email("Email inválido").required("Requerido"),
    }),
    onSubmit: async (values) => {
      try {
        await recoverPassword(values);
        formik.resetForm();
        setIsRecoverPasswordModalOpen(false);
        infoToast(
          "Se ha enviado un email para que puedas reiniciar tu contraseña."
        );
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setIsRecoverPasswordModalOpen(false);
  }, [formik, setIsRecoverPasswordModalOpen]);

  return (
    <Modal
      open={isRecoverPasswordModalOpen}
      onClose={handleClose}
      ariaTitle="Modal para recuperar contraseña"
    >
      <Title>Recuperar contraseña</Title>
      <Form formik={formik}>
        <FormTextField
          name="email"
          label="Email"
          placeholder="usuario@bluecar.com.ar"
          type="email"
          isRequired
          fullWidth
        />
        <Paragraph
          onClick={handleAuthentication}
          style={{ marginBottom: 1, marginRight: "auto" }}
        >
          Iniciar sesión
        </Paragraph>
        <Box display="flex">
          <Button
            disabled={formik.isSubmitting}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Recuperar contraseña
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
