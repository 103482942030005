import { useQuery } from "@tanstack/react-query";

import { Product } from "types/models";
import { API } from "utils/api";

export const useAllProducts = () => {
  const queryFn = (): Promise<Product[]> =>
    API.get("products?all=true&active=true").then(({ data }) => data);

  return useQuery({
    queryKey: ["products", "all"],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });
};
