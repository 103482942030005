import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Tooltip } from "@mui/material";
import { useContext, useMemo } from "react";

import { SortSelector } from "components/screens/backoffice-companies/SortSelector";
import { Sync } from "components/Sync";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { CompanyEditModal } from "modals/CompanyEditModal";
import { Company } from "types/models";
import { BLUECAR_ROLES_IDS, isBluecarRole } from "utils/models/user";

const baseColumns: Column<Company>[] = [
  { id: "name", label: "Nombre" },
  { id: "cuit", label: "CUIT" },
  { id: "billing_taxes_category", label: "Cat. IVA" },
  { id: "email", label: "Email" },
  {
    id: "discount",
    label: "Dto. base",
    align: "center",
    renderer: (company) => `${company.discount}%`,
  },
  {
    id: "active",
    label: "Activo",
    renderer: (company) => (Boolean(company.active) ? "Sí" : "No"),
  },
  // {
  //   id: "addresses",
  //   label: "Direcciones",
  //   renderer: (company) => (
  //     <>
  //       {company.addresses.map((address) => (
  //         <Paragraph key={address.id}>{address.label}</Paragraph>
  //       ))}
  //     </>
  //   ),
  // },
];

const filters = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activa",
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeCompanies = () => {
  const { user } = useAuthentication();
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Empresas",
    roles: BLUECAR_ROLES_IDS,
  });
  const { setCompanyEditModalProps } = useContext(ModalContext);
  const { SortSelector: SortSelectorComponent, sorting } = SortSelector({});

  const actionColumn: Column<Company> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (company) => (
        <Tooltip title="Editar email o descuento">
          <Button
            onClick={() =>
              setCompanyEditModalProps({
                open: true,
                companyId: company.id,
                companyName: company.name,
                companyDiscount: company.discount,
                companyEmail: company.email,
              })
            }
          >
            <EditIcon titleAccess="Editar" />
          </Button>
        </Tooltip>
      ),
      align: "center",
    }),
    [setCompanyEditModalProps]
  );

  const filtersRightNode = useMemo(
    () => (
      <Box alignItems="center" display="flex" gap="5px">
        <Sync kind="companies" />
        {SortSelectorComponent}
      </Box>
    ),
    [SortSelectorComponent]
  );

  const columns = useMemo(
    () => (isBluecarRole(user) ? [...baseColumns, actionColumn] : baseColumns),
    [actionColumn, user]
  );

  return (
    <BackofficeLayout>
      <Table
        columns={columns}
        filters={filters}
        filtersRightNode={filtersRightNode}
        queryKey={`companies?sort_by=${sorting?.id}`}
        path={`companies?sort_by=${sorting?.id}`}
      />
      <CompanyEditModal />
    </BackofficeLayout>
  );
};
