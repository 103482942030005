import { useMediaQuery, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useFormik } from "formik";
import { useCallback, useContext, useMemo } from "react";
import { object, string } from "yup";

import { Form } from "components/Form";
import {
  FormSelect,
  SingleUpdatedValues,
  SingleOption,
} from "components/FormSelect";
import { ModalContext } from "contexts/ModalProvider";
import { useAllCompanies } from "hooks/useAllCompanies";
import { mapToSelect } from "utils/select";

type Props = {
  style?: SxProps<Theme>;
  label?: string;
};

export const CompanySelector = ({ style, label = "Precios para" }: Props) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { company, setCompany } = useContext(ModalContext);

  const { data: companies, isLoading, isError } = useAllCompanies();
  const mappedCompanies = useMemo(
    () => mapToSelect({ elements: companies }),
    [companies]
  );

  const formik = useFormik({
    initialValues: {
      company: mappedCompanies.find(
        (mappedCompany) => mappedCompany.id === company?.id
      ) as SingleOption,
    },
    validationSchema: object({
      company: object({
        id: string().required(),
        label: string().required(),
      }).required("Seleccione la empresa"),
    }),
    onSubmit: () => {},
  });

  const handleChange = useCallback(
    (updatedValue: SingleUpdatedValues) => {
      const selectedCompany = companies?.find(
        (c) => c.id === updatedValue.value?.id
      );
      setCompany({
        id: updatedValue.value?.id || "",
        label: updatedValue.value?.label || "",
        baseDiscount: selectedCompany?.discount || 0,
        addresses: selectedCompany?.addresses || [],
      });
    },
    [companies, setCompany]
  );

  if (!companies) return null;

  return (
    <Form formik={formik} noMargin>
      <FormSelect
        name="company"
        label={label}
        fullWidth={xsDevice}
        isDisabled={isLoading || isError}
        onChange={handleChange}
        options={mappedCompanies}
        style={{ marginY: -1, ...style }}
        multiple={false}
        size="small"
      />
    </Form>
  );
};
