import {
  Divider,
  List,
  ListItem,
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment, ReactNode } from "react";

import { Paragraph } from "components/Paragraph";

export type Column<Data> = {
  id: keyof Data;
  label: string;
  minWidth?: number;
  align?: "left" | "right" | "center" | "justify";
  renderer?: (row: Data) => ReactNode;
};

type Props<Data> = {
  columns: readonly Column<Data>[];
  maxHeight?: number | string;
  data: Data[];
};

function DesktopTable<Data>({ columns, maxHeight, data }: Props<Data>) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: maxHeight || 640 }}>
      <MaterialTable stickyHeader aria-label="Tabla">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.label}
                align={column.align}
                sx={{
                  minWidth: column.minWidth,
                  backgroundColor: "rgb(245, 245, 245)",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length ? (
            data?.map((row, i) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                {columns.map((column) => (
                  <TableCell
                    key={`${i}-${column.label}`}
                    align={column.align}
                    sx={{ paddingY: "12px" }}
                  >
                    {
                      ((column.renderer
                        ? column.renderer(row)
                        : row[column.id]) || "-") as ReactNode
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow hover role="checkbox" tabIndex={-1}>
              {columns.map((column, index) => (
                <TableCell key={column.label} align={column.align}>
                  {index === 0 ? "Sin resultados." : null}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>
    </TableContainer>
  );
}

function MobileTable<Data>({ columns, data }: Props<Data>) {
  return (
    <List>
      {data.length === 0 && <Paragraph>Sin resultados.</Paragraph>}
      {data.map((row, i) => (
        <Fragment key={i}>
          <List>
            {columns.map((column) => (
              <ListItem
                key={`${i}-${String(column.id)}-${String(column.label)}`}
              >
                <Paragraph
                  style={{
                    marginRight: 1,
                    minWidth: "64px",
                    fontWeight: "bold",
                  }}
                >
                  {column.label}:
                </Paragraph>
                <Paragraph style={{ lineBreak: "anywhere", overflow: "auto" }}>
                  {
                    ((column.renderer
                      ? column.renderer(row)
                      : row[column.id]) || "-") as ReactNode
                  }
                </Paragraph>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}

export function ResponsiveTable<Data>({
  columns,
  maxHeight,
  data,
}: Props<Data>) {
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.down("md"));

  if (smDevice) {
    return <MobileTable columns={columns} data={data} />;
  }

  return <DesktopTable columns={columns} maxHeight={maxHeight} data={data} />;
}
