import ClearIcon from "@mui/icons-material/Clear";
import { TextField as MaterialTextField, IconButton } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useField } from "formik";
import { debounce } from "lodash";
import {
  ChangeEvent,
  KeyboardEvent,
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";

export type Props = {
  type?: "text" | "password" | "email" | "number" | "date";
  name: string;
  label: string;
  fullWidth?: boolean;
  focus?: boolean;
  autoFocus?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
  minRows?: number | string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  style?: SxProps<Theme>;
  hideAdornment?: boolean;
  debounceTime?: number;
  size?: "small" | "medium";
} & (
  | { type?: "text" | "password" | "email" | "date"; min?: never }
  | { type: "number"; min?: number }
);

export const FormTextField = ({
  type = "text",
  min,
  name,
  label,
  placeholder,
  fullWidth,
  focus,
  autoFocus,
  isRequired,
  isDisabled,
  onChange,
  onBlur,
  style,
  multiline,
  minRows,
  hideAdornment = false,
  debounceTime = 800,
  size = "medium",
}: Props) => {
  const [field, fieldMeta] = useField<string>(name);
  const error = fieldMeta.touched && fieldMeta.error;
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedOnChange = useMemo(
    () =>
      onChange
        ? debounce(
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChange(e),
            debounceTime
          )
        : () => {},
    [debounceTime, onChange]
  );

  if (isFocused && document.activeElement !== inputRef.current) {
    setTimeout(() => inputRef.current?.focus());
  }

  const handleClear = useCallback(() => {
    const event = { target: { name, value: "" } };
    debouncedOnChange(event as ChangeEvent<HTMLInputElement>);
    field.onChange(event);
  }, [debouncedOnChange, field, name]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event?.key === "Enter" && !multiline) {
        inputRef.current?.blur();
      }
    },
    [multiline]
  );

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
    }
  }, [focus]);

  return (
    <MaterialTextField
      fullWidth={fullWidth}
      type={type}
      name={name}
      placeholder={placeholder}
      required={isRequired}
      disabled={isDisabled}
      autoFocus={autoFocus}
      error={!!error}
      label={label}
      helperText={error}
      value={field.value || ""}
      multiline={multiline}
      minRows={minRows}
      size={size}
      variant="outlined"
      inputRef={inputRef}
      InputLabelProps={type === "date" ? { shrink: true } : {}}
      InputProps={{
        inputProps: { min },
        endAdornment: hideAdornment ? null : (
          <IconButton
            sx={{ visibility: field.value ? "visible" : "hidden" }}
            onClick={handleClear}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      onClick={(e) => {
        e.stopPropagation();
        setIsFocused(true);
      }}
      onChange={(e) => {
        field.onChange(e);
        debouncedOnChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
        setIsFocused(false);
        if (onBlur) onBlur();
      }}
      onKeyDown={handleKeyDown}
      sx={{ marginBottom: 1, marginTop: 1, ...style }}
    />
  );
};
