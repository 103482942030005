import { Box, Container } from "@mui/material";
import { useCallback, useContext } from "react";

import logo from "assets/logo.png";
import { Link } from "components/Link";
import { Props as ParagraphProps, Paragraph } from "components/Paragraph";
import {
  companyPath,
  contactPath,
  productsPath,
  promotionsPath,
  rrhhPath,
} from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import {
  BLUECAR_ADDRESS_HUMAN,
  BLUECAR_EMAIL,
  BLUECAR_GMAPS,
  BLUECAR_PHONE,
  BLUECAR_PHONE_HUMAN,
} from "utils/contact";

export const Footer = () => {
  const { setIsShoppingCartModalOpen } = useContext(ModalContext);

  const openShoppingCartModal = useCallback(
    () => setIsShoppingCartModalOpen(true),
    [setIsShoppingCartModalOpen]
  );

  const FooterParagraph = ({ children, onClick }: ParagraphProps) => (
    <Paragraph
      onClick={onClick}
      style={{
        "&:hover": { textDecoration: "underline" },
        marginBottom: 1,
        color: "inherit",
      }}
    >
      {children}
    </Paragraph>
  );

  const FooterLink = ({
    to,
    external,
    onClick,
    children,
  }: { to: string; external?: boolean } & ParagraphProps) => (
    <Link noMargin to={to} external={external}>
      <FooterParagraph onClick={onClick}>{children}</FooterParagraph>
    </Link>
  );

  return (
    <Box
      sx={{
        backgroundColor: "rgba(23, 152, 209, .1)",
        paddingBottom: 8,
        paddingTop: 4,
      }}
    >
      <Container
        sx={{
          display: { xs: "block", md: "flex" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box flexGrow="1">
          <Box component="img" sx={{ width: 128 }} alt="Bluecar" src={logo} />
          <Paragraph style={{ marginBottom: 1 }}>
            Buenos Aires - Argentina
          </Paragraph>
          <Paragraph style={{ marginBottom: 1, marginTop: 1 }}>
            Tel: <a href={`tel:${BLUECAR_PHONE}`}>{BLUECAR_PHONE_HUMAN}</a>
          </Paragraph>
          <Paragraph style={{ marginBottom: 1, marginTop: 1 }}>
            Email: <a href={`mailto:${BLUECAR_EMAIL}`}>{BLUECAR_EMAIL}</a>
          </Paragraph>
          <Paragraph>
            Dirección:{" "}
            <a href={BLUECAR_GMAPS} target="blank">
              {BLUECAR_ADDRESS_HUMAN}
            </a>
          </Paragraph>
        </Box>
        <Box flexGrow="1" sx={{ marginTop: { xs: 5, md: 0 } }}>
          <Paragraph
            style={{ fontWeight: "bold", marginBottom: { xs: 2, md: 4 } }}
          >
            Compañía
          </Paragraph>
          <FooterLink to={productsPath}>Productos</FooterLink>
          <FooterLink to={promotionsPath}>Promociones</FooterLink>
        </Box>
        <Box flexGrow="1" sx={{ marginTop: { xs: 5, md: 0 } }}>
          <Paragraph
            style={{ fontWeight: "bold", marginBottom: { xs: 2, md: 4 } }}
          >
            Mi cuenta
          </Paragraph>
          <FooterParagraph onClick={openShoppingCartModal}>
            Carrito de compra
          </FooterParagraph>
        </Box>
        <Box flexGrow="1" sx={{ marginTop: { xs: 5, md: 0 } }}>
          <Paragraph
            style={{ fontWeight: "bold", marginBottom: { xs: 2, md: 4 } }}
          >
            Información
          </Paragraph>
          <FooterLink to={companyPath}>Empresa</FooterLink>
          <FooterLink to={contactPath} external>
            Contacto
          </FooterLink>
          <FooterLink to={rrhhPath}>Trabaja con nosotros</FooterLink>
        </Box>
      </Container>
    </Box>
  );
};
