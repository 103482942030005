import { Tooltip, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useMemo, useCallback } from "react";

import { ChildrenProps } from "types/props";

export type Props = ChildrenProps & {
  isDisabled?: boolean;
  style?: SxProps<Theme>;
  onClick?: () => void;
  font?: "sm" | "md" | "l" | "xl";
  ellipsis?: boolean;
  uppercase?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
  disableSelect?: boolean;
};

export const Paragraph = ({
  children,
  isDisabled,
  style,
  onClick,
  font = "md",
  ellipsis,
  uppercase,
  tooltip,
  tooltipText,
  disableSelect = false,
}: Props) => {
  const uppercaseStyle: SxProps<Theme> = useMemo(
    () => (uppercase ? { textTransform: "uppercase" } : {}),
    [uppercase]
  );

  const disableSelectStyle: SxProps<Theme> = useMemo(
    () => ({ "user-select": disableSelect ? "none" : "auto" }),
    [disableSelect]
  );

  const ellipsisStyle: SxProps<Theme> = useMemo(
    () =>
      ellipsis
        ? {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }
        : {},
    [ellipsis]
  );

  const fontWeight = font == "xl" ? 700 : 400;
  const fontSize = useMemo(
    () =>
      font === "sm"
        ? { xs: 10, md: 12 }
        : font === "md"
        ? { xs: 12, md: 14 }
        : { xs: 14, md: 16 },
    [font]
  );

  const sx = useMemo(
    () =>
      onClick
        ? {
            "&:hover": { textDecoration: isDisabled ? "none" : "underline" },
            cursor: isDisabled ? "default" : "pointer",
            color: isDisabled ? "secondary.main" : "primary.main",
            fontWeight,
            fontSize,
            ...disableSelectStyle,
            ...uppercaseStyle,
            ...ellipsisStyle,
            ...style,
          }
        : {
            fontWeight,
            fontSize,
            ...disableSelectStyle,
            ...uppercaseStyle,
            ...ellipsisStyle,
            ...style,
          },
    [
      onClick,
      isDisabled,
      fontWeight,
      fontSize,
      disableSelectStyle,
      uppercaseStyle,
      ellipsisStyle,
      style,
    ]
  );

  const handleClick = useCallback(() => {
    if (isDisabled) return;
    onClick?.();
  }, [isDisabled, onClick]);

  return (
    <Tooltip title={tooltip ? tooltipText ?? children : ""} arrow>
      <Typography onClick={handleClick} sx={sx} variant="body2">
        {children}
      </Typography>
    </Tooltip>
  );
};
