import { useMemo } from "react";

import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { Line } from "types/models";
import { BLUECAR_ROLES_IDS, isBluecarRole } from "utils/models/user";

const baseColumns: Column<Line>[] = [{ id: "name", label: "Nombre" }];

const activeColumn: Column<Line> = {
  id: "active",
  label: "Activo",
  renderer: (group) => (Boolean(group.active) ? "Sí" : "No"),
};

const allFilters = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activo",
    roles: BLUECAR_ROLES_IDS,
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeLines = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Lineas",
    roles: BLUECAR_ROLES_IDS,
  });
  const { user } = useAuthentication();

  const columns = useMemo(() => {
    if (user && isBluecarRole(user)) {
      return [...baseColumns, activeColumn];
    }
    return baseColumns;
  }, [user]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters.filter((filter) =>
      filter.roles ? filter.roles.includes(user?.role) : true
    );
  }, [user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="lines"
          path="lines"
        />
      )}
    </BackofficeLayout>
  );
};
