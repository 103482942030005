import { ButtonTypeMap } from "@mui/material";
import { createContext, useState } from "react";

import { SingleOption } from "components/FormSelect";
import { Company } from "types/models";
import { ChildrenProps } from "types/props";

type ConfirmationModalProps = {
  open: boolean;
  title?: string;
  text?: string;
  buttonText?: string;
  buttonColor?: ButtonTypeMap["props"]["color"];
  onSubmit?: () => Promise<unknown>;
};

type EmailModalProps = {
  open: boolean;
  title?: string;
  successTootlip?: string;
  buttonText?: string;
  onSubmit?: ({ email }: { email: string }) => Promise<unknown>;
};

type QuoteModalProps = {
  open: boolean;
  quoteId?: string;
};

type EditCompanyModalProps = {
  open: boolean;
  companyId?: string;
  companyName?: string;
  companyDiscount?: number;
  companyEmail?: string;
};

type EditCompanyDiscountModalProps = {
  open: boolean;
  companyDiscountId?: string;
  companyName?: string;
  companyDiscount?: number;
};

type EditBrandModalProps = {
  open: boolean;
  brandId?: string;
  brandName?: string;
  showHome?: boolean;
  imageUrl?: string;
};

type DuplicatePromotionModalProps = {
  open: boolean;
  brand?: SingleOption;
  product?: SingleOption;
  discount?: string;
  minimum?: string;
  image?: string;
};

type SelectedCompany = {
  id: string;
  label: string;
  baseDiscount: number;
  addresses: Company["addresses"];
};

type ModalContextType = {
  company: SelectedCompany | undefined;
  setCompany: (company: SelectedCompany | undefined) => void;
  isShoppingCartModalOpen: boolean;
  setIsShoppingCartModalOpen: (isShoppingCartModalOpen: boolean) => void;
  isAuthenticationModalOpen: boolean;
  setIsAuthenticationModalOpen: (isAuthenticationModalOpen: boolean) => void;
  isRecoverPasswordModalOpen: boolean;
  setIsRecoverPasswordModalOpen: (isRecoverPasswordModalOpen: boolean) => void;
  emailModalProps: EmailModalProps;
  setEmailModalProps: (emailModalProps: EmailModalProps) => void;
  confirmationModalProps: ConfirmationModalProps;
  setConfirmationModalProps: (
    confirmationModalProps: ConfirmationModalProps
  ) => void;
  quoteModalProps: QuoteModalProps;
  setQuoteModalProps: (quoteModalProps: QuoteModalProps) => void;
  companyEditModalProps: EditCompanyModalProps;
  setCompanyEditModalProps: (
    editCompanyModalProps: EditCompanyModalProps
  ) => void;
  companyDiscountEditModalProps: EditCompanyDiscountModalProps;
  setCompanyDiscountEditModalProps: (
    editCompanyDiscountModalProps: EditCompanyDiscountModalProps
  ) => void;
  brandEditModalProps: EditBrandModalProps;
  setBrandEditModalProps: (editBrandModalProps: EditBrandModalProps) => void;
  duplicatePromotionModalProps: DuplicatePromotionModalProps;
  setDuplicatePromotionModalProps: (
    duplicatePromotionModalProps: DuplicatePromotionModalProps
  ) => void;
};

export const ModalContext = createContext<ModalContextType>({
  company: undefined,
  setCompany: () => {},
  isShoppingCartModalOpen: false,
  setIsShoppingCartModalOpen: () => {},
  isAuthenticationModalOpen: false,
  setIsAuthenticationModalOpen: () => {},
  isRecoverPasswordModalOpen: false,
  setIsRecoverPasswordModalOpen: () => {},
  emailModalProps: { open: false },
  setEmailModalProps: () => {},
  confirmationModalProps: { open: false },
  setConfirmationModalProps: () => {},
  quoteModalProps: { open: false },
  setQuoteModalProps: () => {},
  companyEditModalProps: { open: false },
  setCompanyDiscountEditModalProps: () => {},
  companyDiscountEditModalProps: { open: false },
  setCompanyEditModalProps: () => {},
  brandEditModalProps: { open: false },
  setBrandEditModalProps: () => {},
  duplicatePromotionModalProps: { open: false },
  setDuplicatePromotionModalProps: () => {},
});

export const ModalProvider = ({ children }: ChildrenProps) => {
  const [company, setCompany] = useState<ModalContextType["company"]>();
  const [isShoppingCartModalOpen, setIsShoppingCartModalOpen] = useState(false);
  const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] =
    useState(false);
  const [isRecoverPasswordModalOpen, setIsRecoverPasswordModalOpen] =
    useState(false);
  const [emailModalProps, setEmailModalProps] = useState({ open: false });
  const [confirmationModalProps, setConfirmationModalProps] = useState({
    open: false,
  });
  const [quoteModalProps, setQuoteModalProps] = useState({ open: false });
  const [companyEditModalProps, setCompanyEditModalProps] = useState({
    open: false,
  });
  const [companyDiscountEditModalProps, setCompanyDiscountEditModalProps] =
    useState({
      open: false,
    });
  const [brandEditModalProps, setBrandEditModalProps] = useState({
    open: false,
  });
  const [duplicatePromotionModalProps, setDuplicatePromotionModalProps] =
    useState({ open: false });

  return (
    <ModalContext.Provider
      value={{
        company,
        setCompany,
        isShoppingCartModalOpen,
        setIsShoppingCartModalOpen,
        isAuthenticationModalOpen,
        setIsAuthenticationModalOpen,
        isRecoverPasswordModalOpen,
        setIsRecoverPasswordModalOpen,
        emailModalProps,
        setEmailModalProps,
        confirmationModalProps,
        setConfirmationModalProps,
        quoteModalProps,
        setQuoteModalProps,
        companyEditModalProps,
        setCompanyEditModalProps,
        companyDiscountEditModalProps,
        setCompanyDiscountEditModalProps,
        brandEditModalProps,
        setBrandEditModalProps,
        duplicatePromotionModalProps,
        setDuplicatePromotionModalProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
