import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { string, number, object, mixed, date, ref } from "yup";

import { Form } from "components/Form";
import { FormSelect, SingleOption } from "components/FormSelect";
import { FormTextField } from "components/FormTextField";
import { FormUploadButton } from "components/FormUploadButton";
import { ModalContext } from "contexts/ModalProvider";
import { useAllBrands } from "hooks/useAllBrands";
import { useAllProducts } from "hooks/useAllProducts";
import { useCreationModal } from "hooks/useCreationModal";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";
import { yesterday } from "utils/date";
import { invalidateQueries } from "utils/models/promotions";
import { mapToSelect } from "utils/select";

export const PromotionCreationModal = () => {
  const [isProductDiscount, setIsProductDiscount] = useState<boolean>(false);
  const {
    CreationModal,
    handleClose: closeModal,
    handleOpen: openModal,
  } = useCreationModal({
    title: "Crear promoción",
  });
  const { infoToast, errorToast } = useToast();
  const { data: brands } = useAllBrands({ active: true });
  const { data: products } = useAllProducts();
  const { duplicatePromotionModalProps, setDuplicatePromotionModalProps } =
    useContext(ModalContext);

  const mappedBrands = useMemo(
    () => mapToSelect({ elements: brands }),
    [brands]
  );
  const mappedProducts = useMemo(
    () =>
      mapToSelect({
        elements: products,
        elementLabelFn: (product) => `${product.external_id} - ${product.name}`,
      }),
    [products]
  );

  const { mutate: createPromotion, isLoading } = useMutation({
    mutationFn: (form: {
      productId: string;
      brandId: string;
      discount: string;
      minimum: string;
      image: string;
      from: string;
      to: string;
    }) =>
      API.post("/promotions", {
        discount: form.discount,
        product_id: isProductDiscount ? form.productId : null,
        brand_id: !isProductDiscount ? form.brandId : null,
        minimum_amount: form.minimum,
        image_url: form.image,
        start_date: form.from,
        end_date: form.to,
      }),
    onSuccess: () => infoToast("Promoción creada con éxito."),
    onError: ({ response }) => {
      const { errors } = response.data;
      errorToast(`No se ha podido crear la promoción: ${errors}`);
    },
  });

  const objectValidationSchema = useMemo(
    () =>
      object({
        id: string().required(),
        label: string().required(),
      }),
    []
  );

  const yesterdayDate = useMemo(() => yesterday(), []);

  const formik = useFormik({
    initialValues: {
      brand: null as unknown as SingleOption,
      product: null as unknown as SingleOption,
      discount: "",
      minimum: "",
      image: "",
      from: "",
      to: "",
    },
    validationSchema: object().shape(
      {
        brand: object({}).when("product", {
          is: (product?: SingleOption) => !isProductDiscount && !product?.id,
          then: objectValidationSchema.required("Seleccione una línea"),
          otherwise: object({}).nullable(),
        }),
        product: object({}).when("brand", {
          is: (brand?: SingleOption) => isProductDiscount && !brand?.id,
          then: objectValidationSchema.required("Seleccione un producto"),
          otherwise: object({}).nullable(),
        }),
        discount: number()
          .positive("Ingrese un valor positivo")
          .max(100, "El descuento no puede ser mayor al 100%")
          .required("El descuento es requerido"),
        minimum: number()
          .positive("Ingrese un valor positivo")
          .required("El descuento es requerido"),
        image: mixed(),
        from: date()
          .min(
            yesterdayDate,
            "La fecha de comienzo debe ser posterior al día de ayer"
          )
          .required("Ingresa la fecha  de comienzo de la promoción"),
        to: date()
          .min(
            ref("from"),
            "La fecha de finalización para la promoción debe ser posterior a la fecha de comienzo"
          )
          .required("Ingresa la fecha de finalización de la promoción"),
      },
      [["product", "brand"]]
    ),
    onSubmit: (values) =>
      createPromotion(
        {
          ...values,
          productId: values.product?.id,
          brandId: values.brand?.id,
        },
        {
          onSuccess: () => {
            formik.resetForm();
            closeModal();
            invalidateQueries();
            setDuplicatePromotionModalProps({ open: false });
          },
        }
      ),
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    closeModal();
    setDuplicatePromotionModalProps({ open: false });
  }, [formik, closeModal, setDuplicatePromotionModalProps]);

  const toggleIsProductDiscount = useCallback(
    () => setIsProductDiscount((value) => !value),
    []
  );

  useEffect(() => {
    if (duplicatePromotionModalProps.open) openModal();
    formik.setValues({
      brand:
        duplicatePromotionModalProps.brand ?? (null as unknown as SingleOption),
      product:
        duplicatePromotionModalProps.product ??
        (null as unknown as SingleOption),
      discount: duplicatePromotionModalProps.discount ?? "",
      minimum: duplicatePromotionModalProps.minimum ?? "",
      image: duplicatePromotionModalProps.image ?? "",
      from: "",
      to: "",
    });
    if (duplicatePromotionModalProps.product?.id) {
      setIsProductDiscount(true);
    } else {
      setIsProductDiscount(false);
    }
  }, [duplicatePromotionModalProps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CreationModal handleClose={handleClose}>
      <Form formik={formik}>
        <FormTextField
          name="discount"
          label="Descuento"
          type="number"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="minimum"
          label="Cantidad mínima"
          type="number"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="from"
          label="Desde"
          type="date"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormTextField
          name="to"
          label="Hasta"
          type="date"
          isDisabled={isLoading}
          isRequired
          fullWidth
        />
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isProductDiscount} />}
            label="Promoción por producto"
            onChange={toggleIsProductDiscount}
          />
        </FormGroup>
        {isProductDiscount ? (
          <FormSelect
            name="product"
            label="Producto"
            options={mappedProducts}
            multiple={false}
            isDisabled={isLoading}
            fullWidth
            isRequired
          />
        ) : (
          <FormSelect
            name="brand"
            label="Marca"
            options={mappedBrands}
            multiple={false}
            isDisabled={isLoading}
            fullWidth
            isRequired
          />
        )}
        <FormUploadButton
          presignedPath="promotions/promotion_image_url"
          text="Subir banner"
          name="image"
          required={false}
        />
        <Box display="flex" marginTop="10px">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Crear promoción
          </Button>
        </Box>
      </Form>
    </CreationModal>
  );
};
