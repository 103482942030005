import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { useCallback, useContext } from "react";

import { ModalContext } from "contexts/ModalProvider";
import { useShoppingCart } from "hooks/useShoppingCart";

export const CartMenu = () => {
  const { items } = useShoppingCart();
  const { setIsShoppingCartModalOpen } = useContext(ModalContext);

  const openShoppingCartModal = useCallback(
    () => setIsShoppingCartModalOpen(true),
    [setIsShoppingCartModalOpen]
  );

  return (
    <Tooltip title="Carrito">
      <IconButton onClick={openShoppingCartModal} size="large">
        <Badge badgeContent={items?.length || 0} color="primary">
          <ShoppingCartIcon sx={{ zIndex: 10 }} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
