import { colors, Paper as MaterialPaper } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import {
  forwardRef,
  useCallback,
  useState,
  useMemo,
  ForwardedRef,
} from "react";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  baseElevation?: number;
  style?: SxProps<Theme>;
  onClick?: () => void;
};

export const Paper = forwardRef(
  (
    { baseElevation = 0, children, onClick, style = {} }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const targetElevation = useMemo(
      () => (baseElevation == 0 ? 3 : 5),
      [baseElevation]
    );
    const [elevation, setElevation] = useState<number>(baseElevation);
    const onMouseOver = useCallback(
      () => setElevation(targetElevation),
      [targetElevation]
    );
    const onMouseOut = useCallback(
      () => setElevation(baseElevation),
      [baseElevation]
    );

    const handleClick = useCallback(() => onClick?.(), [onClick]);

    return (
      <MaterialPaper
        elevation={elevation}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        sx={{
          border:
            elevation !== baseElevation
              ? `2px solid ${colors.blue[600]}`
              : `2px solid ${baseElevation ? colors.grey[200] : "transparent"}`,
          ...style,
        }}
        onClick={handleClick}
        ref={ref}
      >
        {children}
      </MaterialPaper>
    );
  }
);
