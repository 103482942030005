import { createContext, useState } from "react";

import { ChildrenProps } from "types/props";

type SearchParamsObject = {
  [key: string]: string;
};

type FilterPaginatedsContextType = {
  searchParamsObject: SearchParamsObject;
  setSearchParamsObject: (searchParamsObject: SearchParamsObject) => void;
};

export const FilterPaginatedContext =
  createContext<FilterPaginatedsContextType>({
    searchParamsObject: {},
    setSearchParamsObject: () => {},
  });

export const FilterPaginatedProvider = ({ children }: ChildrenProps) => {
  const [searchParamsObject, setSearchParamsObject] =
    useState<SearchParamsObject>({});

  return (
    <FilterPaginatedContext.Provider
      value={{
        searchParamsObject,
        setSearchParamsObject,
      }}
    >
      {children}
    </FilterPaginatedContext.Provider>
  );
};
