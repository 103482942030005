import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { Box, Tooltip, colors, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

import { toFormattedPrice } from "../utils/numbers";

import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { useCompanyBaseDiscount } from "hooks/useCompanyBaseDiscount";
import { getDiscounts, getPrice } from "utils/models/product";

type Props = {
  amount?: number;
  price?: number;
  companyDiscount?: number;
  promotionDiscount?: number;
  promotionMinimumAmount?: number;
  showTitle?: boolean;
  hidePromotionInfo?: boolean;
  usePriceAsFinal?: boolean;
};

export const Price = ({
  amount,
  price = 0,
  companyDiscount,
  promotionDiscount,
  promotionMinimumAmount,
  showTitle,
  hidePromotionInfo,
  usePriceAsFinal = false,
}: Props) => {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const companyBaseDiscount = useCompanyBaseDiscount();

  const discountPrice = useMemo(
    () =>
      getPrice({
        amount,
        price,
        companyBaseDiscount,
        companyDiscount,
        promotionDiscount,
        promotionMinimumAmount,
      }),
    [
      amount,
      companyBaseDiscount,
      companyDiscount,
      price,
      promotionDiscount,
      promotionMinimumAmount,
    ]
  );

  const discounts = useMemo(
    () =>
      getDiscounts({
        amount,
        companyBaseDiscount,
        companyDiscount,
        promotionDiscount,
        promotionMinimumAmount,
      }),
    [
      amount,
      companyBaseDiscount,
      companyDiscount,
      promotionDiscount,
      promotionMinimumAmount,
    ]
  );

  if (!price) return null;

  if (usePriceAsFinal) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexDirection: showTitle ? "column" : { xs: "row", sm: "column" },
        }}
        marginRight={showTitle ? { xs: 2, md: 0 } : 0}
      >
        <Title font="sm">${toFormattedPrice({ number: price })}</Title>
        {amount && amount > 1 ? (
          <Paragraph style={{ marginX: { xs: 2, sm: 0 } }}>
            Total: $ {toFormattedPrice({ number: amount * price })}
          </Paragraph>
        ) : null}
      </Box>
    );
  }

  const hasDiscount = discountPrice && discountPrice !== price;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginRight={showTitle ? { xs: 2, md: 0 } : 0}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        {showTitle ? <Paragraph font="xl">Precio</Paragraph> : null}
        {hasDiscount ? (
          <>
            <Paragraph font="sm" style={{ textDecoration: "line-through" }}>
              ${toFormattedPrice({ number: price })}
            </Paragraph>
            <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
              ${toFormattedPrice({ number: discountPrice })}
            </Title>
          </>
        ) : (
          <Title font="sm">${toFormattedPrice({ number: price })}</Title>
        )}
        {discounts.promotionPossibleDicount && !hidePromotionInfo ? (
          <Tooltip
            title={`Si superas las ${discounts.promotionMinimumAmount} unidades, se aplicará un ${discounts.promotionPossibleDicount}%`.concat(
              discounts.companyBaseDiscount
                ? `, en lugar del ${discounts.companyBaseDiscount}% ${
                    discounts.companyDiscount
                      ? `+ ${discounts.companyDiscount}%`
                      : ""
                  } aplicado`
                : ""
            )}
            enterTouchDelay={0}
            arrow
          >
            <NewReleasesOutlinedIcon
              sx={{
                color: colors.yellow[800],
                right: xsDevice ? "-30px" : "unset",
                left: xsDevice ? "unset" : "-60px",
                top: 0,
                position: "absolute",
              }}
            />
          </Tooltip>
        ) : null}
      </Box>
      {amount && discountPrice && amount > 1 ? (
        <Paragraph style={{ marginX: { xs: 2, sm: 0 } }}>
          Subtotal: $ {toFormattedPrice({ number: amount * discountPrice })}
        </Paragraph>
      ) : null}
    </Box>
  );
};
