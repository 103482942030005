import { Skeleton } from "@mui/material";

export const PromotionBoxSkeleton = () => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        margin: 1,
        width: { xs: "146px", md: "320px" },
        height: { xs: "320px", md: "544px" },
      }}
    />
  );
};
