import { useQuery } from "@tanstack/react-query";

import { useAuthentication } from "hooks/useAuthentication";
import { API } from "utils/api";
import { isCompanyAdmin } from "utils/models/user";

export const usePendingQuotesCount = () => {
  const { user } = useAuthentication();

  const { data, isLoading, isError } = useQuery<{ count: number }>({
    queryKey: ["quotes", "pending_count"],
    queryFn: () =>
      API.get("quotes/pending_count").then(({ data: axiosData }) => axiosData),
    enabled: !!user && isCompanyAdmin(user),
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });

  return { isLoading, count: isError ? 0 : data?.count ?? 0 };
};
