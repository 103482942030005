type GetDiscountArgs = {
  amount?: number;
  companyBaseDiscount?: number; // discounts go from 0 to 100, and not from 0 to 1
  companyDiscount?: number; // discounts go from 0 to 100, and not from 0 to 1
  promotionDiscount?: number;
  promotionMinimumAmount?: number;
};

export const getDiscounts = ({
  amount = 0,
  companyBaseDiscount = 0,
  companyDiscount,
  promotionDiscount,
  promotionMinimumAmount,
}: GetDiscountArgs) => {
  // If exists any company discount and, also, promotion discount
  if (
    (companyBaseDiscount || companyDiscount) &&
    promotionDiscount &&
    promotionMinimumAmount
  ) {
    const companyTotalDiscount =
      companyBaseDiscount && companyDiscount
        ? 100 -
          (100 -
            companyBaseDiscount -
            ((100 - companyBaseDiscount) * companyDiscount) / 100)
        : companyBaseDiscount ?? companyDiscount;

    // If promotion discount is better, but promotionMinimumAmount is not reached
    if (
      amount < promotionMinimumAmount &&
      companyTotalDiscount < promotionDiscount
    ) {
      return {
        companyBaseDiscount,
        companyDiscount,
        promotionPossibleDicount: promotionDiscount,
        promotionMinimumAmount,
      };
    }

    // If promotion discount is better, and promotionMinimumAmount is reached
    if (
      amount >= promotionMinimumAmount &&
      companyTotalDiscount < promotionDiscount
    ) {
      return { promotionDiscount };
    }

    // As promotion discount is not better, return company discounts
    return { companyBaseDiscount, companyDiscount };
  }

  // If promotion discount applies, there are no company discounts
  if (promotionDiscount && promotionMinimumAmount) {
    return amount < promotionMinimumAmount
      ? { promotionPossibleDicount: promotionDiscount, promotionMinimumAmount }
      : { promotionDiscount };
  }

  // Default to any company discount
  return { companyBaseDiscount, companyDiscount };
};

type GetPriceArgs = GetDiscountArgs & { price?: number };

export const getPrice = ({ price, ...discountsConfig }: GetPriceArgs) => {
  if (!price) return price;

  const { companyBaseDiscount, companyDiscount, promotionDiscount } =
    getDiscounts(discountsConfig);

  let finalPrice = price;

  finalPrice = companyBaseDiscount
    ? finalPrice - (finalPrice * companyBaseDiscount) / 100
    : finalPrice;

  finalPrice = companyDiscount
    ? finalPrice - (finalPrice * companyDiscount) / 100
    : finalPrice;

  finalPrice = promotionDiscount
    ? finalPrice - (finalPrice * promotionDiscount) / 100
    : finalPrice;

  return finalPrice;
};
