import { Box, Modal as MaterialModal } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  ariaTitle?: string;
  open?: boolean;
  onClose?: () => void;
  style?: SxProps<Theme>;
};

export const Modal = ({
  ariaTitle,
  children,
  open = false,
  onClose,
  style,
}: Props) => {
  return (
    <MaterialModal open={open} onClose={onClose} aria-labelledby={ariaTitle}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 400,
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          border: "1px solid black",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          ...style,
        }}
      >
        {children}
      </Box>
    </MaterialModal>
  );
};
