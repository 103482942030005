import { InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { Order, OrderItem } from "types/models";
import { getDiscounts } from "utils/models/product";
import { toFormattedPrice } from "utils/numbers";

const sameDeprecatedPrice = (orderItem: OrderItem) =>
  Math.floor(orderItem.DEPRECATED_product_final_unit_price) ===
  Math.floor(orderItem.product_itris_final_unit_price);

const renderDiscounts = ({
  companyName,
  orderItem,
  allDiscounts,
}: {
  companyName?: string;
  orderItem: OrderItem;
  allDiscounts: ReturnType<typeof getDiscounts>;
}) => {
  const discounts = [];

  if (allDiscounts.companyBaseDiscount) {
    discounts.push(
      <Paragraph tooltip tooltipText={`Descuento base ${companyName}`} font="l">
        {allDiscounts.companyBaseDiscount}%
      </Paragraph>
    );
  }

  if (allDiscounts.companyBaseDiscount && allDiscounts.companyDiscount) {
    discounts.push(" + ");
  }

  if (allDiscounts.companyDiscount) {
    discounts.push(
      <Paragraph tooltip tooltipText="Descuento en marca/producto" font="l">
        {allDiscounts.companyDiscount}%
      </Paragraph>
    );
  }

  if (allDiscounts.promotionDiscount) {
    discounts.push(
      <Paragraph
        tooltip
        tooltipText={`Promoción a partir de ${orderItem.promotion_minimum_amount} unidades`}
        font="l"
      >
        {allDiscounts.promotionDiscount}%
      </Paragraph>
    );
  }

  return discounts.length ? (
    <Box display="flex" alignItems="center" justifyContent="center" gap="5px">
      <Tooltip
        title="Posa el mouse sobre el descuento para ver más información"
        arrow
      >
        <InfoOutlined fontSize="small" sx={{ transform: "scale(85%)" }} />
      </Tooltip>
      {discounts}
    </Box>
  ) : (
    "-"
  );
};

export const withSavedDiscount = {
  discounts: (orderItem: OrderItem, order?: Order) => {
    if (!orderItem.product_itris_final_discount) return "-";

    const allDiscounts = getDiscounts({
      amount: orderItem.amount,
      companyBaseDiscount: order?.company_base_discount,
      companyDiscount: orderItem.company_discount,
      promotionDiscount: orderItem.promotion_discount,
      promotionMinimumAmount: orderItem.promotion_minimum_amount,
    });

    const computedDiscount = allDiscounts.companyBaseDiscount
      ? (1 -
          (1 - allDiscounts.companyBaseDiscount / 100) *
            (1 - (allDiscounts.companyDiscount ?? 0) / 100)) *
        100
      : allDiscounts.promotionDiscount;
    const computedFmt = toFormattedPrice({ number: computedDiscount ?? 0 });
    const itrisDiscountFmt = toFormattedPrice({
      number: orderItem.product_itris_final_discount ?? 0,
    });

    return computedFmt === itrisDiscountFmt ? (
      renderDiscounts({
        companyName: order?.company.name,
        orderItem,
        allDiscounts,
      })
    ) : (
      <Paragraph font="l">{itrisDiscountFmt}%</Paragraph>
    );
  },
};

// Used to render orders created before the itris discounts sync
export const withoutSavedDiscount = {
  productPrice: (orderItem: OrderItem) =>
    sameDeprecatedPrice(orderItem) ? (
      <Paragraph font="l">
        ${toFormattedPrice({ number: orderItem.product_price ?? 0 })}
      </Paragraph>
    ) : (
      <Paragraph font="l">
        $
        {toFormattedPrice({
          number: orderItem.product_itris_final_unit_price,
        })}
      </Paragraph>
    ),
  productPriceWithDiscount: (orderItem: OrderItem) =>
    sameDeprecatedPrice(orderItem) ? (
      "-"
    ) : (
      <Paragraph font="l">
        $
        {toFormattedPrice({
          number: orderItem.product_itris_final_unit_price,
        })}
      </Paragraph>
    ),
  discounts: (orderItem: OrderItem, order?: Order) => {
    if (!sameDeprecatedPrice(orderItem)) return "-";
    const allDiscounts = getDiscounts({
      amount: orderItem.amount,
      companyBaseDiscount: order?.company_base_discount,
      companyDiscount: orderItem.company_discount,
      promotionDiscount: orderItem.promotion_discount,
      promotionMinimumAmount: orderItem.promotion_minimum_amount,
    });
    return renderDiscounts({
      companyName: order?.company.name,
      orderItem,
      allDiscounts,
    });
  },
  subtotal: (orderItem: OrderItem) => {
    if (!sameDeprecatedPrice(orderItem)) {
      return (
        <Box>
          <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
            $
            {toFormattedPrice({
              number:
                orderItem.product_itris_final_unit_price * orderItem.amount,
            })}
          </Title>
        </Box>
      );
    }
    const hasDiscount =
      orderItem.DEPRECATED_product_final_unit_price !== orderItem.product_price;
    return (
      <Box>
        {hasDiscount && (
          <Paragraph font="sm" style={{ textDecoration: "line-through" }}>
            $
            {toFormattedPrice({
              number: orderItem.product_price! * orderItem.amount,
            })}
          </Paragraph>
        )}
        <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
          $
          {toFormattedPrice({
            number:
              orderItem.DEPRECATED_product_final_unit_price * orderItem.amount,
          })}
        </Title>
      </Box>
    );
  },
  total: (orderItem: OrderItem) => (
    <Box>
      <Paragraph font="sm">(IVA {orderItem.product_human_tax})</Paragraph>
      <Title font="sm" style={{ marginX: { xs: 1, sm: 0 } }}>
        $
        {toFormattedPrice({
          number:
            orderItem.product_itris_final_unit_price *
            orderItem.amount *
            orderItem.product_tax_multiplier,
        })}
      </Title>
    </Box>
  ),
};
