import { Box, Skeleton } from "@mui/material";

export const FiltersSkeleton = () => {
  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Skeleton
          variant="rounded"
          height={56}
          sx={{ width: { md: 512 }, marginRight: 1, marginTop: 2 }}
        />
        <Skeleton
          variant="rounded"
          height={56}
          sx={{ width: { md: 256 }, marginRight: 1, marginTop: 2 }}
        />
        <Skeleton
          variant="rounded"
          height={56}
          sx={{ width: { md: 256 }, marginRight: 1, marginTop: 2 }}
        />
        <Skeleton
          variant="rounded"
          height={56}
          sx={{ width: { md: 256 }, marginRight: 1, marginTop: 2 }}
        />
      </Box>
      <Skeleton variant="text" height={24} width={128} sx={{ marginTop: 2 }} />
    </>
  );
};
