import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { PageOnTop } from "components/PageOnTop";
import {
  wildcardPath,
  productsPath,
  productPath,
  homePath,
  promotionsPath,
  backofficePath,
  recoverPasswordPath,
  backofficeLinesPath,
  backofficeUsersPath,
  backofficeGroupsPath,
  companyPath,
  backofficeCompanyDiscountsPath,
  backofficePromotionsPath,
  rrhhPath,
  backofficeCompaniesPath,
  backofficeProductsPath,
  backofficeBrandsPath,
  backofficeQuotesPath,
  backofficeOrdersPath,
  finishOrderPath,
  backofficeBannersPath,
  ordersPath,
  orderPath,
} from "constants/routes";
import { AuthenticationModal } from "modals/AuthenticationModal";
import { ConfirmationModal } from "modals/ConfirmationModal";
import { EmailModal } from "modals/EmailModal";
import { RecoverPasswordModal } from "modals/RecoverPasswordModal";
import { ShoppingCartModal } from "modals/ShoppingCartModal";
import { BackofficeBanners } from "screens/BackofficeBanners";
import { BackofficeBrands } from "screens/BackofficeBrands";
import { BackofficeCompanies } from "screens/BackofficeCompanies";
import { BackofficeCompanyDiscounts } from "screens/BackofficeCompanyDiscounts";
import { BackofficeGroups } from "screens/BackofficeGroups";
import { BackofficeLines } from "screens/BackofficeLines";
import { BackofficeOrders } from "screens/BackofficeOrders";
import { BackofficeProducts } from "screens/BackofficeProducts";
import { BackofficePromotions } from "screens/BackofficePromotions";
import { BackofficeQuotes } from "screens/BackofficeQuotes";
import { BackofficeUsers } from "screens/BackofficeUsers";
import { Company } from "screens/Company";
import { FinishOrder } from "screens/FinishOrder";
import { Home } from "screens/Home";
import { Order } from "screens/Order";
import { Product } from "screens/Product";
import { Products } from "screens/Products";
import { Promotions } from "screens/Promotions";
import { RecoverPassword } from "screens/RecoverPassword";
import { Rrhh } from "screens/Rrhh";

export const AppRoutes = () => (
  <Router>
    <PageOnTop />
    <ShoppingCartModal />
    <AuthenticationModal />
    <EmailModal />
    <ConfirmationModal />
    <RecoverPasswordModal />

    <Routes>
      <Route path={homePath} element={<Home />} />

      <Route path={productsPath}>
        <Route index element={<Products />} />
        <Route path={productPath} element={<Product />} />
      </Route>

      <Route path={promotionsPath} element={<Promotions />} />

      <Route path={finishOrderPath} element={<FinishOrder />} />

      <Route path={recoverPasswordPath} element={<RecoverPassword />} />

      {/* <Route path={contactPath} element={<Contact />} /> */}

      <Route path={companyPath} element={<Company />} />

      <Route path={rrhhPath} element={<Rrhh />} />

      <Route path={ordersPath}>
        <Route path={orderPath} element={<Order />} />
      </Route>

      {/* Backoffice */}
      <Route path={backofficePath}>
        <Route index element={<Navigate to={backofficeUsersPath} />} />
        <Route path={backofficeQuotesPath} element={<BackofficeQuotes />} />
        <Route path={backofficeOrdersPath} element={<BackofficeOrders />} />
        <Route path={backofficeBannersPath} element={<BackofficeBanners />} />
        <Route
          path={backofficeCompaniesPath}
          element={<BackofficeCompanies />}
        />
        <Route path={backofficeUsersPath} element={<BackofficeUsers />} />
        <Route path={backofficeGroupsPath} element={<BackofficeGroups />} />
        <Route path={backofficeLinesPath} element={<BackofficeLines />} />
        <Route path={backofficeBrandsPath} element={<BackofficeBrands />} />
        <Route path={backofficeProductsPath} element={<BackofficeProducts />} />
        <Route
          path={backofficeCompanyDiscountsPath}
          element={<BackofficeCompanyDiscounts />}
        />
        <Route
          path={backofficePromotionsPath}
          element={<BackofficePromotions />}
        />
      </Route>

      {/* Fallback to home */}
      <Route path={wildcardPath} element={<Navigate to={homePath} />} />
    </Routes>
  </Router>
);
