import { Box, Button } from "@mui/material";
import { useCallback, useContext, useState } from "react";

import { Modal } from "components/Modal";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";

export const ConfirmationModal = () => {
  const [loading, setLoading] = useState(false);
  const { confirmationModalProps, setConfirmationModalProps } =
    useContext(ModalContext);

  const onClose = useCallback(() => {
    if (!loading) setConfirmationModalProps({ open: false });
  }, [loading, setConfirmationModalProps]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await confirmationModalProps.onSubmit?.();
    } finally {
      setLoading(false);
    }
  }, [confirmationModalProps]);

  return (
    <Modal
      open={confirmationModalProps.open}
      onClose={onClose}
      ariaTitle={`Modal para ${
        confirmationModalProps.title || "confirmar acción"
      }`}
    >
      <Title style={{ marginBottom: 1 }}>
        {confirmationModalProps.title || "Confirmar acción"}
      </Title>
      <Paragraph>
        {confirmationModalProps.text || "Haz click para confirmar la acción"}
      </Paragraph>
      <Box display="flex" sx={{ marginTop: 2 }}>
        <Button
          disabled={loading}
          onClick={onClose}
          style={{ flexGrow: 1, marginRight: 5 }}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          disabled={loading}
          style={{ flexGrow: 1, marginLeft: 5 }}
          variant="contained"
          type="submit"
          onClick={onSubmit}
          color={confirmationModalProps.buttonColor || "primary"}
        >
          {confirmationModalProps.buttonText || "Confirmar"}
        </Button>
      </Box>
    </Modal>
  );
};
