import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect } from "react";
import { object, string } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Modal } from "components/Modal";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useToast } from "hooks/useToast";

export const EmailModal = () => {
  const { user } = useAuthentication();
  const { infoToast, errorToast } = useToast();
  const { emailModalProps, setEmailModalProps } = useContext(ModalContext);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: object({
      email: string().email("Email inválido").required("Requerido"),
    }),
    onSubmit: async ({ email }) => {
      try {
        await emailModalProps.onSubmit?.({ email });
        setEmailModalProps({ open: false });
        infoToast(emailModalProps.successTootlip ?? "Email enviado con éxito.");
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  const onClose = useCallback(() => {
    if (!formik.isSubmitting) setEmailModalProps({ open: false });
  }, [formik.isSubmitting, setEmailModalProps]);

  useEffect(() => {
    if (user) {
      formik.setFieldValue("email", user.email);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={emailModalProps.open}
      onClose={onClose}
      ariaTitle={`Modal para ${emailModalProps.title || "ingresar email"}`}
    >
      <Title style={{ marginBottom: 1 }}>
        {emailModalProps.title || "Ingresar email"}
      </Title>
      <Paragraph>
        Ingresa el email en el que quieres recibir el correo:
      </Paragraph>
      <Form formik={formik}>
        <FormTextField
          name="email"
          label="Email"
          placeholder="usuario@bluecar.com.ar"
          type="email"
          isRequired
          fullWidth
        />
        <Box display="flex" sx={{ marginTop: 2 }}>
          <Button
            disabled={formik.isSubmitting}
            onClick={onClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            {emailModalProps.buttonText || "Enviar"}
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
