import AddIcon from "@mui/icons-material/Add";
import { Fab, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useState } from "react";

import { Modal } from "components/Modal";
import { Title } from "components/Title";
import { ChildrenProps } from "types/props";

type ModalProps = {
  handleClose?: () => void;
};

type Props = {
  title: string;
};

export const useCreationModal = ({ title }: Props) => {
  const theme = useTheme();
  const smDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const CreationModal = useCallback(
    ({
      children,
      handleClose: handleCloseExternal,
    }: ChildrenProps & ModalProps) => (
      <>
        <Tooltip title={title}>
          <Fab
            size={smDevice ? "small" : "medium"}
            color="primary"
            sx={{ position: "fixed", right: 24, bottom: 80 }}
            onClick={handleOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Modal
          open={open}
          onClose={() => {
            handleClose();
            handleCloseExternal?.();
          }}
          ariaTitle={`Modal para ${title}`}
          style={{ width: "40%" }}
        >
          <Title>{title}</Title>
          {children}
        </Modal>
      </>
    ),
    [handleClose, handleOpen, open, smDevice, title]
  );

  return { CreationModal, handleClose, handleOpen };
};
