export const wildcardPath = "*";

export const homePath = "/";

export const productsPath = "/products";
export const productPath = ":productId";

export const ordersPath = "/orders";
export const orderPath = ":orderId";

export const promotionsPath = "/promotions";
export const finishOrderPath = "/finish-order";
export const companyPath = "/company";
export const rrhhPath = "/rrhh";
export const contactPath = "https://contacto.bluecar.com.ar";
// export const contactPath = "/contact";

export const recoverPasswordPath = "/recover-password";

export const backofficePath = "/backoffice";
export const backofficeBannersPath = "banners";
export const backofficeCompaniesPath = "companies";
export const backofficeUsersPath = "users";
export const backofficeGroupsPath = "groups";
export const backofficeLinesPath = "lines";
export const backofficeBrandsPath = "brands";
export const backofficeProductsPath = "products";
export const backofficeCompanyDiscountsPath = "company-discounts";
export const backofficePromotionsPath = "promotions";
export const backofficeQuotesPath = "quotes";
export const backofficeOrdersPath = "orders";
