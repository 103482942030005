import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useMemo, useContext } from "react";

import { queryClient } from "App";
import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { UserCreationModal } from "modals/UserCreationModal";
import { User } from "types/models";
import { API } from "utils/api";
import {
  humanizeRole,
  isAdmin,
  ROLES,
  COMPANY_ADMIN,
  BLUECAR_ROLES_IDS,
  isBluecarRole,
} from "utils/models/user";

const baseColumns: Column<User>[] = [
  { id: "name", label: "Nombre" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Teléfono" },
  {
    id: "role",
    label: "Rol",
    renderer: (user) => humanizeRole(user),
  },
];

const companyColumn: Column<User> = {
  id: "company",
  label: "Empresa",
  renderer: (user) => user.company?.name,
};

const allFilters = [
  { id: "search", label: "Nombre / Email" },
  { id: "role", label: "Rol", options: ROLES, roles: BLUECAR_ROLES_IDS },
  {
    id: "company_id",
    label: "Empresa",
    roles: BLUECAR_ROLES_IDS,
    options: [],
  },
];

export const BackofficeUsers = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Usuarios",
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  });
  const { setConfirmationModalProps } = useContext(ModalContext);
  const { infoToast, errorToast } = useToast();
  const { user } = useAuthentication();
  const { data: companies } = useAllCompanies();

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: (userId: string) => API.delete(`/users/${userId}`),
    onSuccess: () => {
      infoToast("Usuario eliminado con éxito.");
      queryClient.invalidateQueries(["users"]);
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido eliminar el usuario, intenta nuevamente"),
  });

  const actionColumn: Column<User> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (rowUser) => (
        <Tooltip title="Eliminar usuario">
          <Button
            onClick={() =>
              setConfirmationModalProps({
                open: true,
                title: "Eliminar usuario",
                text: "Si eliminas el usuario perderá el acceso a su cuenta",
                buttonText: "Eliminar",
                buttonColor: "error",
                onSubmit: () => deleteUser(rowUser.id),
              })
            }
          >
            <DeleteIcon titleAccess="Eliminar" />
          </Button>
        </Tooltip>
      ),
      align: "center",
    }),
    [setConfirmationModalProps, deleteUser]
  );

  const columns = useMemo(() => {
    if (user) {
      if (isAdmin(user)) {
        return [...baseColumns, companyColumn, actionColumn];
      } else if (isBluecarRole(user)) {
        return [...baseColumns, companyColumn];
      }
    }
    return baseColumns;
  }, [user, actionColumn]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters
      .filter((filter) => {
        if (filter.roles) {
          return filter.roles.includes(user?.role);
        }
        return true;
      })
      .map((filter) => {
        if (filter.id === "company_id") {
          return {
            ...filter,
            options:
              companies?.map((company) => ({
                id: company.id,
                label: company.name,
              })) || [],
          };
        }
        return filter;
      });
  }, [companies, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="users"
          path="users"
        />
      )}
      {!!user && isBluecarRole(user) && <UserCreationModal user={user} />}
    </BackofficeLayout>
  );
};
