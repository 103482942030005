import { useQuery } from "@tanstack/react-query";

import { User } from "types/models";
import { API } from "utils/api";

export const useMe = () => {
  const queryFn = (): Promise<User> =>
    API.get("users/me").then(({ data }) => data);

  return useQuery({
    queryKey: ["users/me"],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });
};
