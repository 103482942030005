import { RefObject, useEffect, useMemo, useState } from "react";

export const useIsOnScreen = ({
  ref,
  rootMargin = "0px",
  onScreen,
}: {
  ref: RefObject<HTMLElement>;
  rootMargin?: `${number}px`;
  onScreen?: () => void;
}) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
          if (entry.isIntersecting) {
            onScreen?.();
          }
        },
        {
          rootMargin,
        }
      ),
    [onScreen, rootMargin]
  );

  useEffect(() => {
    const { current } = ref;
    if (current) {
      observer.observe(current);
    }
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};
