import { colors } from "@mui/material";

import { Paragraph } from "components/Paragraph";

type Props = {
  externalId: string;
  noTitle?: boolean;
};

export const ProductExternalId = ({ externalId, noTitle }: Props) => {
  const title = noTitle ? "" : "ID: ";
  return (
    <Paragraph
      font="l"
      ellipsis
      uppercase
      style={{ color: colors.blue[800], fontWeight: "bold" }}
    >
      {title}
      {externalId}
    </Paragraph>
  );
};
