import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Paragraph } from "components/Paragraph";
import { productsPath } from "constants/routes";

type Props = {
  handleClose: () => void;
};

export const EmptyShoppingCart = ({ handleClose }: Props) => {
  const navigate = useNavigate();

  const handleAddProducts = () => {
    handleClose();
    navigate(productsPath);
  };

  return (
    <>
      <Paragraph style={{ marginY: 1 }}>
        ¡Tu carrito de compras se encuentra vacío!
      </Paragraph>
      <Box display="flex">
        <Button
          onClick={handleClose}
          style={{ flexGrow: 1, marginRight: 5 }}
          variant="outlined"
        >
          Cerrar
        </Button>
        <Button
          onClick={handleAddProducts}
          style={{ flexGrow: 1, marginLeft: 5 }}
          variant="contained"
          type="submit"
        >
          Agregar productos
        </Button>
      </Box>
    </>
  );
};
