export const BLUECAR_PHONE = "541141499139";
export const BLUECAR_PHONE_HUMAN = "+54 11 4149-9139";

export const BLUECAR_WHATSAPP = BLUECAR_PHONE;
export const BLUECAR_WHATSAPP_HUMAN = BLUECAR_PHONE_HUMAN;

export const BLUECAR_EMAIL = "info@bluecar.com.ar";

export const BLUECAR_GMAPS = "https://goo.gl/maps/oCsnk9m6HGiVBnWD6";
export const BLUECAR_ADDRESS_HUMAN = "California 2067, C1289, Buenos Aires";
