import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, colors, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Carousel } from "components/Carousel";
import { Paragraph } from "components/Paragraph";
import { HomeTitle } from "components/screens/home/HomeTitle";
import { ProductSkeleton } from "components/screens/product/ProductSkeleton";
import { ProductBox } from "components/screens/products/ProductBox";
import { ProductBoxShopping } from "components/screens/products/ProductBoxShopping";
import { ProductBoxSkeleton } from "components/screens/products/ProductBoxSkeleton";
import { Title } from "components/Title";
import { contactPath, homePath, productsPath } from "constants/routes";
import { ContactContext } from "contexts/ContactProvider";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useFullscreenImage } from "hooks/useFullscreenImage";
import { useLayout } from "hooks/useLayout";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { Product as ProductType } from "types/models";
import { API } from "utils/api";

const EQUIVALENTS_PER_PAGE = 20;

export const Product = () => {
  const { PublicLayout } = useLayout({ title: "Productos" });
  const { user } = useAuthentication();
  const { productId } = useParams();
  const { setMessage } = useContext(ContactContext);
  const navigate = useNavigate();

  if (!productId) {
    navigate(homePath);
  }

  const { company } = useContext(ModalContext);
  const {
    data: product,
    isLoading: isLoadingProduct,
    isError,
  } = useQuery<ProductType>({
    queryKey: ["product", productId, company?.id],
    queryFn: () =>
      API.get(`products/${productId}?company_id=${company?.id}`).then(
        ({ data }) => data
      ),
  });

  const handleContact = useCallback(() => {
    if (!product) return;
    navigate(contactPath);
    setMessage(
      `Hola!\n\nNo soy cliente y quiero obtener más información sobre el producto: ${product?.external_id} (${product.name}).\n\nGracias.`
    );
  }, [navigate, product, setMessage]);

  const carouselItems = useMemo(
    () =>
      product?.pictures?.length
        ? product.pictures.map((picture, ix) => ({
            title: `${product?.name} ${ix + 1}`,
            imageUrl: picture,
            url: picture,
          }))
        : [
            {
              title: "prodcut default placeholder",
              imageUrl: "https://via.placeholder.com/128x128",
              url: "https://via.placeholder.com/128x128",
            },
          ],
    [product?.name, product?.pictures]
  );

  const { FullscreenImage, imageStyles, openImage } = useFullscreenImage({
    images: carouselItems,
  });

  const { data: equivalents, isLoading: isLoadingEquivalents } =
    usePaginatedQuery<ProductType>({
      queryKey: ["product", productId, "equivalents"],
      path: `products/${productId}/equivalents?per=${EQUIVALENTS_PER_PAGE}`,
    });

  if (isError) {
    navigate(homePath);
  }

  return (
    <PublicLayout>
      <Box padding={{ xs: 3, md: 7 }}>
        {isLoadingProduct || isLoadingEquivalents || !product ? (
          <>
            <ProductSkeleton />

            <Box sx={{ marginTop: { xs: 3 } }}>
              <HomeTitle title="Equivalentes" />
              <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
                {Array.from({ length: EQUIVALENTS_PER_PAGE }).map(
                  (_, i: number) => (
                    <ProductBoxSkeleton key={i} />
                  )
                )}
              </Box>
            </Box>
          </>
        ) : (
          <>
            {FullscreenImage}
            <Box
              display="flex"
              position="relative"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "0", md: "40px" },
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                marginBottom={3}
              >
                <Carousel
                  items={carouselItems}
                  animation="slide"
                  navButtonsStyles={{ backgroundColor: colors.blue[700] }}
                  imageStyles={
                    product?.pictures?.length
                      ? { ...imageStyles, objectFit: "scale-down" }
                      : undefined
                  }
                  onImageClick={openImage}
                  style={{
                    marginTop: 0,
                    height: { xs: 256, md: 320, lg: 464 },
                    width: { xs: 256, md: 320, lg: 512 },
                  }}
                  indicators
                />
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                flexDirection="column"
                sx={{
                  alignItems: { xs: "center", md: "start" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Paragraph>Marca</Paragraph>
                <Title>{product.brand_name}</Title>
                <Paragraph style={{ marginTop: { xs: 1, md: 2 } }}>
                  Grupo
                </Paragraph>
                <Title>{product.group_name}</Title>
                <Paragraph style={{ marginTop: { xs: 1, md: 2 } }}>
                  Linea
                </Paragraph>
                <Title>{product.line_name}</Title>
                <Paragraph style={{ marginTop: { xs: 1, md: 2 } }}>
                  Nombre
                </Paragraph>
                <Title>{product.name}</Title>
                <Paragraph style={{ marginTop: { xs: 1, md: 2 } }}>
                  ID
                </Paragraph>
                <Title>{product.external_id}</Title>
                {user && (
                  <>
                    <Paragraph style={{ marginTop: { xs: 1, md: 2 } }}>
                      Códigos OEM
                    </Paragraph>
                    <Title disableSelect>
                      {product.oem_codes?.length
                        ? product.oem_codes.map((code, ix) => (
                            <Fragment key={`product-${ix}-oem-code`}>
                              <Link
                                style={{ color: colors.blue[700] }}
                                to={`${productsPath}?search=${code}`}
                              >
                                {code}
                              </Link>
                              {product.oem_codes?.length &&
                              ix !== product.oem_codes?.length - 1
                                ? " "
                                : ""}
                            </Fragment>
                          ))
                        : "-"}
                    </Title>
                  </>
                )}
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                flexDirection="column"
                sx={{
                  right: { xs: "unset", md: 0 },
                  top: { xs: "unset", md: 0 },
                  position: { xs: "relative", md: "absolute" },
                  minWidth: { xs: "100%", md: "auto" },
                  marginTop: { xs: 2, md: 0 },
                  alignItems: "center",
                }}
              >
                {user ? (
                  <ProductBoxShopping product={product} showTitle showIcon />
                ) : (
                  <Button
                    onClick={handleContact}
                    disabled={isLoadingProduct}
                    startIcon={<TaskAltIcon />}
                  >
                    Consultar
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: { xs: 3 } }}>
              <HomeTitle
                title="Equivalentes"
                rightButton={
                  equivalents?.results.length && equivalents.total_pages !== "1"
                    ? {
                        to: `${productsPath}?search=${product.name}`,
                        text: "Ver todos",
                      }
                    : undefined
                }
              />
              <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
                {equivalents?.results.map((equivalent) => (
                  <ProductBox key={equivalent.id} product={equivalent} />
                ))}
                {!isLoadingEquivalents && !equivalents?.results.length && (
                  <Paragraph style={{ marginTop: 2 }}>
                    No se registran productos equivalentes
                  </Paragraph>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </PublicLayout>
  );
};
