import { NavLink } from "react-router-dom";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  to: string;
  noMargin?: boolean;
  style?: React.CSSProperties;
  external?: boolean;
};

export const Link = ({ to, children, noMargin, style, external }: Props) => {
  const margins = noMargin ? {} : { marginRight: "1rem", marginLeft: "1rem" };
  const commonStyles = {
    textDecoration: "none",
    color: "black",
    ...margins,
    ...style,
  };

  return external ? (
    <a href={to} target="_blank" style={commonStyles}>
      {children}
    </a>
  ) : (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        ...commonStyles,
        color: isActive ? "gray" : "black",
        cursor: isActive ? "text" : "pointer",
      })}
    >
      {children}
    </NavLink>
  );
};
