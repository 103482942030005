import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { Quote } from "types/models";
import { API } from "utils/api";

export const useCompanyBaseDiscount = () => {
  const { quoteModalProps } = useContext(ModalContext);

  const { data: quote } = useQuery<Quote>({
    queryKey: ["quotes", quoteModalProps.quoteId],
    queryFn: () =>
      API.get(`quotes/${quoteModalProps.quoteId}`).then(({ data }) => data),
    enabled: !!quoteModalProps.quoteId,
    staleTime: Infinity,
  });

  const { company } = useContext(ModalContext);
  const { user } = useAuthentication();

  if (quoteModalProps.quoteId) return quote?.company_base_discount;

  return user?.company?.discount || company?.baseDiscount;
};
