import { createContext, useState } from "react";

import { SingleOption } from "components/FormSelect";
import { ChildrenProps } from "types/props";

type ProductsContextType = {
  tableView?: boolean;
  setTableView: (tableView?: boolean) => void;

  sorting: SingleOption | undefined;
  setSorting: (option?: SingleOption | undefined) => void;
};

export const ProductsContext = createContext<ProductsContextType>({
  tableView: false,
  setTableView: () => {},
  sorting: undefined,
  setSorting: () => {},
});

export const ProductsProvider = ({ children }: ChildrenProps) => {
  const [tableView, setTableView] =
    useState<ProductsContextType["tableView"]>();
  const [sorting, setSorting] = useState<ProductsContextType["sorting"]>();

  return (
    <ProductsContext.Provider
      value={{ tableView, setTableView, sorting, setSorting }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
