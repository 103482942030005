import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Badge, Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { Paragraph } from "components/Paragraph";
import {
  backofficeCompanyDiscountsPath,
  backofficePath,
  backofficeQuotesPath,
  productsPath,
} from "constants/routes";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { usePendingQuotesCount } from "hooks/usePendingQuotesCount";
import { isCompanySeller } from "utils/models/user";

export const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { setIsAuthenticationModalOpen } = useContext(ModalContext);
  const { user, signOut } = useAuthentication();
  const navigate = useNavigate();
  const isBasckoffice = useMatch(`${backofficePath}/*`);

  const { count: pendingQuotesCount } = usePendingQuotesCount();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = useMemo(() => {
    if (user) {
      const options = isBasckoffice
        ? [
            {
              label: "Volver al sitio",
              handleClick: () => navigate(productsPath),
            },
          ]
        : [
            {
              label: user.company?.name || "Bluecar",
              handleClick: () =>
                navigate(
                  `${backofficePath}/${
                    isCompanySeller(user)
                      ? backofficeCompanyDiscountsPath
                      : backofficeQuotesPath
                  }`
                ),
            },
          ];
      return [
        ...options,
        {
          label: "Cerrar sesión",
          handleClick: () => {
            signOut();
            handleCloseUserMenu();
          },
        },
      ];
    }
    return [
      {
        label: "Iniciar sesión",
        handleClick: () => {
          setIsAuthenticationModalOpen(true);
          handleCloseUserMenu();
        },
      },
    ];
  }, [isBasckoffice, navigate, setIsAuthenticationModalOpen, signOut, user]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Menu">
        <IconButton onClick={handleOpenUserMenu} size="large">
          <Badge
            badgeContent={isBasckoffice ? 0 : pendingQuotesCount}
            color="primary"
          >
            <AccountCircleIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="user-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.label} onClick={setting.handleClick}>
            <Paragraph>{setting.label}</Paragraph>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
