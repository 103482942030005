import { useQuery } from "@tanstack/react-query";

import { Brand } from "types/models";
import { API } from "utils/api";

interface Props {
  active?: boolean;
  showHome?: boolean;
  line?: string;
  group?: string;
}

export const useAllBrands = ({ active, showHome, line, group }: Props) => {
  const showHomeString = showHome ? `&show_home=${showHome}` : "";
  const activeString = active ? "&active=true" : "";
  const lineString = line ? `&line_id=${line}` : "";
  const groupString = group ? `&group_id=${group}` : "";

  const queryFn = (): Promise<Brand[]> =>
    API.get(
      `brands?all=true${showHomeString}${activeString}${lineString}${groupString}`
    ).then(({ data }) => data);

  return useQuery({
    queryKey: [
      "brands",
      "all",
      showHomeString,
      activeString,
      lineString,
      groupString,
    ],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });
};
