import {
  Delete,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { queryClient } from "App";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { BannerCreationModal } from "modals/BannerCreationModal";
import { Banner } from "types/models";
import { API } from "utils/api";
import { BLUECAR_ROLES_IDS, isAdmin } from "utils/models/user";

const baseColumns: Column<Banner>[] = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activo",
    renderer: (company) => (Boolean(company.active) ? "Sí" : "No"),
  },
  {
    id: "image_url",
    label: "Banner",
    renderer: (banner) => (
      <a href={banner.image_url} target="_blank">
        Abrir
      </a>
    ),
  },
];

const filters = [
  { id: "name", label: "Nombre" },
  {
    id: "active",
    label: "Activa",
    options: [
      { id: "true", label: "Sí" },
      { id: "false", label: "No" },
    ],
  },
];

export const BackofficeBanners = () => {
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Empresas",
    roles: BLUECAR_ROLES_IDS,
  });
  const { setConfirmationModalProps } = useContext(ModalContext);
  const { infoToast, errorToast } = useToast();
  const { user } = useAuthentication();

  const { mutateAsync: toggleBannerVisibility } = useMutation({
    mutationFn: ({ active, bannerId }: { active: boolean; bannerId: string }) =>
      API.put(`/banners/${bannerId}`, { active }),
    onSuccess: () => {
      infoToast("Banner actualizado con éxito.");
      queryClient.invalidateQueries(["banners"]);
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido actualizar el banner, intenta nuevamente"),
  });

  const { mutateAsync: deleteBanner } = useMutation({
    mutationFn: (bannerId: string) => API.delete(`/banners/${bannerId}`),
    onSuccess: () => {
      infoToast("Banner eliminado con éxito.");
      queryClient.invalidateQueries(["banners"]);
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido eliminar el banner, intenta nuevamente"),
  });

  const actionColumn: Column<Banner> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      align: "center",
      renderer: (banner) => (
        <Box display="flex" justifyContent="center" gap="5px">
          <Tooltip
            title={banner.active ? "Desactivar banner" : "Activar banner"}
          >
            <Button
              onClick={() =>
                setConfirmationModalProps({
                  open: true,
                  title: banner.active ? "Desactivar banner" : "Activar banner",
                  text: banner.active
                    ? "¿Quieres desactivar el banner? Esta acción desactivará el banner del home de Bluecar."
                    : "¿Quieres eactivar el banner? Esta acción agregará el banner en el home de Bluecar.",
                  buttonText: banner.active ? "Desactivar" : "Activar",
                  onSubmit: () =>
                    toggleBannerVisibility({
                      bannerId: banner.id,
                      active: !banner.active,
                    }),
                })
              }
            >
              {banner.active ? (
                <VisibilityOffOutlined titleAccess="Desactivar" />
              ) : (
                <VisibilityOutlined titleAccess="Activar" />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Eliminar banner">
            <Button
              onClick={() =>
                setConfirmationModalProps({
                  open: true,
                  title: "Eliminar banner",
                  text: "¿Quieres eliminar el banner? Esta acción no es reversible y deberás volver a crearlo para que vuelva a existir.",
                  buttonText: "Eliminar",
                  buttonColor: "error",
                  onSubmit: () => deleteBanner(banner.id),
                })
              }
            >
              <Delete titleAccess="Eliminar" />
            </Button>
          </Tooltip>
        </Box>
      ),
    }),
    [setConfirmationModalProps, deleteBanner, toggleBannerVisibility]
  );

  const columns = useMemo(
    () => (isAdmin(user) ? [...baseColumns, actionColumn] : baseColumns),
    [actionColumn, user]
  );

  return (
    <BackofficeLayout>
      <Table
        columns={columns}
        filters={filters}
        queryKey="banners"
        path="banners"
      />
      {!!user && isAdmin(user) && <BannerCreationModal />}
    </BackofficeLayout>
  );
};
