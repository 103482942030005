import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Paper } from "components/Paper";
import { Paragraph } from "components/Paragraph";
import { ProductExternalId } from "components/ProductExternalId";
import { ProductBoxShopping } from "components/screens/products/ProductBoxShopping";
import { Title } from "components/Title";
import { productsPath } from "constants/routes";
import { useAuthentication } from "hooks/useAuthentication";
import { useFullscreenImage } from "hooks/useFullscreenImage";
import { Product } from "types/models";

type Props = {
  product: Product;
};

export const ProductBox = ({ product }: Props) => {
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const { FullscreenImage, imageStyles, openImage } = useFullscreenImage({
    images: [
      {
        title: product.name,
        url: product.image_url || "https://via.placeholder.com/128x128",
      },
    ],
  });

  return (
    <>
      {FullscreenImage}
      <Paper
        baseElevation={1}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 2,
          margin: 1,
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          width: { xs: "95%", lg: "48%" },
          minWidth: { xs: "95%", lg: "48%" },
          maxWidth: { xs: "95%", lg: "48%" },
          transition: "all 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
        onClick={() => navigate(`${productsPath}/${product.id}`)}
      >
        <Box
          onClick={openImage}
          component="img"
          height={xsDevice ? 64 : 128}
          width={xsDevice ? 64 : 128}
          src={product.image_url || "https://via.placeholder.com/128x128"}
          alt={product.name}
          sx={{
            ...imageStyles,
            filter: product.stock === 0 ? "opacity(50%)" : "unset",
          }}
        />
        <Box
          marginLeft={3}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="hidden"
        >
          <Paragraph ellipsis uppercase>
            {product.brand_name}
          </Paragraph>
          <Paragraph ellipsis uppercase>
            {product.group_name}
            {product.group_name && product.line_name ? " - " : ""}
            {product.line_name}
          </Paragraph>
          <Title font="sm" ellipsis uppercase tooltip>
            {product.name}
          </Title>
          <ProductExternalId externalId={product.external_id} />
          {user && product.oem_codes?.length ? (
            <Paragraph ellipsis uppercase disableSelect>
              Códigos OEM: {product.oem_codes.join(" ")}
            </Paragraph>
          ) : null}
          {xsDevice ? <ProductBoxShopping product={product} /> : null}
        </Box>
        {xsDevice ? null : <ProductBoxShopping product={product} />}
      </Paper>
    </>
  );
};
