import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Tooltip, colors } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useMemo, useContext } from "react";

import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { Column, Table } from "components/Table";
import { ModalContext } from "contexts/ModalProvider";
import { useAllCompanies } from "hooks/useAllCompanies";
import { useAuthentication } from "hooks/useAuthentication";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { QuoteModal } from "modals/QuoteModal";
import { Quote } from "types/models";
import { API } from "utils/api";
import { datetimeString } from "utils/date";
import {
  humanizeQuote,
  isApproved,
  isPending,
  STATUS_OPTIONS,
} from "utils/models/quote";
import {
  COMPANY_ADMIN,
  BLUECAR_ROLES_IDS,
  isBluecarRole,
} from "utils/models/user";
import { mapToSelect } from "utils/select";

const baseColumns: Column<Quote>[] = [
  { id: "id", label: "Identificador" },
  {
    id: "status",
    label: "Estado",
    renderer: (quote) => (
      <Paragraph
        style={{
          color: isPending(quote)
            ? colors.yellow[800]
            : isApproved(quote)
            ? colors.green[700]
            : colors.red[700],
        }}
      >
        {humanizeQuote(quote)}
      </Paragraph>
    ),
  },
  {
    id: "created_at",
    label: "Creada ",
    renderer: (order) => datetimeString(order.created_at),
  },
  {
    id: "quote_items",
    label: "Items",
    renderer: (quote) =>
      quote.quote_items
        ?.map((item) => `${item.product_name} x${item.amount}`)
        .join(", "),
  },
];

const bluecarColumns: Column<Quote>[] = [
  {
    id: "company",
    label: "Empresa",
    renderer: (quote) => quote.company?.name,
  },
  {
    id: "creator",
    label: "Creador",
    renderer: (quote) => quote.creator?.name || quote.creator?.email || "-",
  },
];

const allFilters = [
  { id: "id", label: "Identificador" },
  {
    id: "status",
    label: "Estado",
    options: STATUS_OPTIONS,
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  },
  {
    id: "company_id",
    label: "Empresa",
    roles: BLUECAR_ROLES_IDS,
    options: [],
  },
];

export const BackofficeQuotes = () => {
  const { infoToast, errorToast } = useToast();
  const { BackofficeLayout } = useLayout({
    isPrivate: true,
    title: "Cotizaciones",
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  });
  const { setQuoteModalProps } = useContext(ModalContext);
  const { user } = useAuthentication();
  const { data: companies } = useAllCompanies();

  const { setConfirmationModalProps } = useContext(ModalContext);
  const { mutateAsync: resentQuoteToCustomer } = useMutation({
    mutationFn: (quoteId: string) =>
      API.post(`/quotes/${quoteId}/resend_to_customer`),
    onSuccess: () => {
      infoToast("Cotización reenviada con éxito.");
      setConfirmationModalProps({ open: false });
    },
    onError: () =>
      errorToast("No se ha podido reenviar la cotización, intenta nuevamente"),
  });

  const actionColumn: Column<Quote> = useMemo(
    () => ({
      id: "id",
      label: "Acciones",
      renderer: (quote) => (
        <Box display="flex" justifyContent="center" gap="5px">
          <Tooltip title="Ver cotización">
            <Button
              onClick={() =>
                setQuoteModalProps({ open: true, quoteId: quote.id })
              }
            >
              <VisibilityIcon titleAccess="Ver" />
            </Button>
          </Tooltip>
          {isPending(quote) && (
            <Tooltip title="Reenviar cotización">
              <Button
                onClick={() =>
                  setConfirmationModalProps({
                    open: true,
                    title: "Reenviar cotización",
                    text: `¿Quieres reenviar la cotización de ${quote.company?.name}?`,
                    buttonText: "Reenviar",
                    onSubmit: () => resentQuoteToCustomer(quote.id),
                  })
                }
              >
                <SendIcon titleAccess="Reenviar cotización" />
              </Button>
            </Tooltip>
          )}
        </Box>
      ),
      align: "center",
    }),
    [resentQuoteToCustomer, setConfirmationModalProps, setQuoteModalProps]
  );

  const columns = useMemo(() => {
    if (user) {
      if (isBluecarRole(user)) {
        return [...bluecarColumns, ...baseColumns, actionColumn];
      }
      return [...baseColumns, actionColumn];
    }
    return baseColumns;
  }, [user, actionColumn]);

  const filters = useMemo(() => {
    if (!user) return [];
    return allFilters
      .filter((filter) => {
        if (filter.roles) {
          return filter.roles.includes(user?.role);
        }
        return true;
      })
      .map((filter) => {
        if (filter.id === "company_id") {
          return { ...filter, options: mapToSelect({ elements: companies }) };
        }
        return filter;
      });
  }, [companies, user]);

  const anyFilterLoading = useMemo(() => {
    return filters.some((filter) => !!filter.options && !filter.options.length);
  }, [filters]);

  return (
    <BackofficeLayout>
      {anyFilterLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          filters={filters}
          queryKey="quotes"
          path="quotes"
        />
      )}
      <QuoteModal />
    </BackofficeLayout>
  );
};
