import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { string, object, mixed } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { FormUploadButton } from "components/FormUploadButton";
import { Header } from "components/Header";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const Rrhh = () => {
  const { PublicLayout } = useLayout({ title: "RRHH" });
  const { infoToast, errorToast } = useToast();

  const { mutate: sendResume, isLoading } = useMutation({
    mutationFn: (form: {
      name: string;
      email: string;
      phone: string;
      message: string;
      resumeUrl: string;
    }) =>
      API.post("/contacts/new_employee", {
        ...form,
        resume_url: form.resumeUrl,
      }),
    onSuccess: () =>
      infoToast(
        "Se ha enviado tu contacto. Alguien se comunicará contigo pronto."
      ),
    onError: () =>
      errorToast("No se ha podido enviar tu contacto, intenta nuevamente"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      resumeUrl: "",
    },
    validationSchema: object({
      name: string().required("Requerido"),
      email: string().email("Email inválido").required("Requerido"),
      phone: string().required("Requerido"),
      message: string().required("Requerido"),
      resumeUrl: mixed().required("Requerido"),
    }),
    onSubmit: (values) =>
      sendResume(values, {
        onSuccess: () => {
          formik.resetForm();
        },
      }),
  });

  return (
    <PublicLayout>
      <Header titleText="Trabaja con nosotros" />
      <Box paddingY={3} paddingX={{ xs: 3, md: 7 }}>
        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          justifyContent="space-between"
          paddingTop={2}
          paddingX={{ xs: 0, md: 5 }}
        >
          <Box paddingX={{ xs: 0, md: 2 }} sx={{ marginBottom: 4 }}>
            <Title font="xl">Dejanos tu CV</Title>
            <Paragraph font="xl" style={{ paddingTop: 1, paddingBottom: 1 }}>
              Si te interesa trabajar con nosotros envianos estos datos para
              analizar tu candidatura.
            </Paragraph>
            <Form formik={formik}>
              <FormTextField name="name" label="Nombre completo" type="text" />
              <FormTextField name="email" label="Email" type="email" />
              <FormTextField name="phone" label="Teléfono" type="text" />
              <FormTextField
                name="message"
                label="Mensaje"
                placeholder="Contanos por qué te gustaría trabajar con nosotros"
                type="text"
                minRows={5}
                multiline
              />
              <FormUploadButton
                presignedPath="contacts/new_employee_resume_url"
                text="Subir CV"
                name="resumeUrl"
                required
              />
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading || !formik.isValid}
              >
                {isLoading ? "Enviando..." : "Enviar contacto"}
              </Button>
            </Form>
          </Box>
          <Box
            flexGrow={1}
            paddingX={{ xs: 0, md: 2 }}
            sx={{ marginBottom: 4, width: { xs: "auto", md: "30%" } }} //TODO: Add text of the job
          >
            <Title font="xl" style={{ marginBottom: 1 }}>
              Vacantes
            </Title>
            <Title>ASISTENTE TÉCNICO</Title>
            <Paragraph>
              Buscamos un perfil técnico, para brindar soporte al área de
              Investigación y desarrollo, compras y ventas. Será requisito
              excluyente para el puesto contar con conocimientos comprobables en
              Electricidad y/o Mecánica Automotor, como así también del parque
              automotor nacional. Valoraremos la atención al detalle, prolijidad
              y buen trato interpersonal.
            </Paragraph>
            <Paragraph style={{ marginTop: 2 }}>¡POSTULATE!</Paragraph>
            <Title style={{ marginTop: 2 }}>Requisitos para el puesto:</Title>
            <ul style={{ marginTop: 1, marginBottom: 1 }}>
              <li>
                <Paragraph>Ingles básico</Paragraph>
              </li>
              <li>
                <Paragraph>Manejo de Paquete Office</Paragraph>
              </li>
              <li>
                <Paragraph>
                  Título terciario que acredite formación electromecánica
                </Paragraph>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </PublicLayout>
  );
};
