import { User } from "types/models";

export const ADMIN = "admin";
export const SELLER = "seller";
export const COMPANY_ADMIN = "company_admin";
export const COMPANY_SELLER = "company_seller";

export type Role =
  | typeof ADMIN
  | typeof SELLER
  | typeof COMPANY_ADMIN
  | typeof COMPANY_SELLER;

type RoleOption = {
  id: Role;
  label: string;
};

export const COMPANY_ROLES: RoleOption[] = [
  { id: COMPANY_ADMIN, label: "Administrador Empresa" },
  { id: COMPANY_SELLER, label: "Vendedor Empresa" },
];

export const BLUECAR_ROLES: RoleOption[] = [
  { id: ADMIN, label: "Administrador Bluecar" },
  { id: SELLER, label: "Vendedor Bluecar" },
];

export const BLUECAR_ROLES_IDS = BLUECAR_ROLES.map((role) => role.id);

export const ROLES = [...BLUECAR_ROLES, ...COMPANY_ROLES];

export const isAdmin = (user?: User) => user?.role === ADMIN;
export const isSeller = (user?: User) => user?.role === SELLER;
export const isBluecarRole = (user?: User) => isAdmin(user) || isSeller(user);

export const isCompanyAdmin = (user?: User) => user?.role === COMPANY_ADMIN;
export const isCompanySeller = (user?: User) => user?.role === COMPANY_SELLER;
export const isCompanyRole = (user: User) => !isBluecarRole(user);

export const humanizeRole = (user: User) => {
  if (isAdmin(user)) return "Administrador Bluecar";
  if (isSeller(user)) return "Vendedor Bluecar";
  if (isCompanyAdmin(user)) return "Administrador Empresa";
  if (isCompanySeller(user)) return "Vendedor Empresa";
  return "";
};
