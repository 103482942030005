import { Box } from "@mui/material";

import { Paragraph } from "components/Paragraph";
import { Price } from "components/Price";
import { Product } from "types/models";

type Props = {
  productAmount: number;
} & (
  | {
      usePriceAsFinal: true;
      product: {
        price?: Product["price"];
        company_discount?: undefined;
        promotion_discount?: undefined;
        promotion_minimum_amount?: undefined;
      };
    }
  | {
      usePriceAsFinal?: false;
      product: Pick<
        Product,
        | "price"
        | "company_discount"
        | "promotion_discount"
        | "promotion_minimum_amount"
      >;
    }
);

export const ProductAmountDisplay = ({
  productAmount,
  product,
  usePriceAsFinal = false,
}: Props) => {
  return (
    <Box
      display="flex"
      alignItems="space-between"
      justifyContent="space-between"
      sx={{
        height: { sm: "100%" },
        flexDirection: { xs: "row", sm: "column" },
      }}
    >
      <Price
        price={product.price}
        amount={productAmount}
        companyDiscount={product.company_discount}
        promotionDiscount={product.promotion_discount}
        promotionMinimumAmount={product.promotion_minimum_amount}
        usePriceAsFinal={usePriceAsFinal}
        hidePromotionInfo
      />
      <Paragraph style={{ alignSelf: "end" }}>
        {productAmount} {productAmount > 1 ? "unidades" : "unidad"}
      </Paragraph>
    </Box>
  );
};
