import InfoOutlined from "@mui/icons-material/InfoOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, Tooltip, colors } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { queryClient } from "App";
import { Paragraph } from "components/Paragraph";
import { useAuthentication } from "hooks/useAuthentication";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useToast } from "hooks/useToast";
import { Sync as SyncModel } from "types/models";
import { API } from "utils/api";
import { SyncKinds } from "utils/models/sync";
import { isAdmin } from "utils/models/user";

type Props = {
  kind: SyncKinds;
};

export const Sync = ({ kind }: Props) => {
  const { user } = useAuthentication();
  const { data, isLoading } = usePaginatedQuery<SyncModel>({
    queryKey: ["syncs"],
    path: "syncs",
    pause: !user || !isAdmin(user),
  });

  const { infoToast, errorToast } = useToast();
  const { mutate, isLoading: isForcingSync } = useMutation({
    mutationFn: ({ syncKind }: { syncKind: SyncKinds }) =>
      API.post("/syncs/force_sync", { kind: syncKind }),
    onSuccess: () => {
      infoToast("Sincronización creada con éxito.");
      setTimeout(() => queryClient.invalidateQueries(["syncs"]), 2000);
    },
    onError: () => errorToast("No se ha forzar la sincronización"),
  });

  if (!data) return null;

  const sync = data.results.find((s) => s.kind === kind);
  if (!sync) return null;

  if (sync.syncing) {
    return (
      <Box display="flex" alignItems="center">
        <Paragraph
          font="sm"
          style={{ marginRight: 1, fontWeight: 600, color: colors.yellow[800] }}
        >
          Sincronizando...
        </Paragraph>
        <Tooltip title="Actualiza para ver si ha terminado" arrow>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Box>
    );
  }

  if (sync.errored) {
    return (
      <Box display="flex" alignItems="center">
        <Paragraph
          font="sm"
          style={{ marginRight: 1, fontWeight: 600, color: colors.red[800] }}
        >
          ERROR!
        </Paragraph>
        <Paragraph font="sm" style={{ marginRight: 1 }}>
          Último intento: {sync.last_synced_at}.
        </Paragraph>
        <Paragraph font="sm" style={{ marginRight: 1 }}>
          Última sincronización exitosa: {sync.last_success_synced_at}.
        </Paragraph>
        <Tooltip title="Forzar sicronización con ITRIS" arrow>
          <IconButton
            disabled={isLoading || isForcingSync}
            onClick={() => mutate({ syncKind: sync.kind })}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Paragraph
        font="sm"
        style={{ marginRight: 1, fontWeight: 600, color: colors.green[800] }}
      >
        Última sincronización: {sync.last_success_synced_at}
      </Paragraph>
      <Tooltip title="Forzar sicronización con ITRIS" arrow>
        <IconButton
          disabled={isLoading || isForcingSync}
          onClick={() => mutate({ syncKind: sync.kind })}
        >
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
