import { useQuery } from "@tanstack/react-query";

import { Group } from "types/models";
import { API } from "utils/api";

interface Props {
  active?: boolean;
  brand?: string;
  line?: string;
}

export const useAllGroups = (props?: Props) => {
  const activeString = props?.active ? `&active=true` : "";
  const brandString = props?.brand ? `&brand_id=${props?.brand}` : "";
  const lineString = props?.line ? `&line_id=${props?.line}` : "";

  const queryFn = (): Promise<Group[]> =>
    API.get(`groups?all=true${activeString}${brandString}${lineString}`).then(
      ({ data }) => data
    );

  return useQuery({
    queryKey: ["groups", "all", activeString, brandString, lineString],
    queryFn,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
  });
};
