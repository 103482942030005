import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ref, object, string } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Title } from "components/Title";
import { homePath } from "constants/routes";
import { useLayout } from "hooks/useLayout";
import { useToast } from "hooks/useToast";
import { API } from "utils/api";

export const RecoverPassword = () => {
  const { PublicLayout } = useLayout({ title: "Recuperar contraseña" });
  const { infoToast, errorToast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]) || "";

  if (!token) {
    navigate(homePath);
  }

  const mutationFn = (data: {
    token: string;
    password: string;
    passwordConfirmation: string;
  }): Promise<void> =>
    API.post("users/recover_password", {
      token: data.token,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    }).then((response) => response.data);
  const { mutateAsync: recoverPassword } = useMutation({ mutationFn });

  const formik = useFormik({
    initialValues: { password: "", passwordConfirmation: "" },
    validationSchema: object({
      password: string()
        .min(8, "Contraseña inválida - Mínimo 8 caracteres")
        .required("Requerido"),
      passwordConfirmation: string()
        .oneOf([ref("password"), null], "Las contraseñas deben coincidir")
        .required("Requerido"),
    }),
    onSubmit: async (values) => {
      try {
        await recoverPassword({ token, ...values });
        infoToast("Contraseña actualizada con éxito.");
        formik.resetForm();
        navigate(homePath);
      } catch (_) {
        errorToast("Error, vuelva a intentarlo.");
      }
    },
  });

  return (
    <PublicLayout>
      <Box
        sx={{
          flex: 1,
          flexGrow: 1,
          minHeight: "400px",
          maxWidth: "400px",
          textAlign: "center",
          margin: "auto",
          marginTop: "64px",
        }}
      >
        <Title>Reiniciar contraseña</Title>
        <Form formik={formik}>
          <FormTextField
            name="password"
            label="Contraseña"
            type="password"
            isRequired
            fullWidth
          />
          <FormTextField
            name="passwordConfirmation"
            label="Confirme la contraseña"
            type="password"
            isRequired
            fullWidth
          />
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            variant="contained"
            type="submit"
          >
            Reiniciar contraseña
          </Button>
        </Form>
      </Box>
    </PublicLayout>
  );
};
