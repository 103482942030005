import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { string, object } from "yup";

import { Form } from "components/Form";
import { FormTextField } from "components/FormTextField";
import { Modal } from "components/Modal";
import { Paragraph } from "components/Paragraph";
import { Title } from "components/Title";
import { contactPath } from "constants/routes";
import { ContactContext } from "contexts/ContactProvider";
import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { useToast } from "hooks/useToast";

export const AuthenticationModal = () => {
  const { infoToast, errorToast } = useToast();
  const { signInMutation } = useAuthentication();
  const {
    isAuthenticationModalOpen,
    setIsAuthenticationModalOpen,
    setIsRecoverPasswordModalOpen,
  } = useContext(ModalContext);
  const { setMessage } = useContext(ContactContext);
  const navigate = useNavigate();

  const handleRecoverPassowrd = useCallback(() => {
    setIsAuthenticationModalOpen(false);
    setIsRecoverPasswordModalOpen(true);
  }, [setIsAuthenticationModalOpen, setIsRecoverPasswordModalOpen]);

  const handleContact = useCallback(() => {
    setIsAuthenticationModalOpen(false);
    navigate(contactPath);
    setMessage(
      "¡Hola¡\n\nQuiero ser cliente de Bluecar. Por favor contactarme en mi celular.\n\nGracias."
    );
  }, [navigate, setIsAuthenticationModalOpen, setMessage]);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: object({
      email: string().email("Email inválido").required("Requerido"),
      password: string().min(8, "Contraseña inválida").required("Requerido"),
    }),
    onSubmit: async (values) => {
      try {
        await signInMutation.mutateAsync(values);
        formik.resetForm();
        setIsAuthenticationModalOpen(false);
        infoToast("Sesión iniciada con éxito.");
      } catch (_) {
        errorToast("Email o contraseña inválidos.");
      }
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setIsAuthenticationModalOpen(false);
  }, [formik, setIsAuthenticationModalOpen]);

  return (
    <Modal
      open={isAuthenticationModalOpen}
      onClose={handleClose}
      ariaTitle="Modal para iniciar sesión"
    >
      <Title>Iniciar sesión en Bluecar</Title>
      <Form formik={formik}>
        <FormTextField
          name="email"
          label="Email"
          placeholder="usuario@bluecar.com.ar"
          type="email"
          isRequired
          fullWidth
        />
        <FormTextField
          name="password"
          label="Contraseña"
          type="password"
          isRequired
          fullWidth
        />
        <Paragraph
          onClick={handleContact}
          style={{ marginTop: 1, marginBottom: 1, marginRight: "auto" }}
        >
          No tengo usuario, quiero ser cliente.
        </Paragraph>
        <Paragraph
          onClick={handleRecoverPassowrd}
          style={{ marginBottom: 2, marginRight: "auto" }}
        >
          Recuperar contraseña
        </Paragraph>
        <Box display="flex">
          <Button
            disabled={formik.isSubmitting}
            onClick={handleClose}
            style={{ flexGrow: 1, marginRight: 5 }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            style={{ flexGrow: 1, marginLeft: 5 }}
            variant="contained"
            type="submit"
          >
            Iniciar sesión
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};
