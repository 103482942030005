import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useMemo, useState } from "react";

import logo from "assets/logo.png";
import { CartMenu } from "components/CartMenu";
import { Link } from "components/Link";
import { appBarHeight } from "components/PublicAppBar";
import { SendPriceList } from "components/SendPriceList";
import { UserMenu } from "components/UserMenu";
import {
  backofficeBrandsPath,
  backofficeCompaniesPath,
  backofficeCompanyDiscountsPath,
  backofficeGroupsPath,
  backofficeLinesPath,
  backofficePath,
  backofficeProductsPath,
  backofficePromotionsPath,
  backofficeUsersPath,
  backofficeQuotesPath,
  homePath,
  backofficeOrdersPath,
  backofficeBannersPath,
} from "constants/routes";
import { useAuthentication } from "hooks/useAuthentication";
import { usePendingQuotesCount } from "hooks/usePendingQuotesCount";
import { ChildrenProps } from "types/props";
import { BLUECAR_ROLES_IDS, COMPANY_ADMIN } from "utils/models/user";

export const drawerWidth = 240;
export const backofficePadding = "32px";

const options = [
  { label: "Empresas", to: backofficeCompaniesPath, roles: BLUECAR_ROLES_IDS },
  {
    label: "Usuarios",
    to: backofficeUsersPath,
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  },
  {
    label: "Cotizaciones",
    to: backofficeQuotesPath,
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  },
  {
    label: "Pedidos",
    to: backofficeOrdersPath,
    roles: [...BLUECAR_ROLES_IDS, COMPANY_ADMIN],
  },
  { label: "Descuentos", to: backofficeCompanyDiscountsPath },
  {
    label: "Promociones",
    to: backofficePromotionsPath,
    roles: BLUECAR_ROLES_IDS,
  },
  { divider: true },
  { label: "Banners", to: backofficeBannersPath, roles: BLUECAR_ROLES_IDS },
  { label: "Marcas", to: backofficeBrandsPath, roles: BLUECAR_ROLES_IDS },
  { label: "Productos", to: backofficeProductsPath, roles: BLUECAR_ROLES_IDS },
  { label: "Grupos", to: backofficeGroupsPath, roles: BLUECAR_ROLES_IDS },
  { label: "Lineas", to: backofficeLinesPath, roles: BLUECAR_ROLES_IDS },
];

export const BackofficeLayout = ({ children }: ChildrenProps) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { user } = useAuthentication();

  const { count: pendingQuotesCount } = usePendingQuotesCount();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const drawerOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          user?.role && (!option.roles || option.roles.includes(user.role))
      ),
    [user?.role]
  );

  const responsiveMenu = (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton size="large" onClick={handleOpenNavMenu}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {drawerOptions.map((option, index) =>
          option.divider ? (
            index === drawerOptions.length - 1 ? null : (
              <Divider key="divider" />
            )
          ) : (
            <Link key={option.label} to={`${backofficePath}/${option.to}`}>
              <MenuItem key={option.label} onClick={handleCloseNavMenu}>
                <Badge
                  badgeContent={
                    option.to === backofficeQuotesPath ? pendingQuotesCount : 0
                  }
                  color="primary"
                >
                  {option.label}
                </Badge>
              </MenuItem>
            </Link>
          )
        )}
      </Menu>
    </Box>
  );

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {responsiveMenu}
          <Link to={homePath} noMargin>
            <Box component="img" sx={{ height: 54 }} alt="Bluecar" src={logo} />
          </Link>
          <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "end" }}>
            <SendPriceList />
            <CartMenu />
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {drawerOptions.map((option, index) =>
              option.divider ? (
                index === drawerOptions.length - 1 ? null : (
                  <Divider key="divider" />
                )
              ) : (
                <Link to={`${backofficePath}/${option.to}`} key={option.label}>
                  <ListItem key={option.label} disablePadding>
                    <ListItemButton>
                      <Badge
                        badgeContent={
                          option.to === backofficeQuotesPath
                            ? pendingQuotesCount
                            : 0
                        }
                        color="primary"
                      >
                        <ListItemText primary={option.label} />
                      </Badge>
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flex: 1,
          marginLeft: { xs: 0, md: `${drawerWidth}px` },
          marginTop: appBarHeight,
          padding: backofficePadding,
        }}
      >
        {children}
      </Box>
    </>
  );
};
