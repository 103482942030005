import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { Paginated } from "types/paginated";
import { API } from "utils/api";

type Props = {
  queryKey: (string | undefined)[];
  path: string;
  keepPreviousData?: boolean;
  pause?: boolean;
};

export function usePaginatedQuery<T>({
  queryKey,
  path,
  keepPreviousData = true,
  pause,
}: Props) {
  const queryFn = useCallback(
    (): Promise<Paginated<T>> => API.get(path).then(({ data }) => data),
    [path]
  );

  return useQuery({
    enabled: !pause,
    keepPreviousData,
    queryKey,
    queryFn,
    staleTime: Infinity,
  });
}
