import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import { IconButton, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

import { ModalContext } from "contexts/ModalProvider";
import { useAuthentication } from "hooks/useAuthentication";
import { API } from "utils/api";

export const SendPriceList = () => {
  const { user } = useAuthentication();
  const { setEmailModalProps } = useContext(ModalContext);

  const sendPriceListMutation = useMutation({
    mutationFn: async ({ email }: { email: string }) =>
      API.post("/products/export_csv", { email }),
  });

  if (!user) return null;

  return (
    <Tooltip title="Lista de precios">
      <IconButton
        size="large"
        onClick={() =>
          setEmailModalProps({
            open: true,
            title: "Lista de precios",
            successTootlip:
              "¡La lista de precios se está enviando! La recibirás en tu correo en unos minutos.",
            buttonText: "Enviar",
            onSubmit: sendPriceListMutation.mutateAsync,
          })
        }
      >
        <SendAndArchiveIcon titleAccess="Enviar" />
      </IconButton>
    </Tooltip>
  );
};
