import { SxProps, Theme } from "@mui/material/styles";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import Lightbox from "react-awesome-lightbox";

interface Props {
  images: Array<{ title: string; url: string }>;
}

export const useFullscreenImage = ({ images }: Props) => {
  const [imageOpened, setImageOpened] = useState(false);

  const openImage = useCallback((event: MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setImageOpened(true);
  }, []);

  const closeImage = useCallback(() => setImageOpened(false), []);

  const lightboxProps = useMemo(
    () =>
      images.length === 1
        ? { image: images[0].url, title: images[0].title }
        : { images },
    [images]
  );

  const FullscreenImage = useMemo(
    () =>
      imageOpened ? (
        <Lightbox {...lightboxProps} allowRotate={false} onClose={closeImage} />
      ) : null,
    [closeImage, imageOpened, lightboxProps]
  );

  const imageStyles: SxProps<Theme> = useMemo(
    () => ({
      objectFit: "contain",
      "&:hover": {
        cursor: "zoom-in",
      },
    }),
    []
  );

  return {
    FullscreenImage,
    openImage,
    imageStyles,
  };
};
