import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { homePath } from "constants/routes";
import { useAuthentication } from "hooks/useAuthentication";
import { BackofficeLayout } from "layouts/BackofficeLayout";
import { PublicLayout } from "layouts/PublicLayout";
import { ChildrenProps } from "types/props";
import { Role } from "utils/models/user";

type Props = {
  title: string;
  isPrivate?: boolean;
  roles?: Role[];
};

export const useLayout = ({ isPrivate, title, roles }: Props) => {
  const { user, isLoading } = useAuthentication();
  const navigate = useNavigate();

  const shouldProtect = isPrivate && (isLoading || !user);

  const layouts = useMemo(
    () => ({
      PublicLayout: ({ children }: ChildrenProps) =>
        shouldProtect ? null : <PublicLayout children={children} />,
      BackofficeLayout: ({ children }: ChildrenProps) =>
        shouldProtect ? null : <BackofficeLayout children={children} />,
    }),
    [shouldProtect]
  );

  useEffect(() => {
    if (isPrivate && !isLoading) {
      if (!user || (roles && !roles?.includes(user.role))) {
        navigate(homePath);
      }
    }
  }, [user, isLoading, isPrivate, navigate, roles]);

  useEffect(() => {
    document.title = `${title} | Bluecar S.A.`;
  }, [title]);

  return layouts;
};
