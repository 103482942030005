// Based on https://mui.com/material-ui/react-autocomplete/#virtualization

import {
  Autocomplete,
  autocompleteClasses,
  FilterOptionsState,
  TextField,
} from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled, SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useField } from "formik";
import * as React from "react";
import { useCallback } from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export type Option = {
  id: string;
  label: string;
};

export type UpdatedValues = {
  value: Option | null;
  name: string;
};

export type Props = {
  name: string;
  label: string;
  width?: string;
  fullWidth?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  onChange?: (updatedValues: UpdatedValues) => void;
  options: readonly Option[];
  style?: SxProps<Theme>;
  size?: "small" | "medium";
  startsWithMatch?: boolean;
};

export const FormSingleAutocomplete = ({
  name,
  label,
  placeholder,
  width = "256px",
  fullWidth,
  isRequired,
  isDisabled,
  onChange,
  options,
  style,
  size = "medium",
  startsWithMatch = false,
}: Props) => {
  const [field, fieldMeta, { setValue }] = useField<Option | null>(name);
  const error = fieldMeta.touched && fieldMeta.error;

  const handleFilterOptions = useCallback(
    (allOptions: Option[], state: FilterOptionsState<Option>) => {
      const inputValue = state.inputValue.toLowerCase();
      return allOptions.filter((option) =>
        option.label.toLowerCase().startsWith(inputValue)
      );
    },
    []
  );

  return (
    <Autocomplete
      id={name}
      options={options}
      value={field.value}
      disableListWrap
      fullWidth={fullWidth}
      size={size}
      sx={{
        marginBottom: 1,
        marginTop: 1,
        minWidth: width,
        maxWidth: fullWidth ? "unset" : width,
        ".MuiOutlinedInput-root": {
          "&:not(.Mui-focused)": {
            maxHeight: "56px",
          },
          ".MuiChip-root": {
            maxWidth: "150px",
          },
        },
        ".Mui-focused": {
          backgroundColor: "white",
          zIndex: 10,
        },
        ...style,
      }}
      noOptionsText="No hay opciones"
      onChange={(_event, value) => {
        setValue(value);
        if (onChange) onChange({ value, name });
      }}
      renderOption={(props, option) => [props, option.label] as React.ReactNode}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          variant="outlined"
          required={isRequired}
          disabled={isDisabled}
          placeholder={placeholder}
          error={!!error}
          helperText={error}
          sx={{ maxHeight: "56px" }}
          fullWidth
        />
      )}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      filterOptions={startsWithMatch ? handleFilterOptions : undefined}
    />
  );
};
