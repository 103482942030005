import { Box, Tooltip, colors } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

type Props = {
  stock?: number;
  selected?: number;
  style?: SxProps<Theme>;
};

export const StockIndicator = ({
  stock = 0,
  selected = 0,
  style = {},
}: Props) => {
  const remaining = stock - selected;
  return (
    <Tooltip title={stock > 0 ? "Stock disponible" : "Stock a confirmar"}>
      <Box
        sx={{
          backgroundColor:
            remaining > 0 || (remaining === 0 && selected > 0)
              ? colors.green[800]
              : colors.yellow[800],
          borderRadius: "50%",
          height: 8,
          width: 8,
          ...style,
        }}
      />
    </Tooltip>
  );
};
