import { Box, Pagination, useMediaQuery } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";

import { Form } from "components/Form";
import {
  FormSelect,
  MultipleOption as Option,
  MultipleUpdatedValues as UpdatedValues,
} from "components/FormSelect";
import { FormTextField } from "components/FormTextField";
import { Paragraph } from "components/Paragraph";
import { Spinner } from "components/Spinner";
import { FilterPaginatedContext } from "contexts/FilterPaginatedProvider";
import { ModalContext } from "contexts/ModalProvider";
import { ProductsContext } from "contexts/ProductsProvider";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import useScrollTo from "hooks/useScrollTo";
import { Paginated } from "types/paginated";

export type Filter = {
  id: string;
  label: string;
  type?: "text" | "date";
  options?: readonly Option[];
  style?: SxProps<Theme>;
};

export type Props<Data> = {
  filters?: readonly Filter[];
  filtersRightNode?: ReactNode;
  queryKey: string;
  path: string;
  renderer?: (data: Paginated<Data>) => ReactNode;
  LoadingComponent?: () => JSX.Element;
};

const PAGE = "page";

export function FilterPaginated<Data>({
  filters,
  filtersRightNode,
  queryKey,
  path,
  renderer,
  LoadingComponent,
}: Props<Data>) {
  const theme = useTheme();
  const xsDevice = useMediaQuery(theme.breakpoints.down("md"));
  const scrollTo = useScrollTo();

  const { setSearchParamsObject } = useContext(FilterPaginatedContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(PAGE) || "1";

  const searchParamsObject = useMemo(() => {
    const result: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }, [searchParams]);
  const searchParamsKeys = Object.keys(searchParamsObject);
  const searchParamsValues = Object.values(searchParamsObject);
  const searchParamsString = useMemo(
    () =>
      searchParamsKeys
        .map((key) => `${key}=${searchParamsObject[key]}`)
        .join("&"),
    [searchParamsObject, searchParamsKeys]
  );

  const { company } = useContext(ModalContext);
  const { sorting } = useContext(ProductsContext);
  const companyPath =
    company && path.includes("products") ? `&company_id=${company.id}` : "";
  const productsSortingPath =
    sorting?.id && path.includes("products") ? `&sort_by=${sorting.id}` : "";
  const initialPath = path.includes("?") ? `${path}&` : `${path}?`;
  const { data, isLoading, isStale } = usePaginatedQuery<Data>({
    queryKey: [
      queryKey,
      page,
      ...searchParamsValues,
      companyPath,
      productsSortingPath,
    ],
    path: `${initialPath}${PAGE}=${page}&${searchParamsString}${companyPath}${productsSortingPath}`,
  });

  const formik = useFormik({
    initialValues:
      filters?.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.id]: filter.options
            ? filter.options.filter((option) =>
                searchParamsObject[filter.id]
                  ?.split(",")
                  .includes(option.id.toString())
              )
            : searchParamsObject[filter.id],
        }),
        {}
      ) || {},
    onSubmit: () => {},
  });

  const handlePageChange = useCallback(
    (event: unknown, newPage: number) =>
      setSearchParams({
        ...searchParamsObject,
        [PAGE]: newPage.toString(),
      }),
    [searchParamsObject, setSearchParams]
  );

  const handleTextFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setSearchParams({
        ...searchParamsObject,
        [PAGE]: "1",
        [event.target.name]: event.target.value,
      }),
    [searchParamsObject, setSearchParams]
  );

  const handleMultipleSelectFilterChange = useCallback(
    (selected: UpdatedValues) => {
      return setSearchParams({
        ...searchParamsObject,
        [PAGE]: "1",
        [selected.name]: selected.values.map((value) => value.id).join(","),
      });
    },
    [searchParamsObject, setSearchParams]
  );

  const handleClearFilters = useCallback(() => {
    if (filters) {
      setSearchParams({});
      filters.forEach((filter) => formik.setFieldValue(filter.id, ""));
    }
  }, [filters, formik, setSearchParams]);

  const filtersComponent = useMemo(
    () =>
      filters
        ? filters.map((filter) =>
            filter.options && filter.options.length > 0 ? (
              <FormSelect
                key={filter.id}
                name={filter.id}
                label={filter.label}
                fullWidth={xsDevice}
                onChange={handleMultipleSelectFilterChange}
                options={filter.options}
                style={{ marginRight: 1, ...filter.style }}
                startsWithMatch
                multiple
              />
            ) : (
              <FormTextField
                key={filter.id}
                name={filter.id}
                label={filter.label}
                type={filter.type}
                fullWidth={xsDevice}
                onChange={handleTextFilterChange}
                style={{ marginRight: 1, ...filter.style }}
              />
            )
          )
        : null,
    [
      filters,
      xsDevice,
      handleMultipleSelectFilterChange,
      handleTextFilterChange,
    ]
  );

  useEffect(() => {
    scrollTo("#data-top");
  }, [data, scrollTo]);

  useEffect(
    () => setSearchParamsObject(searchParamsObject),
    [searchParamsObject, setSearchParamsObject]
  );

  return (
    <Box position="relative">
      <Box id="data-top" />
      {filtersComponent && (
        <>
          <Form formik={formik}>
            <Box display="flex" flexWrap="wrap">
              {filtersComponent}
            </Box>
          </Form>
          <Box
            sx={{
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: 36,
            }}
          >
            <Paragraph onClick={handleClearFilters}>Limpiar filtros</Paragraph>
            {filtersRightNode}
          </Box>
        </>
      )}
      {((isStale && !isLoading) || (isLoading && !LoadingComponent)) && (
        <Spinner />
      )}
      {isLoading && LoadingComponent ? <LoadingComponent /> : null}
      {data && renderer ? (
        <>
          {renderer(data)}
          {data.results.length ? (
            <Pagination
              siblingCount={1}
              boundaryCount={xsDevice ? 1 : 3}
              count={parseInt(data.total_pages)}
              page={parseInt(data.page)}
              onChange={handlePageChange}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
              }}
            />
          ) : null}
        </>
      ) : null}
    </Box>
  );
}
