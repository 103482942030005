import { createContext, useState } from "react";

import { ChildrenProps } from "types/props";

type ContactContextType = {
  message?: string;
  setMessage: (message?: string) => void;
};

export const ContactContext = createContext<ContactContextType>({
  message: undefined,
  setMessage: () => {},
});

export const ContactProvider = ({ children }: ChildrenProps) => {
  const [message, setMessage] = useState<ContactContextType["message"]>();

  return (
    <ContactContext.Provider value={{ message, setMessage }}>
      {children}
    </ContactContext.Provider>
  );
};
