import { Box, colors } from "@mui/material";
import { NavLink, useMatch } from "react-router-dom";

import { ChildrenProps } from "types/props";

type Props = ChildrenProps & {
  to: string;
  style?: React.CSSProperties;
  external?: boolean;
};

export const PublicAppBarLink = ({ to, children, style, external }: Props) => {
  const match = useMatch(`${to}/*`);
  const content = (
    <Box
      sx={{
        height: "100%",
        padding: 3,
        transition: "border 0.2s",
        "&:hover": {
          backgroundColor: colors.grey[200],
          borderBottom: !match ? `4px solid ${colors.blue[500]}` : "unset",
        },
      }}
    >
      {children}
    </Box>
  );
  const commonStyles = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "black",
    textDecoration: "none",
    ...style,
  };

  return external ? (
    <a href={to} target="_blank" style={commonStyles}>
      {content}
    </a>
  ) : (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        borderBottom: isActive ? `4px solid ${colors.blue[500]}` : "unset",
        ...commonStyles,
      })}
    >
      {content}
    </NavLink>
  );
};
