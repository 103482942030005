import { Button } from "@mui/material";

import { Props as FilterPaginatedProps } from "components/FilterPaginated";
import { Link } from "components/Link";
import { Paragraph } from "components/Paragraph";
import { Column, Table } from "components/Table";
import { productsPath } from "constants/routes";
import { Promotion } from "types/models";

const columns: Column<Promotion>[] = [
  {
    id: "product_id",
    label: "Producto/Marca",
    renderer: (promotion) =>
      promotion.product_name ? (
        <Paragraph>Producto: {promotion.product_name}</Paragraph>
      ) : (
        <Paragraph>Marca: {promotion.brand_name}</Paragraph>
      ),
  },
  {
    id: "discount",
    label: "Descuento",
    renderer: (promotion) => `${promotion.discount}%`,
  },
  { id: "minimum_amount", label: "Cantidad mínima" },
  { id: "start_date", label: "Desde" },
  { id: "end_date", label: "Hasta" },
  {
    id: "image_url",
    label: "",
    renderer: (promotion) => (
      <Link
        noMargin
        to={
          promotion.brand_id
            ? `${productsPath}?brand_id=${promotion.brand_id}`
            : `${productsPath}/${promotion.product_id}`
        }
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <Button>Ver {promotion.brand_id ? "Catálogo" : "Producto"}</Button>
      </Link>
    ),
  },
];

type Props = Omit<FilterPaginatedProps<Promotion>, "columns">;

export const PromotionsTable = (props: Props) => {
  return <Table columns={columns} {...props} />;
};
