import { useSnackbar } from "notistack";
import { useMemo } from "react";

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const toasts = useMemo(
    () => ({
      successToast: (message: string) =>
        enqueueSnackbar(message, { variant: "success" }),
      errorToast: (message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      infoToast: (message: string) =>
        enqueueSnackbar(message, { variant: "info" }),
    }),
    [enqueueSnackbar]
  );

  return toasts;
};
