import { Skeleton } from "@mui/material";

export const ProductBoxSkeleton = () => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        margin: 1,
        width: { xs: "93%", lg: "45%" },
        height: { xs: 110, md: 160 },
      }}
    />
  );
};
