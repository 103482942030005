import {
  Props as FilterPaginatedProps,
  FilterPaginated,
} from "components/FilterPaginated";
import {
  Column as ResponsiveColumn,
  ResponsiveTable,
} from "components/ResponsiveTable";

export type Column<Data> = ResponsiveColumn<Data>;

type Props<Data> = FilterPaginatedProps<Data> & {
  maxHeight?: number | string;
  columns: readonly Column<Data>[];
};

export function Table<Data>({
  maxHeight,
  columns,
  filters,
  filtersRightNode,
  queryKey,
  path,
}: Props<Data>) {
  return (
    <FilterPaginated<Data>
      filters={filters}
      filtersRightNode={filtersRightNode}
      queryKey={queryKey}
      path={path}
      renderer={(data) => (
        <ResponsiveTable
          data={data.results}
          maxHeight={maxHeight}
          columns={columns}
        />
      )}
    />
  );
}
