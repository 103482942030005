import { Box } from "@mui/material";
import { useMemo } from "react";

import { ProductsTableItem } from "./ProductTableItem";

import { Props as FilterPaginatedProps } from "components/FilterPaginated";
import { ProductExternalId } from "components/ProductExternalId";
import { ProductBoxShopping } from "components/screens/products/ProductBoxShopping";
import { Column, Table } from "components/Table";
import { useAuthentication } from "hooks/useAuthentication";
import { Product } from "types/models";

type Props = Omit<FilterPaginatedProps<Product>, "columns">;

export const ProductsTable = (props: Props) => {
  const { user } = useAuthentication();

  const baseColumns: Column<Product>[] = useMemo(
    () => [
      {
        id: "name",
        label: "Nombre",
        renderer: (product) => <ProductsTableItem product={product} />,
      },
      {
        id: "id",
        label: "ID",
        align: "center",
        renderer: (product) => (
          <ProductExternalId externalId={product.external_id} noTitle />
        ),
      },
    ],
    []
  );

  const userColumns: Column<Product>[] = useMemo(
    () => [
      {
        id: "description",
        label: "Cantidad",
        align: "center",
        renderer: (product) => (
          <Box
            sx={{
              "& div": {
                marginLeft: "unset",
                justifyContent: "center",
              },
            }}
          >
            {product.price ? (
              <ProductBoxShopping product={product} hidePrice />
            ) : (
              "No disponible"
            )}
          </Box>
        ),
      },
      {
        id: "price",
        label: "Precio",
        align: "center",
        renderer: (product) =>
          product.price ? (
            <ProductBoxShopping product={product} hideHandler />
          ) : (
            "-"
          ),
      },
    ],
    []
  );

  const columns = useMemo(
    () => (user ? [...baseColumns, ...userColumns] : baseColumns),
    [user, baseColumns, userColumns]
  );

  return <Table columns={columns} maxHeight="unset" {...props} />;
};
